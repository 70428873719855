import PulseColorPicker from 'components/pulse-color-picker/pulse-color-picker';
import { useFormikContext } from 'formik';
import React, { ReactElement, useEffect } from 'react';
import styles from './booking-color.module.scss';
import PulseColorPickerData from 'components/pulse-color-picker/pulse-color-picker-colors.json';
import RPBookingModel from 'components/pulse-resource-planner/models/rp-booking-model';

const blueColor = PulseColorPickerData.find(color => color.name === 'blue');

const defaultBookingColor = PulseColorPickerData.find(color => color?.default);
const BookingColor: React.FC<{
  isNonChargeTime: boolean;
}> = ({ isNonChargeTime }): ReactElement => {
  const { values, setValues, setFieldValue } = useFormikContext<Partial<RPBookingModel>>();
  const isNC = values?.job?.raw?.isNC || isNonChargeTime;
  const isDisablePicker = values.priority === 'escalated' || isNC;

  const onChangeColor = color => {
    setValues({
      ...values,
      bookingCustomColor: color,
      isCustomBookingColor: true,
    });
  };

  useEffect(() => {
    if (isNC) {
      setFieldValue('bookingCustomColor', blueColor);
      setFieldValue('isCustomBookingColor', true);
    }
  }, [isNC]);

  return (
    <PulseColorPicker
      disabled={isDisablePicker}
      PulseColorSwatchProps={{
        selectedColor: values?.bookingCustomColor ?? defaultBookingColor,
        onSelect: onChangeColor,
      }}
      classes={{
        root: styles['booking-color'],
        label: styles['booking-color__label'],
        button: styles['booking-color__button'],
      }}
      PulseMenuProps={{
        TippyProps: {
          placement: 'bottom-start',
        },
      }}
      variant="colorPickerV2"
    />
  );
};

export default BookingColor;
