import { Grid } from '@mui/material';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { PulseGalleryItemTooltipProps } from './pulse-gallery-item-tooltip-types';
import styles from './pulse-gallery-item-tooltip.module.scss';

/**
 * This is a const to filter out
 * which asset meta data to show
 * in the tooltip.
 *
 * @todo add this as a prop if the
 * requirement arises
 */
const ALLOWED_TOOLTIP_LABEL = [
  'assetId',
  'version',
  'size',
  'uploadedBy',
  'uploadedAt',
  'project',
  'job',
  'client',
  'brand',
];

const PulseGalleryItemTooltip: FC<PulseGalleryItemTooltipProps> = (props: PulseGalleryItemTooltipProps) => {
  const { data } = props;
  const labels = ALLOWED_TOOLTIP_LABEL.map(label => {
    let displayLabel = label,
      displayValue = data[label];
    switch (label) {
      case 'assetId':
        displayLabel = 'asset id';
        break;
      case 'version':
        displayLabel = 'file version';
        break;
      case 'size':
        displayLabel = 'file size';
        displayValue = `${(data?.size / 1024).toFixed(1)}MB`;
        break;
      case 'uploadedBy':
        displayLabel = 'author';
        displayValue = data.uploadedBy?.label || '';
        break;
      case 'uploadedAt':
        displayLabel = 'Uploaded';
        const uploadedDate = new Date(data.uploadedAt.split('T').shift() || '');
        displayValue = format(uploadedDate, 'dd/MM/yyyy');
        break;
      case 'project':
        displayValue = data.job.label;
        break;
      case 'job':
        displayLabel = 'job no';
        displayValue = data.job.raw.jobExtension;
        break;
      case 'client':
        displayLabel = 'Office';
        displayValue = data.client.label;
        break;
      case 'brand':
        displayLabel = 'brand';
        displayValue = data?.job?.raw?.brandTitle;
      default:
        break;
    }
    return {
      displayLabel,
      displayValue,
    };
  });
  return (
    <Grid data-testid="pulseGalleryItemTooltipRoot" className={styles.pulseGalleryItemTooltipRoot} container>
      <Grid className={styles.pulseGalleryItemTooltipTitle} item xs={12}>
        File Details:
      </Grid>
      <Grid item xs={12} className={styles.pulseGalleryItemTooltipAssetTitle}>
        <span className={styles.pulseGalleryItemTooltipLabel}>FILE NAME: </span>
        <span>{data.title}</span>
      </Grid>
      {labels.map(({ displayLabel, displayValue }) => {
        return (
          <Grid item key={displayLabel} xs={12}>
            <span className={styles.pulseGalleryItemTooltipLabel}>{displayLabel}: </span>
            <span>{displayValue}</span>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PulseGalleryItemTooltip;
