import React, { ReactElement } from 'react';
import styles from './linear-pillar.module.scss';

const LinearPillarSpinner = (): ReactElement => {
  return (
    <div data-testid="pcs-linear-pillar-spinner" className={styles.spinner}>
      <div className={styles.rect1}></div>
      <div className={styles.rect2}></div>
      <div className={styles.rect3}></div>
      <div className={styles.rect4}></div>
      <div className={styles.rect5}></div>
    </div>
  );
};

export default LinearPillarSpinner;
