import React, { ReactElement, Ref } from 'react';
import { PulseInputProps } from './pulse-input-types';
import InputBase from '@mui/material/InputBase';
import styles from './pulse-input.module.scss';
import clsx from 'clsx';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import { IconSizes } from 'pulse-commons/types';
import PulseIcons from 'components/pulse-icons/pulse-icons';

export const PulseInput = React.forwardRef(
  (props: PulseInputProps, ref: Ref<HTMLDivElement>): ReactElement => {
    const {
      label = '',
      classes,
      InputBaseProps,
      required = false,
      error,
      isInvalid = false,
      labelTag,
      iconInfo,
      TippyProps,
      spinBoxInput = false,
      decreaseValue,
      increaseValue,
      designVariant = 'v2',
      pulseIcon,
    } = props;

    return (
      <div
        className={clsx(
          styles['pulse-input__root'],
          classes?.root,
          styles[designVariant],
          InputBaseProps?.disabled && styles['pulse-input__root--disabled'],
        )}
        data-testid={'pulse-input__ctn'}
      >
        {label && (
          <span
            className={clsx(
              styles['input-header__label'],
              InputBaseProps?.disabled && styles['input-header__label--disabled'],
              classes?.label,
            )}
          >
            <span>
              {label}
              {labelTag && (
                <span
                  data-testid="pulse-input-label"
                  className={clsx(styles['input-header__label-tag'], labelTag?.newTag)}
                >
                  {labelTag.content}
                </span>
              )}
              {iconInfo && (
                <PulseTooltip
                  classes={{ tooltipRoot: styles['input-header__tooltip-root'] }}
                  TippyProps={{
                    content: iconInfo.content,
                    popperOptions: {
                      strategy: 'fixed',
                    },
                    placement: 'top',
                    ...TippyProps,
                  }}
                >
                  <i
                    className={clsx(
                      styles['input-header__tooltip-icon'],
                      iconInfo.icon || 'fal fa-info-circle',
                      iconInfo.iconClass,
                    )}
                  />
                </PulseTooltip>
              )}
            </span>
            {required && designVariant != 'v3' && (
              <span
                className={clsx(
                  styles['pulse-input__required-label'],
                  isInvalid && styles['pulse-input__required-label--error'],
                  classes?.error,
                )}
              >
                *Required
              </span>
            )}
          </span>
        )}
        <div className={(styles['pulse-input__ctn'], classes?.ctn)}>
          {spinBoxInput && (
            <div className={clsx(styles['pulse-input__root-spin-box'])} data-testid="spin-box-left">
              <PulseIconButton
                classes={{
                  root: clsx(styles['pulse-input__spin-box__left']),
                  pulseIcon: {
                    icon: clsx('fal fa-chevron-left', styles['pulse-input__spin-box__left-icon']),
                  },
                }}
                iconName=""
                size={IconSizes.lg}
                handleClick={decreaseValue}
              />
            </div>
          )}
          <InputBase
            autoComplete={'off'}
            fullWidth
            placeholder={'Input'}
            {...InputBaseProps}
            ref={ref}
            classes={{
              ...InputBaseProps?.classes,
              root: clsx(styles['pulse-input__inputBaseRoot'], InputBaseProps?.classes?.root, classes?.root),
              input: clsx(
                styles['pulse-input__input'],
                InputBaseProps?.multiline && styles['pulse-input__input-multiline'],
                InputBaseProps?.disabled && styles['pulse-input__input--disabled'],
                InputBaseProps?.classes?.input,
                classes?.input,
                isInvalid && styles['pulse-input__input--error'],
              ),
            }}
          />
          {spinBoxInput && (
            <div className={clsx(styles['pulse-input__root-spin-box'])} data-testid="spin-box-right">
              <PulseIconButton
                classes={{
                  root: clsx(styles['pulse-input__spin-box__right']),
                  pulseIcon: {
                    icon: clsx('fal fa-chevron-right', styles['pulse-input__spin-box__right-icon']),
                  },
                }}
                iconName=""
                size={IconSizes.lg}
                handleClick={increaseValue}
              />
            </div>
          )}
        </div>
        {error && (
          <span data-testid={'pulse-input__error'} className={clsx(styles['pulse-input__error'], classes?.error)}>
            {error}
          </span>
        )}
      </div>
    );
  },
);

export default PulseInput;
