import React, { ReactElement } from 'react';
import { Select } from '@mui/material';
import PulseIcons from 'components/pulse-icons/pulse-icons';
import { IconSizes } from 'pulse-commons/types';
import { PulseSimpleSelectProps } from './pulse-simple-select-types';
import styles from './pulse-simple-select.module.scss';
import clsx from 'clsx';

export const PulseSimpleSelect = (props: PulseSimpleSelectProps): ReactElement => {
  const { classes, children, MenuProps, PulseIconsProps, ...restProps } = props;

  return (
    <div data-testid="pulseSimpleSelectRoot" className={styles.pulseSimpleSelectRoot}>
      <Select
        classes={{
          ...classes,
          select: clsx(styles.pulseSimpleSelectSelect, classes?.select),
          nativeInput: clsx(styles.pulseSimpleSelectNativeInput, classes?.nativeInput),
        }}
        IconComponent={props => {
          return (
            <PulseIcons
              iconName=""
              size={IconSizes.md}
              {...PulseIconsProps}
              classes={{
                ...PulseIconsProps?.classes,
                root: clsx(props.className, styles.pulseSimpleSelectIndicator, PulseIconsProps?.classes?.root),
                icon: 'fal fa-chevron-down',
              }}
            />
          );
        }}
        {...restProps}
        MenuProps={{
          ...MenuProps,
          classes: { ...MenuProps?.classes, list: clsx(styles.pulseSimpleSelectMenuList, MenuProps?.classes?.list) },
          elevation: 0,
        }}
        variant="standard"
      >
        {children}
      </Select>
    </div>
  );
};

export default PulseSimpleSelect;
