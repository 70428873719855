/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { FilterStateProps } from './types';
import isEqual from 'lodash/isEqual';
export enum Filters {
  activityDescription = 'activityDescription',
  activityTracking = 'activityTracking',
  activityType = 'activityType',
  activityTypeName = 'activityTypeName',
  assignedUser = 'assignedUser',
  assignedUserOffice = 'assignedUserOffice',
  assignedUsers = 'assignedUsers',
  bookingStatus = 'bookingStatus',
  brand = 'brand',
  brandCat = 'brandCat',
  brandcategories = 'brandcategories',
  brands = 'brands',
  createdDate = 'createdDate',
  currency = 'currency',
  dateRange = 'dateRange',
  departments = 'departments',
  dueDate = 'dueDate',
  financeStatus = 'financeStatus',
  financeTo = 'financeTo',
  itemGroup = 'itemGroup',
  jobOffices = 'jobOffices',
  jobs = 'jobs',
  office = 'office',
  project = 'project',
  projectNumber = 'projectNumber',
  reportedUser = 'reportedUser',
  requestor = 'requestor',
  roles = 'roles',
  showPtoOnly = 'showPtoOnly',
  status = 'status',
  tags = 'tags',
  task = 'task',
  ticketType = 'ticketType',
  userOffices = 'userOffices',
  validDate = 'validDate',
  reviewStatus = 'reviewStatus',
  reviewOwner = 'reviewOwner',
  reviewTypes = 'reviewTypes',
  projectManager = 'projectManager',
  reviewStage = 'reviewStage',
  reviewRound = 'reviewRound',
  customFilter = 'customFilter',
  createdBy = 'createdBy',
}

export const initialState: FilterStateProps = {
  selectedFilter: {},
  appliedFilter: {},
  isFilterVisible: false,
};

export const filterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    initForm: (state, action) => {
      state.appliedFilter = { ...state.appliedFilter, ...action.payload };
      state.selectedFilter = { ...state.appliedFilter, ...action.payload };
    },
    openForm: state => {
      if (!isEqual(state.selectedFilter, state.appliedFilter)) {
        state.selectedFilter = { ...state.appliedFilter };
      }
      state.isFilterVisible = true;
    },
    closeForm: state => {
      state.isFilterVisible = false;
    },
    addFilter: (state, action) => {
      const { filterName, filterValue } = action.payload;
      state.selectedFilter[filterName] = filterValue;
      delete state.selectedFilter[`${filterName}Custom`];
    },
    editElementInAppliedFilter: (state, action) => {
      const { filterName, filterValue } = action.payload;
      state.appliedFilter[filterName] = filterValue;
    },
    removeElementInAppliedFilter: (state, action) => {
      const { filterName, filterValue } = action.payload;
      if (Array.isArray(state.appliedFilter[filterName])) {
        state.appliedFilter[filterName] = state.appliedFilter[filterName]?.filter(
          item => item.value.toString() !== filterValue.value.toString(),
        );
      } else {
        delete state.appliedFilter[`${filterName}Custom`];
        delete state.appliedFilter[filterName];
      }
    },
    copyFromSelectedFilterToAppliedFilter: state => {
      state.appliedFilter = { ...state.selectedFilter };
    },
    resetFilter: state => {
      state.appliedFilter = {};
      state.selectedFilter = {};
    },
  },
});

export const {
  openForm,
  closeForm,
  addFilter,
  copyFromSelectedFilterToAppliedFilter,
  resetFilter,
  initForm,
  editElementInAppliedFilter,
  removeElementInAppliedFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
