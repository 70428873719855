export const statusStaticOptions = [
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Tentative', value: 'tentative' },
  { label: 'Work completed', value: 'workCompleted' },
];

export const reviewRoundStaticOptions = [
  { value: '1', label: 'Round 1' },
  { value: '2', label: 'Round 2' },
  { value: '3', label: 'Round 3' },
  { value: '4', label: 'Round 4' },
  { value: '5', label: 'Round 5' },
  { value: '6', label: 'Round 6' },
  { value: '7', label: 'Round 7' },
  { value: '8', label: 'Round 8' },
  { value: '9', label: 'Round 9' },
  { value: '10', label: 'Round 10' },
];
