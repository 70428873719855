import React, { FC, ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import {
  PulseSelectPropTypes,
  PulseSelectBasePropTypes,
  PulseSelectDataStructureTypes,
  PulseSelectOptionType,
} from '../base/pulse-select-base-types';
import { OptionProps } from 'react-select';
import PulseBrandCategoryOption from './components/pulse-brand-categories-option';
import isFunction from 'lodash/isFunction';

const DATA_STRUCTURE: PulseSelectDataStructureTypes = {
  dataKey: 'data',
  label: 'title',
  lastPage: 'meta.page.last_page',
  type: 'brand-categories',
  value: 'brand_category_id',
  isJsonApi: true,
  searchKey: 'title',
  pageSize: 25,
};
const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = 'Filter by brand category name';
const DEFAULT_CLIENT_ENDPOINT: PulseSelectBasePropTypes['url'] = '/v2/api/brand-categories';

export const PulseBrandCategoriesSelect = (props: PulseSelectPropTypes): ReactElement => {
  const {
    value = [],
    isMulti = false,
    labelName = 'Brand categories',
    placeholder = DEFAULT_PLACEHOLDER,
    url = DEFAULT_CLIENT_ENDPOINT,
    valueChangeHandler,
  } = props;

  const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = value => {
    isFunction(valueChangeHandler) && valueChangeHandler(value);
  };

  return (
    <PulseSelectBase
      changeHandler={handleValueChange}
      components={{
        Option: PulseBrandCategoryOption as FC<OptionProps<PulseSelectOptionType>>,
      }}
      dataStructure={DATA_STRUCTURE}
      isMulti={isMulti}
      labelName={labelName}
      placeholder={placeholder}
      url={url}
      value={value}
      {...props}
    />
  );
};

export default React.memo<PulseSelectPropTypes>(PulseBrandCategoriesSelect);
