import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { PulseVideoPlayerProps } from './pulse-video-player-types';
import styles from './pulse-video-player.module.scss';
import ReactPlayer from 'react-player';

export const PulseVideoPlayer = (props: PulseVideoPlayerProps): ReactElement => {
  /** Destructure your props here */
  const { classes, ...ReactPlayerProps } = props;

  return (
    <div className={clsx(styles['pulse-video-player__root'], classes?.root)}>
      <ReactPlayer width="100%" height="auto" controls {...ReactPlayerProps} />
    </div>
  );
};

export default PulseVideoPlayer;
