import React, { FC, ReactElement } from 'react';
import { PulseMenuProps, PulseMenuTippyProps } from './pulse-menu-types';
import styles from './pulse-menu.module.scss';
import { followCursor } from 'tippy.js';
import Tippy from '@tippyjs/react/headless';
import clsx from 'clsx';

const PulseMenuTippy = (props: PulseMenuTippyProps): ReactElement => {
  const { attrs, children, classes } = props;

  const menuCtnClasses = clsx(styles['pulse-menu__menu-ctn'], classes?.menuCtn);

  return (
    <div {...attrs} className={menuCtnClasses} tabIndex={-1}>
      {children}
    </div>
  );
};

export const PulseMenu: FC<PulseMenuProps> = props => {
  /** Destructure your props here */
  const { classes, children, menuChildren, TippyProps, alignArrow = 'center' } = props;
  return (
    <Tippy
      interactive
      placement={alignArrow === 'right' ? 'bottom-end' : 'bottom-start'}
      plugins={[followCursor]}
      {...TippyProps}
      render={attrs => (
        <PulseMenuTippy
          classes={{
            menuCtn: clsx(classes?.menuCtn, styles['pulse-menu-tippy__menuCtn']),
          }}
          attrs={attrs}
        >
          {TippyProps?.arrow && (
            <div
              className={clsx(
                styles['pulse-menu-tippy__arrow'],
                alignArrow === 'right' && styles['pulse-menu-tippy__icon--positionRight'],
                alignArrow === 'left' && styles['pulse-menu-tippy__icon--positionLeft'],
              )}
              data-popper-arrow
            />
          )}
          {menuChildren}
        </PulseMenuTippy>
      )}
    >
      {children ? (
        <div className={clsx(styles['pulse-menu__childrenRoot'], classes?.childrenRoot)}>{children}</div>
      ) : undefined}
    </Tippy>
  );
};

export default PulseMenu;
