import React, { FC, useMemo, useState, useEffect, ChangeEvent } from 'react';
import clsx from 'clsx';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseInput from 'components/pulse-input/pulse-input';
import Tippy from '@tippyjs/react';
import { PulseProjectUsersSelect } from 'components/pulse-select/pulse-users/pulse-users-select';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { ButtonVariants } from 'pulse-commons/types';
import PulseClientsSelect from 'components/pulse-select/pulse-clients/pulse-clients-select';
import PulseBrandsSelect from 'components/pulse-select/pulse-brands/pulse-brands-select';
import PulseBrandCategoriesSelect from 'components/pulse-select/pulse-brand-categories/pulse-brand-categories-select';
import { TimelineTemplate, TimelineTemplateModalProps } from './timeline-template-modal-types';
import styles from './timeline-template-modal.module.scss';

const INITIAL_TEMPLATE: TimelineTemplate = {
  name: '',
  editors: [],
  accessors: [],
  accessorGroups: {
    brandCategories: [],
    brands: [],
    offices: [],
  },
};

const TimelineTemplateModal: FC<TimelineTemplateModalProps> = props => {
  const { isOpen, template, onCancel, onSave, onOpenDeleteModal } = props;
  const [form, updateForm] = useState<TimelineTemplate>(INITIAL_TEMPLATE);

  const modalHeaderConfigs = useMemo(() => {
    if (template?.id) {
      return {
        primaryText: 'EDIT TIMELINE TEMPLATE',
        icon: 'fal fa-cog',
      };
    }

    return {
      primaryText: 'CREATE NEW TIMELINE TEMPLATE',
      icon: 'fal fa-plus',
    };
  }, [template]);

  const handleSaveTemplate = () => onSave && onSave(form);

  const handleValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;

    updateForm({ ...form, [name]: value });
  };

  const handleSelectUser = (user: any, type: 'editors' | 'accessors') => {
    updateForm({ ...form, [type]: user.userId });
  };

  const handleSelectBrands = (
    value: any,
    type: 'brands' | 'offices' | 'brandCategories',
    valueField?: 'officeid' | 'brandid',
  ) => {
    updateForm({
      ...form,
      accessorGroups: {
        ...form?.accessorGroups,
        [type]: valueField ? value[valueField] : value,
      },
    });
  };

  const shouldDisabledSubmit = () => {
    if (!form?.name) {
      return true;
    }

    let accessorsExisted = false;

    if (form?.accessors?.length) {
      accessorsExisted = true;
    } else if (form?.accessorGroups?.offices?.length) {
      accessorsExisted = true;
    } else if (form?.accessorGroups?.brands?.length) {
      accessorsExisted = true;
    } else if (form?.accessorGroups?.brandCategories?.length) {
      accessorsExisted = true;
    }

    // Template must be have at least 1 accessor and 1 editor
    return !accessorsExisted || !form?.editors?.length;
  };

  useEffect(() => {
    if (!isOpen || !template?.id) {
      updateForm({
        ...INITIAL_TEMPLATE,
        accessors: template?.accessors || [],
        editors: template?.editors || [],
      });
    } else if (template?.id) {
      updateForm(template);
    }
  }, [isOpen, template]);

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses={modalHeaderConfigs.icon}
      PulseModalHeaderProps={{
        onCloseClick: onCancel,
        primaryText: modalHeaderConfigs.primaryText,
      }}
      onModalClose={onCancel}
      PulseModalFooterProps={{
        classes: {
          root: [styles['timeline-template-modal__footer']],
        },
        confirmText: 'Save Template',
        confirmIconClasses: clsx(styles['timeline-template-modal__footer__confirm-btn_icon'], 'fal fa-save'),
        confirmDisabled: shouldDisabledSubmit(),
        onConfirmClick: handleSaveTemplate,
        cancelComponent: (
          <div className={styles['timeline-template-modal__footer__btn-container']}>
            <PulseButtonBase label="cancel" onClick={onCancel} />
            {Boolean(template) && (
              <PulseButtonBase
                label="delete template"
                variant={ButtonVariants.text}
                classes={[styles['timeline-template-modal__footer__btn-container__delete-btn']]}
                onClick={onOpenDeleteModal}
              >
                <i className="fal fa-trash-alt"></i>
                <span>delete template</span>
              </PulseButtonBase>
            )}
          </div>
        ),
      }}
    >
      <div className={styles['timeline-template-modal']}>
        {!template?.id && (
          <div className={styles['timeline-template-modal__warning-ctn']}>
            <div className={styles['timeline-template-modal__warning-ctn__icon-wrapper']}>
              <i className="fal fa-info-circle"></i>
            </div>
            <div className={styles['timeline-template-modal__warning-ctn__content']}>
              <div className={styles['timeline-template-modal__warning-ctn__content__header']}>Permissions </div>
              <div className={styles['timeline-template-modal__warning-ctn__content__description']}>
                Choose your permissions settings for this template below. Please note the choices made are accumulative
                between Users, Groups, Brands and Offices.
              </div>
            </div>
          </div>
        )}
        <div className={styles['timeline-template-modal__form-group']}>
          <div className={styles['timeline-template-modal__form-group__label']}>
            <span>template title</span>
            <Tippy maxWidth="auto" content="Give your Template a title so you can find it.">
              <i className="fal fa-info-circle"></i>
            </Tippy>
          </div>
          <PulseInput
            InputBaseProps={{
              name: 'name',
              value: form.name,
              placeholder: 'Give your Template a title',
              onChange: handleValueChanged,
            }}
          />
        </div>
        <div className={styles['timeline-template-modal__form-group']}>
          <div className={styles['timeline-template-modal__form-group__label']}>
            <span>TEMPLATE ACCESS PERMISSIONS (USERS)</span>
            <Tippy maxWidth="auto" content="Selected Users can access this Template">
              <i className="fal fa-info-circle"></i>
            </Tippy>
          </div>
          <PulseProjectUsersSelect
            labelName=""
            isMulti={true}
            // TODO: Should not allow remove current user in create mode & onwer user in edit mode
            // TODO: Will be implement in pharse 3
            isClearable={false}
            pageSize={10}
            classes={{
              selectOptionRoot: styles['timeline-template-modal__form-group__select-ctn'],
              selectOptionLabel:
                form.accessors.length < 2 ? styles['timeline-template-modal__form-group__select-label'] : '',
            }}
            TippyProps={{
              popperOptions: {
                strategy: 'fixed',
              },
            }}
            dataStructure={{
              dataKey: 'data',
              isJsonApi: true,
              label: 'name',
              lastPage: 'meta.page.last_page',
              pageSize: 10,
              type: 'user',
              value: 'userid',
            }}
            url={`/v2/api/users?fields[users]=userid,name`}
            placeholder="Select Users to enable access"
            variant="select2"
            minCharacters={3}
            value={form.accessors}
            valueChangeHandler={value => {
              handleSelectUser(value, 'accessors');
            }}
          />
        </div>
        <div className={styles['timeline-template-modal__form-group']}>
          <div className={styles['timeline-template-modal__form-group__label']}>
            <span>TEMPLATE ACCESS PERMISSIONS (OFFICES)</span>
            <Tippy maxWidth="auto" content="Selected Offices can access this Template">
              <i className="fal fa-info-circle"></i>
            </Tippy>
          </div>
          <PulseClientsSelect
            labelName=""
            isMulti={true}
            pageSize={10}
            classes={{
              selectOptionRoot: styles['timeline-template-modal__form-group__select-ctn'],
            }}
            TippyProps={{
              popperOptions: {
                strategy: 'fixed',
              },
            }}
            extraParams={{ sort: 'title' }}
            placeholder="Select Offices to enable access"
            variant="select2"
            minCharacters={3}
            value={form?.accessorGroups?.offices || []}
            valueChangeHandler={value => handleSelectBrands(value, 'offices', 'officeid')}
          />
        </div>
        <div className={styles['timeline-template-modal__form-group']}>
          <div className={styles['timeline-template-modal__form-group__label']}>
            <span>TEMPLATE ACCESS PERMISSIONS (BRANDS)</span>
            <Tippy maxWidth="auto" content="Selected Brands can access this Template">
              <i className="fal fa-info-circle"></i>
            </Tippy>
          </div>
          <PulseBrandsSelect
            labelName=""
            isMulti={true}
            pageSize={10}
            classes={{
              selectOptionRoot: styles['timeline-template-modal__form-group__select-ctn'],
            }}
            TippyProps={{
              popperOptions: {
                strategy: 'fixed',
              },
            }}
            extraParams={{ sort: 'title' }}
            placeholder="Select Brands to enable access"
            variant="select2"
            minCharacters={3}
            value={form?.accessorGroups?.brands || []}
            valueChangeHandler={value => handleSelectBrands(value, 'brands', 'brandid')}
          />
        </div>
        <div className={styles['timeline-template-modal__form-group']}>
          <div className={styles['timeline-template-modal__form-group__label']}>
            <span>TEMPLATE ACCESS PERMISSIONS (BRANDS CATEGORIES)</span>
            <Tippy maxWidth="auto" content="Selected Brand Categories can access this Template">
              <i className="fal fa-info-circle"></i>
            </Tippy>
          </div>
          <PulseBrandCategoriesSelect
            labelName=""
            isMulti={true}
            pageSize={10}
            classes={{
              selectOptionRoot: styles['timeline-template-modal__form-group__select-ctn'],
            }}
            TippyProps={{
              popperOptions: {
                strategy: 'fixed',
              },
            }}
            extraParams={{ sort: 'title' }}
            placeholder="Select Brand Categories to enable access"
            variant="select2"
            minCharacters={3}
            value={form?.accessorGroups?.brandCategories || []}
            valueChangeHandler={value => handleSelectBrands(value, 'brandCategories')}
          />
        </div>
        <div className={styles['timeline-template-modal__form-group']}>
          <div className={styles['timeline-template-modal__form-group__label']}>
            <span>TEMPLATE EDIT PERMISSIONS (USERS)</span>
            <Tippy maxWidth="auto" content="Selected Users can edit this Template">
              <i className="fal fa-info-circle"></i>
            </Tippy>
          </div>
          <PulseProjectUsersSelect
            labelName=""
            // TODO: Should not allow remove current user in create mode & onwer user in edit mode
            // TODO: Will be implement in phase 3
            isClearable={false}
            isMulti={true}
            extraParams={{ edit_timeline_templates: 'y' }}
            pageSize={10}
            classes={{
              selectOptionRoot: styles['timeline-template-modal__form-group__select-ctn'],
              selectOptionLabel:
                form.accessors.length < 2 ? styles['timeline-template-modal__form-group__select-label'] : '',
            }}
            TippyProps={{
              popperOptions: {
                strategy: 'fixed',
              },
            }}
            placeholder="Select Users to enable editing"
            variant="select2"
            minCharacters={3}
            value={form.editors}
            valueChangeHandler={value => handleSelectUser(value, 'editors')}
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default TimelineTemplateModal;
