import React, { ReactElement } from 'react';
import eventTooltipStyles from 'components/pulse-resource-planner/components/event-tooltip.module.scss';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import { Actions } from 'components/pulse-resource-planner/reducers/resource-planner-reducer';
import { useResourcePlannerDispatch } from 'components/pulse-resource-planner/context/resource-planner-context';
import { EventTooltipHeaderType } from './event-tooltip-header-type';

const EventTooltipHeader = (props: EventTooltipHeaderType): ReactElement => {
  const dispatch = useResourcePlannerDispatch();
  const { eventRecord, resourceRecord } = props;

  const showEditBookingForm = () => {
    dispatch({
      type: Actions.editBooking,
      payload: {
        editBooking: eventRecord,
        eventRecord,
        selectedUser: resourceRecord,
      },
    });
  };

  return (
    <div className={eventTooltipStyles['event-tooltip__header-container']}>
      <div className={eventTooltipStyles['event-tooltip-dark-background']}>
        <h2 className={eventTooltipStyles['event-tooltip__title-primary']}>{eventRecord.timesheetActivity?.label}</h2>
      </div>
      {eventRecord.priority === 'escalated' && (
        <div className={eventTooltipStyles['event-tooltip-dark-background']}>
          <PulseIcon
            classes={{ icon: 'fal fa-fire', root: eventTooltipStyles['event-tooltip__escalated-icon'] }}
            iconName={''}
          />
        </div>
      )}
      {eventRecord.editable && (
        <div className={eventTooltipStyles['eventTooltip__edit-booking-button']} onClick={showEditBookingForm}>
          <PulseIcon classes={{ icon: 'fal fa-edit' }} iconName="" />
          <div className={eventTooltipStyles['eventTooltip__edit-booking-text']}>Edit booking</div>
        </div>
      )}
    </div>
  );
};

export default EventTooltipHeader;
