import React, { ReactElement, useState } from 'react';
import { PulseWizardProps, StepDataItem } from './pulse-wizard-types';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import styles from './pulse-wizard.module.scss';
import get from 'lodash/get';
import clsx from 'clsx';

const STEPPER_ORIENTATION = 'horizontal';

const PulseWizard = (props: PulseWizardProps): ReactElement => {
  const {
    stepsData,
    classes,
    nonLinear = true,
    activeStep = 0,
    onChangeStep,
    onSubmitClick,
    nextButtonProps,
    prevButtonProps,
    submitButtonProps,
  } = props;
  const [currentStep, setCurrentStep] = useState(activeStep);

  const stepContentClasses: string = clsx([styles['pulse-wizard__step-content'], get(classes, 'stepContent')]);

  const rootDestructure = { root: '' };

  const { root: stepperRoot, ...stepperClassesRest } = get(classes, 'stepper', rootDestructure);

  const stepperClasses: NonNullable<PulseWizardProps['classes']>['stepper'] = {
    root: clsx([styles['pulse-wizard'], stepperRoot]),
    ...stepperClassesRest,
  };

  const { root: stepRoot, ...stepClassesRest } = get(classes, 'step', rootDestructure);

  const stepClasses: NonNullable<PulseWizardProps['classes']>['step'] = {
    root: clsx([styles['pulse-wizard__step'], stepRoot]),
    ...stepClassesRest,
  };

  const { root: stepButtonRoot, ...stepButtonClassesRest } = get(classes, 'stepButton', rootDestructure);

  const stepButtonClasses: NonNullable<PulseWizardProps['classes']>['stepButton'] = {
    root: clsx([styles['pulse-wizard__step-button'], stepButtonRoot]),
    ...stepButtonClassesRest,
  };

  const { label, labelContainer, active, ...stepLabelClassesRest } = get(classes, 'stepLabel', {
    label: '',
    labelContainer: '',
    active: '',
  });

  const stepLabelClasses: NonNullable<PulseWizardProps['classes']>['stepLabel'] = {
    active: clsx([styles['pulse-wizard__step-label--active'], active]),
    horizontal: styles['pulse-wizard__step-label-horizontal'],
    iconContainer: styles['pulse-wizard__step-label-iconContainer'],
    label: clsx([styles['pulse-wizard__step-label'], label]),
    labelContainer: clsx([styles['pulse-wizard__step-label-container'], labelContainer]),
    ...stepLabelClassesRest,
  };

  const handleNext = (): void => {
    if (currentStep + 1 < stepsData.length) {
      setCurrentStep(currentStep => currentStep + 1);
      onChangeStep && onChangeStep(currentStep + 1);
    }
  };

  const handlePrev = (): void => {
    if (currentStep - 1 >= 0) {
      setCurrentStep(currentStep => currentStep - 1);
      onChangeStep && onChangeStep(currentStep - 1);
    }
  };

  const handleChangeStep = (step: number): void => {
    setCurrentStep(step);
    onChangeStep && onChangeStep(step);
  };

  return (
    <div className={styles['pulse-wizard-ctn']}>
      <Stepper
        data-testid="pulse-wizard__stepper"
        activeStep={currentStep}
        nonLinear={nonLinear}
        orientation={STEPPER_ORIENTATION}
        connector={<></>}
        classes={stepperClasses}
      >
        {stepsData.map((step: StepDataItem, index: number) => {
          return (
            <Step key={index} classes={stepClasses}>
              <StepButton
                icon={null}
                classes={stepButtonClasses}
                disableRipple
                onClick={(): void => handleChangeStep(index)}
              >
                <StepLabel classes={stepLabelClasses}>{step.label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {currentStep !== stepsData.length && <div className={stepContentClasses}>{stepsData[currentStep].component}</div>}
      <div className={styles['pulse-wizard__navigator']}>
        <div className={styles['pulse-wizard__nav-item--left']}>
          <PulseButtonBase onClick={handlePrev} {...prevButtonProps} />
        </div>
        <div className={styles['pulse-wizard__nav-item--right']}>
          {currentStep + 1 === stepsData.length ? (
            <PulseButtonBase onClick={onSubmitClick} {...submitButtonProps} />
          ) : (
            <PulseButtonBase onClick={handleNext} {...nextButtonProps} />
          )}
        </div>
      </div>
    </div>
  );
};
export default PulseWizard;
