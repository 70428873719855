import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { debounce, uniq } from 'lodash';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseInput from 'components/pulse-input/pulse-input';
import PCSSpinner, { PCSSpinnerVariants } from 'components/spinner/pcs-spinner';
import { PulseListSelectorItemData } from 'components/pulse-list-selector-item/pulse-list-selector-item-types';
import styles from './pulse-list-selector.module.scss';
import { PulseModalFooterProps } from 'components/pulse-modal-base/components/pulse-modal-footer/pulse-modal-footer-types';

const WARNING_TEXT = 'You have entered more than 200 projects only the first 200 projects will be processed';

type JobExtensionInputProps = {
  onSubmitValues: (values: Array<string>) => void;
};

const JobExtensionInput: FC<JobExtensionInputProps> = props => {
  const { onSubmitValues } = props;
  const [isInvalid, setInvalid] = useState<boolean>(false);

  const handleValueChanged = debounce(
    useCallback(
      ({ target: { value } }) => {
        if (!value) {
          onSubmitValues([]);
          return;
        }

        const rawJobsCode = value.split('\n').filter(Boolean);
        const numberOfJobCodes = uniq(rawJobsCode).length;
        setInvalid(numberOfJobCodes > 200);
        onSubmitValues(uniq(rawJobsCode));
      },
      [onSubmitValues],
    ),
    300,
  );

  return (
    <PulseInput
      label="Enter a list of job extension per line (max 200)"
      InputBaseProps={{
        classes: {
          input: styles['pulse-list__modal__text-area'],
        },
        multiline: true,
        minRows: 20,
        placeholder: 'Eg ZESK1752008',
        onChange: handleValueChanged,
      }}
      error={isInvalid ? WARNING_TEXT : ''}
    />
  );
};

type JobExtensionResultProps = {
  validJobsCode: Array<string>;
  inputJobsCode: Array<string>;
};

const JobExtensionResult: FC<JobExtensionResultProps> = props => {
  const { validJobsCode, inputJobsCode } = props;
  const invalidJobIds = inputJobsCode.filter(it => !validJobsCode.includes(it));
  return (
    <div className={styles['pulse-list__modal-result']}>
      <table className={styles['pulse-list__modal-result__table']}>
        <tr>
          <th colSpan={2} className={styles['pulse-list__modal-result__table-item']}>
            WE HAVE PROCESSED THE {inputJobsCode.length} JOB EXTENSIONS YOU HAVE ADDED
            <br />
            AND FOUND THE FOLLOWING RESULTS:
          </th>
        </tr>
        <tr>
          <th className={styles['pulse-list__modal-result__table-item']}>
            <div className={styles['pulse-list__modal-result__icon-wrapper']}>
              <i className="fas fa-check" />
            </div>
          </th>
          <td className={styles['pulse-list__modal-result__table-item']}>
            {validJobsCode.length}/{inputJobsCode.length} Job Extensions are valid and can be bulk added
          </td>
        </tr>
        <tr>
          <th className={styles['pulse-list__modal-result__table-item']}>
            <div
              className={clsx(
                styles['pulse-list__modal-result__icon-wrapper'],
                styles['pulse-list__modal-result__icon-wrapper--failed'],
              )}
            >
              <i className="fas fa-times" />
            </div>
          </th>
          <td className={styles['pulse-list__modal-result__table-item']}>
            {invalidJobIds.length}/{inputJobsCode.length} Job Extensions can not be added.
            <br /> The job extensions that could not be added are listed below:
            <ul className={styles['pulse-list__modal-result__invalid-job-list']}>
              {invalidJobIds.map(it => (
                <li key={it}>{it}</li>
              ))}
            </ul>
          </td>
        </tr>
      </table>
    </div>
  );
};

export type PulseListSelectorBulkAddProjectModalProps = {
  data?: PulseListSelectorItemData[];
  onConfirmAddProjects?: (jobsCode: Array<string>) => void;
  onSearchJobs?: (jobsCode: Array<string>) => void;
  onCloseModal?: () => void;
  classNames?: Partial<{
    bulkButton: string;
  }>;
};

export const PulseListSelectorBulkAddProjectModal: FC<PulseListSelectorBulkAddProjectModalProps> = props => {
  const { data, onConfirmAddProjects, onSearchJobs, onCloseModal } = props;

  const [jobsCode, setJobsCode] = useState<Array<string>>([]);
  const [validJobsCode, setValidJobsCode] = useState<Array<string>>([]);

  // Steps of bulk add projects
  // 1: input job codes
  // 2: loading
  // 3: results
  const [steps, setSteps] = useState<number>(1);

  const handleConfirmModal = useCallback(() => {
    onConfirmAddProjects?.(validJobsCode);
    onCloseModal?.();
  }, [onConfirmAddProjects]);

  const footerProps: PulseModalFooterProps = useMemo(() => {
    switch (steps) {
      case 1:
        return {
          classes: {
            root: [styles['pulse-list__modal-footer']],
          },
          cancelComponent: <></>,
          confirmDisabled: !jobsCode.length,
          confirmText: 'add projects',
          confirmIconClasses: clsx('fas fa-plus', styles['pulse-list__modal-footer__icon']),
        };
      case 2:
        // Not show footer if loading
        return {};
      default:
        return {
          cancelIconClasses: clsx('fas fa-chevron-left', styles['pulse-list__modal-footer__icon']),
          confirmDisabled: !validJobsCode.length,
          confirmText: 'add valid projects',
          confirmIconClasses: clsx('fas fa-plus', styles['pulse-list__modal-footer__icon']),
          onCancelClick: () => setSteps(1),
          onConfirm: () => handleConfirmModal(),
        };
    }
  }, [steps, validJobsCode, jobsCode]);

  const handleClose = useCallback(() => {
    // Do noting if loading
    if (steps === 2 || !onCloseModal) {
      return;
    }

    onCloseModal();
  }, [onCloseModal, steps]);

  const handleSubmit = useCallback(() => {
    // loading
    if (steps === 2) {
      return;
    }
    // Start search jobs
    if (steps === 1) {
      // call search job
      onSearchJobs && onSearchJobs(jobsCode);
      setSteps(2);
      return;
    }
    // Insert valid jobs
    handleConfirmModal();
  }, [steps, validJobsCode, jobsCode]);

  const renderSteps = () => {
    switch (steps) {
      case 1:
        return <JobExtensionInput onSubmitValues={setJobsCode} />;
      case 2:
        return (
          <div className={styles['pulse-list__modal-spinner__wrapper']}>
            <PCSSpinner
              classes={{
                root: [styles['pulse-list__modal-spinner']],
              }}
              variant={PCSSpinnerVariants.circularSpinner}
            />
          </div>
        );
      default:
        return <JobExtensionResult inputJobsCode={jobsCode} validJobsCode={validJobsCode} />;
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    if (steps === 2) {
      const jobsExtension = data.map(it => it.jobExtension);
      if (!jobsExtension.length) {
        setValidJobsCode([]);
        setSteps(3);
        return;
      }

      const validJobsExtension: Array<string> = [];
      jobsCode.forEach(it => {
        if (jobsExtension.includes(it)) {
          validJobsExtension.push(it);
        }
      });
      setValidJobsCode(validJobsExtension);
      setSteps(3);
    }
  }, [data]);

  return (
    <PulseModalBase
      open={true}
      onModalClose={handleClose}
      footer={steps !== 2}
      classes={{
        root: [styles['pulse-list__modal']],
      }}
      PulseModalHeaderProps={{
        classes: {
          root: styles['pulse-list__modal-header'],
        },
        primaryText: 'Bulk add projects',
        headerIconClasses: 'fas fa-plus',
      }}
      PulseModalFooterProps={{
        onConfirmClick: handleSubmit,
        ...footerProps,
      }}
    >
      <div className={styles['pulse-list__modal-content']}>{renderSteps()}</div>
    </PulseModalBase>
  );
};

export default memo(PulseListSelectorBulkAddProjectModal);
