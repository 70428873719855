import React, { ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import { PulseSelectOptionType, PulseSelectPropTypes } from '../base/pulse-select-base-types';
import { ValueType } from 'react-select';
import isFunction from 'lodash/isFunction';

const options = [
  { value: 'Agresso Account Manager', label: 'Agresso Account Manager' },
  { value: 'Agresso Project Owner', label: 'Agresso Project Owner' },
  { value: 'Project Manager', label: 'Project Manager' },
  { value: 'Specified Timesheet Approver', label: 'Specified Timesheet Approver' },
];

export const PulseProjectRoleSelect = React.memo(
  (props: PulseSelectPropTypes): ReactElement => {
    const {
      value = [],
      isMulti = false,
      valueChangeHandler,
      labelName = 'Project Role',
      requestFilterKey = 'projectRole',
    } = props;

    const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = (value: ValueType<PulseSelectOptionType>) => {
      isFunction(valueChangeHandler) &&
        valueChangeHandler({
          [requestFilterKey]: value,
        });
    };

    return (
      <PulseSelectBase
        dataStructure={null}
        value={value}
        isMulti={isMulti}
        labelName={labelName}
        staticOptions={options}
        changeHandler={handleValueChange}
        {...props}
      />
    );
  },
);

PulseProjectRoleSelect.displayName = 'PulseProjectRoleSelect';

export default PulseProjectRoleSelect;
