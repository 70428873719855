import React, { FC } from 'react';
import clsx from 'clsx';
import { Colors, IconSizes } from 'pulse-commons/types';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { UnsavedNotificationModalProps } from './unsaved-notification-modal-types';
import styles from './unsaved-notification-modal.module.scss';

const UnSaveNotificationModal: FC<UnsavedNotificationModalProps> = props => {
  const { isOpen, onCancel, onConfirm } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses="fal fa-exclamation-circle"
      PulseModalHeaderProps={{
        onCloseClick: onCancel,
        primaryText: 'UNSAVED CHANGES',
        classes: {
          root: 'header-root',
          closeBtn: 'header-root__close-btn',
        },
      }}
      footer={false}
      onModalClose={onCancel}
    >
      <div className={styles['unsaved-notificaiton-modal']}>
        <div className={styles['unsaved-notificaiton-modal__title']}>
          You have unsaved changes, are you sure you want to proceed?
        </div>
        <div className={styles['unsaved-notificaiton-modal__actions']}>
          <PulseButtonBase
            classes={[styles['unsaved-notificaiton-modal__button']]}
            label="RERTURN TO TIMELINE"
            onClick={onCancel}
          />
          <PulseButtonBase
            classes={[
              clsx(styles['unsaved-notificaiton-modal__button'], styles['unsaved-notificaiton-modal__button--confirm']),
            ]}
            iconSize={IconSizes.sm}
            color={Colors.success}
            onClick={onConfirm}
            label="Proceed"
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default UnSaveNotificationModal;
