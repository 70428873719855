export const FORMAT_DATA_FUNCTION = `
def format_data:
  . as $item |
  {
    id: $item.ticketid,
    title: $item.title,
    startDate: $item.startDate,
    endDate: $item.dueDate,
    expanded: true,
    status: {
      id: $item.ticketStatus.statusid,
      title: $item.ticketStatus.name,
      colour: $item.ticketStatus.hexcolor,
      translatedStatusTitle: $item.ticketStatus.name
    },
    assignedTo: (
      if ($item.users | length > 0)
      then $item.users[0].name
      else ""
      end
    ),
    linkItem: $item.timelineItem
  };`;

export const RECURSIVE_FORMAT_CHILDREN_FUNCTION = `
def recursive_format_children:
  map(
    (. + {
      children: (
        if .children and (.children | length > 0)
        then (.children | recursive_format_children)
        else [] end
      )
    }) | (format_data + { children: (.children) })
  );`;

export default `
${FORMAT_DATA_FUNCTION}
${RECURSIVE_FORMAT_CHILDREN_FUNCTION}
{
  data: [
    .data[] | .attributes | format_data + { children: (.children | recursive_format_children) }
  ],
  page: .meta.page
}`;
