import React, { ReactElement, useCallback } from 'react';
import { PulseLinkedTaskProps } from './pulse-linked-task-types';
import PulseChip from 'components/pulse-chip/pulse-chip';
import styles from './pulse-linked-task.module.scss';
import isAfter from 'date-fns/isAfter';
import 'tippy.js/dist/svg-arrow.css';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import { IconSizes } from 'pulse-commons/types';
import isFunction from 'lodash/isFunction';
import clsx from 'clsx';
import format from 'date-fns/format';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';

const DATE_FORMAT = 'MM-dd-yyy';
const END_DATE_COLOR_STYLE = {
  danger: '#d93a40',
  normal: '#fff',
};

export const PulseLinkedTask = (props: PulseLinkedTaskProps): ReactElement => {
  const {
    linkedTask,
    contentTippy,
    bookingEndDate = new Date(),
    onRemove,
    PulseIconOpenProps,
    PulseIconRemoveProps,
    TippyInfoProps,
    TippyNameProps,
    PulseIconInfoProps,
    PulseChipProps,
    tippyTarget,
    classes,
    iconOpenLink,
    iconRemove,
  } = props;
  const { tags, ticketStatus, ticketType, ticketUrl, dueDate, startDate } = linkedTask?.raw || {};

  const isTaskEDExceedingBookingED = useCallback(() => dueDate && isAfter(new Date(dueDate), bookingEndDate as Date), [
    dueDate,
    bookingEndDate,
  ]);

  const handleOpen = (): void => {
    window.open(ticketUrl, '_blank');
  };

  const getTags = (): string => {
    return tags?.map(tag => tag.name).join(', ') || '';
  };

  const getTaskName = (): string => {
    return `[#${linkedTask?.value}] ${linkedTask?.label}`;
  };

  const handleRemove = (): void => {
    linkedTask && isFunction(onRemove) && onRemove(Number(linkedTask.value));
  };

  return (
    <div className={clsx(styles['linkedTask__itemRoot'], classes?.root)}>
      <div className={clsx([styles['linkedTask__infoCtn'], classes?.infoCtn])}>
        <PulseTooltip
          TippyProps={{
            singleton: tippyTarget,
            interactive: true,
            content: contentTippy ? (
              React.cloneElement(contentTippy, { task: linkedTask })
            ) : (
              <div className={styles['linkedTask__tooltip']}>
                <div className={styles['linkedTask__tooltip--link']}>
                  <span className={styles['linkedTask__tooltip--label']}>Task ID: </span>
                  <a href={ticketUrl} target="_blank" rel="noreferrer">
                    {getTaskName()}
                  </a>
                </div>
                <div>
                  <span className={styles['linkedTask__tooltip--label']}>Start Date:</span>{' '}
                  {startDate ? format(new Date(startDate), DATE_FORMAT) : 'N/A'}
                </div>
                <div>
                  <span className={styles['linkedTask__tooltip--label']}>End Date:</span>
                  {dueDate && isTaskEDExceedingBookingED() && (
                    <PulseIcon
                      classes={{ icon: clsx('fal fa-exclamation-circle', styles['linkedTask__infoIcon--danger']) }}
                      iconName=""
                    />
                  )}
                  <span
                    style={{
                      color: isTaskEDExceedingBookingED() ? END_DATE_COLOR_STYLE.danger : END_DATE_COLOR_STYLE.normal,
                    }}
                  >
                    {dueDate ? dueDate && format(new Date(dueDate), DATE_FORMAT) : 'N/A'}
                  </span>
                </div>
                <div>
                  <span className={styles['linkedTask__tooltip--label']}>Task Type:</span>
                  {ticketType?.name}
                </div>
                <div>
                  <span className={styles['linkedTask__tooltip--label']}>Task Tags:</span>
                  {getTags()}
                </div>
              </div>
            ),
            ...TippyInfoProps,
          }}
        >
          <PulseIcon
            classes={{ icon: clsx('fal fa-exclamation-circle', styles['linkedTask__infoIcon']) }}
            iconName=""
            {...PulseIconInfoProps}
          />
        </PulseTooltip>
        <PulseTooltip
          classes={{
            childrenRoot: clsx(styles['linkedTask__nameTippy'], classes?.taskName),
            tooltipRoot: styles['taskName__root'],
          }}
          TippyProps={{
            interactive: true,
            content: getTaskName(),
            singleton: tippyTarget,
            ...TippyNameProps,
          }}
        >
          {getTaskName()}
        </PulseTooltip>
      </div>
      <div className={clsx(styles['linkedTask__actions'], classes?.actionCtn)}>
        <PulseChip
          label={ticketStatus?.name}
          color={ticketStatus?.hexcolor}
          classes={{
            root: styles['linkedTask__status'],
          }}
          PulseTooltipProps={{
            TippyProps: { singleton: tippyTarget },
          }}
          {...PulseChipProps}
        />
        <div className={styles['linkedTask__buttons']}>
          <PulseIconButton
            classes={{
              root: styles['linkedTask__openTicketBtn'],
              pulseIcon: {
                root: styles['linkedTask__btnIcon'],
                icon: iconOpenLink || 'fal fa-external-link',
              },
            }}
            size={IconSizes.sm}
            handleClick={handleOpen}
            {...PulseIconOpenProps}
            TippyProps={{
              content: 'View Task',
              interactive: true,
              singleton: tippyTarget,
              ...PulseIconOpenProps?.TippyProps,
            }}
          />
          <PulseIconButton
            classes={{
              root: styles['linkedTask__removeBtn'],
              pulseIcon: {
                root: styles['linkedTask__btnIcon'],
                icon: iconRemove || 'fal fa-trash-alt',
              },
            }}
            size={IconSizes.sm}
            handleClick={handleRemove}
            {...PulseIconRemoveProps}
            TippyProps={{
              content: 'Remove linked task',
              interactive: true,
              singleton: tippyTarget,
              ...PulseIconRemoveProps?.TippyProps,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PulseLinkedTask;
