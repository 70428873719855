import React, { ReactElement, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PulseIconButton from '../../pulse-icon-button/pulse-icon-button';
import { PresetDateRangesComponentProps, PresetType } from './pulse-preset-component-types';

import styles from './pulse-preset-component.module.scss';
import { IconSizes } from 'pulse-commons/types';

const defaultPresets: PresetType[] = [
  { id: 1, label: 'Default' },
  { id: 2, label: 'Today' },
  { id: 3, label: 'Current Week' },
  { id: 4, label: 'Current Month' },
  { id: 5, label: 'Current Quarter' },
  { id: 6, label: 'Current Year' },
];

const PresetDateRangesComponent = (props: PresetDateRangesComponentProps): ReactElement => {
  const { onClick, customPresets = defaultPresets } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const handleShowPresetMenu = (e: React.MouseEvent<HTMLSpanElement>): void => {
    console.log('test');
    setAnchorEl(e.currentTarget);
  };

  const handleHidePresetMenu = (): void => {
    setAnchorEl(null);
  };

  const handlePresetClick = (id: number): void => {
    console.log('test');
    onClick(id);
  };

  return (
    <div data-testid={'date-range-picker-presets'}>
      <PulseIconButton
        classes={{
          root: styles['date-range-picker-presets__toggleButton'],
          pulseIcon: {
            icon: 'material-icons',
          },
        }}
        iconName="more_vert"
        handleClick={handleShowPresetMenu}
        size={IconSizes.lg}
      />
      <Popper
        id="date-range-picker-presets"
        className="date-range-picker__presets"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleHidePresetMenu}>
          <Paper>
            <MenuList>
              {customPresets.map(({ id, label }: { id: number; label: string }) => (
                <MenuItem
                  key={id}
                  className="uk-button uk-button-default quick-range__button"
                  onClick={(): void => handlePresetClick(id)}
                >
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default PresetDateRangesComponent;
