import { PulseDatepickerProps } from 'components/pulse-date-picker/pulse-datepicker-types';
import { RefObject } from 'react';
import isValid from 'date-fns/isValid';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
export interface PulseDatePickerState {
  anchorEl?: RefObject<HTMLDivElement>;
  inputFocus?: 'start' | 'end' | null;
  inputValues?: {
    start?: string | null;
    end?: string | null;
  };
  onChange?: PulseDatepickerProps['onChange'];
  values: PulseDatepickerProps['values'];
}

export enum Actions {
  'hideCalendarPicker',
  'set',
  'setDate',
  'setEndDate',
  'setSingle',
  'setStartDate',
  'showCalendarPicker',
  'validate',
}

export type PulseDatePickerAction =
  | {
      type: Actions.set;
      payload: Partial<PulseDatePickerState>;
    }
  | {
      type: Actions.setDate;
      payload: {
        date:
          | NonNullable<PulseDatePickerState['values']>['startDate']
          | NonNullable<PulseDatePickerState['values']>['endDate'];
      };
    }
  | {
      type: Actions.setEndDate;
      payload: {
        inputValues?: { end: NonNullable<PulseDatePickerState['inputValues']>['end'] };
        values: {
          endDate: NonNullable<PulseDatePickerState['values']>['endDate'];
        };
        inputFocus?: PulseDatePickerState['inputFocus'];
      };
    }
  | {
      type: Actions.setSingle;
      payload: {
        date: Date | null;
      };
    }
  | {
      type: Actions.setStartDate;
      payload: {
        inputValues?: { start: NonNullable<PulseDatePickerState['inputValues']>['start'] };
        values: {
          startDate: NonNullable<PulseDatePickerState['values']>['startDate'];
        };
        inputFocus?: PulseDatePickerState['inputFocus'];
      };
    }
  | {
      type: Actions.showCalendarPicker;
      payload: Pick<PulseDatePickerState, 'anchorEl' | 'inputFocus'>;
    }
  | {
      type: Actions.hideCalendarPicker;
      payload?: Pick<PulseDatePickerState, 'anchorEl'>;
    };

export const pulseDatePickerInitialState: PulseDatePickerState = {
  anchorEl: React.createRef(),
  inputFocus: null,
  values: {
    startDate: null,
    endDate: null,
  },
};

const PulseDatePickerReducer = (state: PulseDatePickerState, action: PulseDatePickerAction): PulseDatePickerState => {
  let isStartDateValid = !state.values?.startDate,
    isEndDateValid = !state.values?.endDate,
    payloadDate,
    isPayloadDateValid;

  switch (action.type) {
    case Actions.set:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.showCalendarPicker:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.hideCalendarPicker:
      return {
        ...state,
        anchorEl: undefined,
        inputFocus: null,
      };
    case Actions.setStartDate:
      isStartDateValid = isEmpty(action.payload.inputValues?.start) ? true : isValid(action.payload.values.startDate);
      return {
        ...state,
        inputFocus: action.payload.inputFocus || (isStartDateValid && 'end') || 'start',
        inputValues: {
          ...state.inputValues,
          start: action.payload.inputValues?.start,
        },
        values: {
          ...state.values,
          startDate: action.payload.values.startDate,
        },
      };
    case Actions.setEndDate:
      isEndDateValid = isEmpty(action.payload.inputValues?.end) ? true : isValid(action.payload.values.endDate);
      return {
        ...state,
        inputFocus: action.payload.inputFocus || (isEndDateValid && 'start') || 'end',

        inputValues: {
          ...state.inputValues,
          end: action.payload.inputValues?.end,
        },
        values: {
          ...state.values,
          endDate: action.payload.values.endDate,
        },
      };
    case Actions.setDate:
      payloadDate = action.payload.date;
      return {
        ...state,
        inputFocus: state.inputFocus === 'start' ? 'end' : 'start',
        inputValues: {
          start: null,
          end: null,
        },
        values: {
          startDate: state.inputFocus === 'start' ? payloadDate : state.values?.startDate,
          endDate: state.inputFocus === 'end' ? payloadDate : state.values?.endDate,
        },
      };
    case Actions.setSingle:
      payloadDate = action.payload.date;
      isPayloadDateValid = isValid(payloadDate);
      return {
        ...state,
        anchorEl: isPayloadDateValid ? undefined : state.anchorEl,
        inputFocus: isPayloadDateValid ? null : 'start',
        values: {
          startDate: payloadDate,
          endDate: payloadDate,
        },
      };
    default:
      return state;
  }
};

export default PulseDatePickerReducer;
