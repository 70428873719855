import React, { ReactElement } from 'react';
import { PulseFieldErrorProps } from './pulse-field-error-types';
import styles from './pulse-field-error.module.scss';
import clsx from 'clsx';

export const PulseFieldError = (props: PulseFieldErrorProps): ReactElement => {
  const { error, classes = {} } = props;

  if (!error) {
    return <></>;
  }
  return <span className={clsx(styles['pulse-field-error__root'], classes.root)}>{error}</span>;
};

PulseFieldError.displayName = 'PulseFieldError';

export default PulseFieldError;
