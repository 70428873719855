import React, { ReactElement, useState } from 'react';
import { PulseThumbnailProps } from './pulse-thumbnail-types';
import styles from './pulse-thumbnail.module.scss';
import clsx from 'clsx';
import { Document, Page, pdfjs } from 'react-pdf';
import PulseIcons from 'components/pulse-icons/pulse-icons';
import deepEqual from 'lodash/isEqual';
import { IconSizes } from 'pulse-commons/types';
import isFunction from 'lodash/isFunction';

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf-js/build/pdf.worker.js';

const RECOGNIZED_FILE_TYPE_IMAGE = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/webP', 'image/*'];
const RECOGNIZED_FILE_TYPE_VIDEOS = ['video/mp4'];
const RECOGNIZED_FILE_TYPE_PDF = ['application/pdf'];

const RECOGNIZED_FILE_TYPE = [
  ...RECOGNIZED_FILE_TYPE_IMAGE,
  ...RECOGNIZED_FILE_TYPE_VIDEOS,
  ...RECOGNIZED_FILE_TYPE_PDF,
];

/* istanbul ignore next */
const userAgent = (typeof navigator !== 'undefined' && navigator.userAgent) || '';
const isIE = /Trident/.test(userAgent);

const isEqual = (prevProps: PulseThumbnailProps, nextProps: PulseThumbnailProps) => {
  return prevProps.src === nextProps.src && deepEqual(prevProps.fileDetails, nextProps.fileDetails);
};

export const PulseThumbnail = React.memo((props: PulseThumbnailProps): ReactElement => {
  const { classes, extraComponents, fileType, fileDetails, onMouseEnter, onMouseLeave, PulseIconProps, src } = props;

  const [isPasswordProtected, setIsPasswordProtected] = useState(false);

  const isPDF = fileType && RECOGNIZED_FILE_TYPE_PDF.includes(fileType);
  const isVideo = fileType && RECOGNIZED_FILE_TYPE_VIDEOS.includes(fileType);
  const isImage = fileType && RECOGNIZED_FILE_TYPE_IMAGE.includes(fileType);

  const rootClassNames = clsx(styles['pulse-thumbnail__root'], classes?.root);
  const imageCtnClassNames = clsx(
    styles['pulse-thumbnail__imageCtn'],
    classes?.imageCtn,
    /* istanbul ignore next */
    isPDF && isPasswordProtected && styles['pulse-thumbnail__protected'],
  );
  return (
    <>
      <div
        className={rootClassNames}
        data-testid="pulse-thumbnail__root"
        onMouseEnter={isFunction(onMouseEnter) ? onMouseEnter(fileDetails) : undefined}
        onMouseLeave={isFunction(onMouseLeave) ? onMouseLeave(fileDetails) : undefined}
      >
        {isImage && (
          <div
            data-testid="pulse-thumbnail__image"
            className={imageCtnClassNames}
            style={{
              backgroundImage: `url(${src})`,
            }}
          >
            <PulseIcons
              iconName=""
              classes={{ root: styles['pulse-thumbnail__icon'], icon: 'fal fa-file-image' }}
              size={IconSizes.lg}
              {...PulseIconProps}
            />
          </div>
        )}
        {isPDF && (
          <>
            {isIE && (
              <div className={imageCtnClassNames} data-testid="pulse-thumbnail__pdf">
                <PulseIcons
                  iconName=""
                  classes={{ root: styles['pulse-thumbnail__icon'], icon: 'fal fa-file-pdf' }}
                  size={IconSizes.lg}
                />
                <span className={styles['pulse-thumbnail__infoText']}>IE Preview not available</span>
              </div>
            )}
            {!isPasswordProtected && !isIE && (
              <div className={imageCtnClassNames} data-testid="pulse-thumbnail__pdf">
                <Document
                  file={src}
                  onPassword={() => {
                    /* istanbul ignore next */
                    setIsPasswordProtected(true);
                  }}
                >
                  <Page pageNumber={1} height={74} renderAnnotationLayer={false} />
                </Document>
                <PulseIcons
                  iconName=""
                  classes={{ root: styles['pulse-thumbnail__icon'], icon: 'fal fa-file-pdf' }}
                  size={IconSizes.lg}
                />
              </div>
            )}
            {isPasswordProtected && !isIE && (
              <div className={imageCtnClassNames} data-testid="pulse-thumbnail__pdf">
                <PulseIcons
                  iconName=""
                  classes={{ root: styles['pulse-thumbnail__icon'], icon: 'fal fa-file-pdf' }}
                  size={IconSizes.lg}
                />
                <span className={styles['pulse-thumbnail__infoText']}>Document is protected.</span>
              </div>
            )}
          </>
        )}
        {isVideo && (
          <div className={imageCtnClassNames} data-testid="pulse-thumbnail__video">
            <video className={styles['pulse-thumbnail__video']}>
              <source src={src} type={fileType} />
            </video>
            <PulseIcons
              iconName=""
              classes={{
                root: clsx(styles['pulse-thumbnail__icon'], styles['pulse-thumbnail__icon--video']),
                icon: 'fal fa-film',
              }}
              size={IconSizes.lg}
            />
          </div>
        )}
        {fileType && !RECOGNIZED_FILE_TYPE.includes(fileType) && (
          <div className={imageCtnClassNames}>
            <PulseIcons
              iconName=""
              classes={{ root: styles['pulse-thumbnail__icon'], icon: 'fal fa-question-square' }}
              size={IconSizes.lg}
            />
            <span className={styles['pulse-thumbnail__infoText']}>Preview not available</span>
          </div>
        )}
        {extraComponents}
      </div>
    </>
  );
}, isEqual);

export default PulseThumbnail;
