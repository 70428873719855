// eslint-disable-next-line
import format from 'date-fns/format';

export enum PRESET_ID {
  pulseYearAndMonth = 'pulseYearAndMonth',
  pulseMonthAndDate = 'pulseMonthAndDate',
  pulseWeekAndDay = 'pulseWeekAndDay',
  pulseDay = 'pulseDay',
  pulseDayAnd6Hour = 'pulseDayAnd6Hour',
  pulseDayAndHourly = 'pulseDayAndHourly',
  pulseMonthAndQuarter = 'pulseMonthAndQuarter',
}
// Will handle in the future
// const pulseYearAndMonth = {
//   id: PRESET_ID.pulseYearAndMonth,
//   name: 'Pulse Year and Month',
//   tickWidth: 100, // Time column width in horizontal mode
//   tickHeight: 24, // Time column height in vertical mode
//   displayDateFormat: 'll LST',
//   defaultSpan: 1,
//   shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
//   shiftUnit: 'month', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.

//   timeResolution: {
//     // Dates will be snapped to this resolution
//     unit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
//   },
//   headers: [
//     // This defines your header rows from top to bottom
//     {
//       // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
//       unit: 'year',
//       increment: 1,
//       dateFormat: 'YYYY',
//     },
//     {
//       unit: 'month',
//       increment: 1,
//       dateFormat: 'MMM',
//     },
//   ],

//   columnLinesFor: 1,
// };

const pulseMonthAndDate = {
  id: PRESET_ID.pulseMonthAndDate,
  name: 'Pulse Month and Date',
  tickWidth: 40, // Time column width in horizontal mode
  tickHeight: 24, // Time column height in vertical mode
  displayDateFormat: 'll LST',
  defaultSpan: 1,
  shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
  shiftUnit: 'month', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.

  timeResolution: {
    // Dates will be snapped to this resolution
    unit: 'minute', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
    increment: 1,
  },
  headers: [
    // This defines your header rows from top to bottom
    {
      unit: 'month',
      increment: 1,
      dateFormat: 'MMMM YYYY',
    },
    {
      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
      unit: 'day',
      increment: 1,
      dateFormat: 'dd DD',
    },
  ],

  columnLinesFor: 1,
};

const pulseWeekAndDay = {
  id: PRESET_ID.pulseWeekAndDay,
  name: 'Pulse Week and Day',
  tickWidth: 100, // Time column width in horizontal mode
  tickHeight: 24, // Time column height in vertical mode
  displayDateFormat: 'None',
  defaultSpan: 1,
  shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
  shiftUnit: 'week', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.

  timeResolution: {
    // Dates will be snapped to this resolution
    unit: 'minute', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
    increment: 1,
  },
  headers: [
    // This defines your header rows from top to bottom
    {
      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
      unit: 'month',
      increment: 1,
      dateFormat: 'MMMM YYYY',
    },
    {
      unit: 'day',
      increment: 1,
      dateFormat: 'dd DD',
    },
  ],

  columnLinesFor: 1,
};

// eslint-disable-next-line
 const pulseDay = {
  id: PRESET_ID.pulseDay,
  name: 'Pulse Day',
  tickWidth: 200, // Time column width in horizontal mode
  tickHeight: 24, // Time column height in vertical mode
  displayDateFormat: 'll LST',
  defaultSpan: 1,
  shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
  shiftUnit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.

  timeResolution: {
    // Dates will be snapped to this resolution
    unit: 'minute', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
    increment: 1,
  },
  headers: [
    // This defines your header rows from top to bottom
    {
      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
      unit: 'month',
      increment: 1,
      dateFormat: 'w.WW MMMM YYYY',
    },
    {
      unit: 'day',
      increment: 1,
      dateFormat: 'dd DD',
    },
  ],

  columnLinesFor: 1,
};

// eslint-disable-next-line
const pulseDayAnd6Hour = {
  id: PRESET_ID.pulseDayAnd6Hour,
  name: 'Pulse Day and 6 hours',
  tickWidth: 50, // Time column width in horizontal mode
  tickHeight: 24, // Time column height in vertical mode
  displayDateFormat: 'll LST',
  defaultSpan: 1,
  shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
  shiftUnit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.

  timeResolution: {
    // Dates will be snapped to this resolution
    unit: 'hour', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
    increment: 1,
  },
  headers: [
    // This defines your header rows from top to bottom
    {
      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
      unit: 'day',
      increment: 1,
      dateFormat: 'DD MMMM YYYY',
    },
    {
      unit: 'hour',
      increment: 6,
      dateFormat: 'HH:mm',
    },
  ],

  columnLinesFor: 1,
};

// eslint-disable-next-line
 const pulseDayAndHourly = {
  id: PRESET_ID.pulseDayAndHourly,
  name: 'Pulse Day and hourly',
  tickWidth: 65, // Time column width in horizontal mode
  tickHeight: 24, // Time column height in vertical mode
  displayDateFormat: 'll LST',
  defaultSpan: 1,
  shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
  shiftUnit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.

  timeResolution: {
    // Dates will be snapped to this resolution
    unit: 'hour', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
    increment: 1,
  },
  headers: [
    // This defines your header rows from top to bottom
    {
      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
      unit: 'day',
      increment: 1,
      dateFormat: 'dd DD MMMM',
    },
    {
      unit: 'hour',
      increment: 1,
      dateFormat: 'hA',
    },
  ],

  columnLinesFor: 1,
};

// eslint-disable-next-line
 const pulseMonthAndQuarter = {
  id: PRESET_ID.pulseMonthAndQuarter,
  name: 'Pulse Month and Quarters',
  tickWidth: 100, // Time column width in horizontal mode
  tickHeight: 24, // Time column height in vertical mode
  displayDateFormat: 'll LST',
  defaultSpan: 1,
  shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
  shiftUnit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.

  timeResolution: {
    // Dates will be snapped to this resolution
    unit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
    increment: 1,
  },
  headers: [
    // This defines your header rows from top to bottom
    {
      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
      unit: 'month',
      increment: 1,
      dateFormat: 'MMMM YYYY',
    },
    {
      unit: 'day',
      increment: 6,
      renderer: (startDate, endDate): string => `${format(startDate, 'dd LLL')} - ${format(endDate, 'dd LLL')}`,
    },
  ],

  columnLinesFor: 1,
};

// always keep descending order from month to hour
export const presets = [pulseMonthAndQuarter, pulseMonthAndDate, pulseWeekAndDay, pulseDay, pulseDayAndHourly];

// days range for pre-load period in the past and future
export const DAYS_PERIOD_ZOOM_PRELOAD: Record<
  string,
  {
    initialPastDays: number;
    pastDays: number;
    futureDays: number;
    edgeDays: number;
  }
> = {
  [PRESET_ID.pulseDay]: {
    initialPastDays: 5,
    pastDays: 7,
    futureDays: 7,
    edgeDays: 2,
  },
  [PRESET_ID.pulseWeekAndDay]: {
    initialPastDays: 10,
    pastDays: 14,
    futureDays: 14,
    edgeDays: 5,
  },
  [PRESET_ID.pulseMonthAndDate]: {
    initialPastDays: 25,
    pastDays: 35,
    futureDays: 35,
    edgeDays: 12,
  },
  [PRESET_ID.pulseMonthAndQuarter]: {
    initialPastDays: 50,
    pastDays: 70,
    futureDays: 70,
    edgeDays: 24,
  },
  [PRESET_ID.pulseDayAndHourly]: {
    initialPastDays: 1,
    pastDays: 3,
    futureDays: 3,
    edgeDays: 1,
  },
};
