import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseStepForm from 'components/pulse-step-form/pulse-step-form';
import PulseListSelector from 'components/pulse-list-selector/pulse-list-selector';
import PulseInput from 'components/pulse-input/pulse-input';
import { PulseListSelectorItemData } from 'components/pulse-list-selector-item/pulse-list-selector-item-types';
import { PulseExportColumn, PulseExportModalProps } from './pulse-export-modal-types';
import styles from './pulse-export-modal-styles.module.scss';
import PulseExportReceivedModal from './pulse-export-received-modal';
import { v2Endpoint } from 'pulse-api/base';
import PulseLoader from 'components/pulse-loader/pulse-loader';

const PulseExportModal: FC<PulseExportModalProps> = props => {
  const {
    modalTitle,
    defaultExportFilename = '',
    exportTitlePlaceholder = '',
    columns: exportColumns,
    defaultExportColumns = [],
    url,
    payload,
    onClose,
    onExport,
    stepperProps,
  } = props;
  const formikRef = useRef<any>();

  const [isShowReceivedModal, setIsShowReceivedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Cretate state for sorting and change selected columns
  const [columns, setColumns] = useState<Array<PulseExportColumn>>(
    exportColumns.map(columns => ({ ...columns, export: defaultExportColumns.some(it => it.field === columns.field) })),
  );
  const [exportTitle, setExportTitle] = useState('');

  const handleSelectAllChanged = () => {
    // Select all field if any field not selected
    if (columns.some(it => !it.export)) {
      return setColumns([...columns].map(it => ({ ...it, export: true })));
    }

    // Reset to defaul export columns if all fields were selected
    return setColumns(
      [...columns].map(col => ({ ...col, export: defaultExportColumns.some(it => it.field === col.field) })),
    );
  };

  /**
   * Reorder export columns when drag & drop
   * @param item the ordered items after drag
   * @returns setState export columns
   */
  const handleDragEnd = (item: Array<PulseListSelectorItemData>) =>
    setColumns(
      item.map(it => ({
        field: (it.id as string) || '',
        text: it.primaryText || '',
        export: it.selected,
      })),
    );

  /**
   * Handle change checked status of each items in column's list
   * @param item the checked/un-checked item
   */
  const handleExportFieldSelected = (item: PulseListSelectorItemData) => {
    const index = columns.findIndex(it => it.field === item.id);
    const nextColumns = [...columns];
    nextColumns[index].export = !item.selected;
    setColumns(nextColumns);
  };

  /**
   * Use formik for validations data
   */
  const handleSubmitData = useCallback((): void => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  }, [formikRef]);

  const handleSubmit = useCallback(
    async values => {
      const selectedColumns = columns.filter(it => it.export);

      if (selectedColumns.length === 0) return;

      if (onExport) {
        onExport({
          exportTitle: values.exportTitle,
          columns: selectedColumns,
        });
        setExportTitle(values.exportTitle);
        setIsShowReceivedModal(true);
        return;
      }

      if (url && payload) {
        const requestObj = { ...payload.requestObj };
        requestObj.Title = values.exportTitle;
        requestObj.Columns = selectedColumns;

        const formData = new FormData();
        formData.append('report_type_id', payload.reportTypeId);
        formData.append('request_json', JSON.stringify(requestObj));
        setIsLoading(true);
        try {
          await v2Endpoint.post(url, formData);
          setExportTitle(values.exportTitle);
          setIsShowReceivedModal(true);
        } catch (err) {
          window?.utilities?.notification.warning('Export failed!');
        } finally {
          setIsLoading(false);
        }
      }
    },
    [onExport, url, payload, columns],
  );

  // Remaping columns if export columns is data from api
  useEffect(() => {
    if (exportColumns && exportColumns.length) {
      const columnConfig = exportColumns.map(cols => ({
        ...cols,
        export: defaultExportColumns.some(it => it.field === cols.field),
      }));
      setColumns(columnConfig);
    }
  }, [exportColumns]);

  if (isShowReceivedModal) {
    return <PulseExportReceivedModal modalTitle={modalTitle} exportTitle={exportTitle} onClose={onClose} />;
  }

  return (
    <PulseModalBase
      open
      footerConfirmText="Export"
      footerConfirmIconClasses={clsx(styles['pulse-export-modal__footer--icon'], 'fal fa-file-export')}
      headerPrimaryText={modalTitle}
      headerIconClasses={clsx(styles['pulse-export-modal__header--icon'], 'fal fa-file-export')}
      classes={{
        root: [styles['pulse-export-modal__container']],
      }}
      PulseModalHeaderProps={{
        classes: {
          root: [styles['pulse-export-modal__header']],
          titlePrimary: [styles['pulse-export-modal__header--label']],
        },
      }}
      onModalClose={onClose}
      onCancelClick={onClose}
      onConfirmClick={handleSubmitData}
    >
      <>
        <PulseLoader isVisible={isLoading} />
        <Formik
          innerRef={formikRef}
          initialValues={{ exportTitle: defaultExportFilename }}
          validationSchema={Yup.object().shape({
            exportTitle: Yup.string().required(' '),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, touched, errors }) => (
            <Form>
              <PulseStepForm
                nonLinear
                stepperProps={{
                  orientation: 'vertical',
                  ...stepperProps,
                }}
                classes={{
                  stepLabel: {
                    root: styles['pulse-export-modal__step-form__item'],
                    label: styles['pulse-export-modal__step-form__item--label'],
                  },
                  stepper: undefined,
                  step: {
                    root: styles['pulse-export-modal__step-form'],
                  },
                  stepContent: undefined,
                }}
                stepsData={[
                  {
                    label: 'Export report title',
                    component: (
                      <PulseInput
                        classes={{
                          label: styles['pulse-export-modal__export-file-name--label'],
                        }}
                        label=" "
                        required={true}
                        isInvalid={touched?.exportTitle && (errors?.exportTitle as boolean | undefined)}
                        InputBaseProps={{
                          placeholder: exportTitlePlaceholder,
                          name: 'exportTitle',
                          value: values.exportTitle,
                          onChange: handleChange,
                        }}
                      />
                    ),
                  },
                  {
                    label: 'Select Columns for export',
                    component: (
                      <PulseListSelector
                        allowSelectAll
                        allowDropAndDrag
                        classes={{
                          root: [styles['pulse-export-modal__step-form__item__slection-list']],
                        }}
                        SelectAllCheckboxProps={{
                          label: 'Select all',
                          labelClasses: styles['pulse-export-modal__step-form__item__checkbox-label'],
                        }}
                        PulseListSelectorItemProps={{
                          data: {
                            classes: {
                              ctn: styles['pulse-export-modal__step-form__item__ctn'],
                            },
                            onSelect: (_, item) => handleExportFieldSelected(item),
                          },
                        }}
                        data={columns.map(it => ({
                          id: it.field,
                          selectable: true,
                          primaryText: it.text,
                          selected: it.export,
                        }))}
                        isSelectAll={!columns.find(it => !it.export)}
                        onSelectAll={handleSelectAllChanged}
                        onDragEnd={handleDragEnd}
                      />
                    ),
                  },
                ]}
              />
            </Form>
          )}
        </Formik>
      </>
    </PulseModalBase>
  );
};

export default memo(PulseExportModal, isEqual);
