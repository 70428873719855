import React, { ReactElement } from 'react';
// import { PCSButtonBasePropsTypes } from './pcs-button-base-types';
import PulseIcon from '../../pulse-icons/pulse-icons';
import { composeClassNameFromArray } from 'pulse-commons/helpers';
import styles from './pcs-button-base.module.scss';
import { Colors, ButtonVariants } from 'pulse-commons/types';
import PCSSpinner, { PCSSpinnerVariants } from '../../spinner/pcs-spinner';
import { PulseIconProps } from 'types/icon-types';

export type PCSButtonBasePropsTypes = {
  children?: React.ReactNode;
  classes?: string[];
  disabled?: boolean;
  icon?: boolean;
  label?: string;
  color?: Colors;
  iconClasses?: string | string[];
  iconName?: string;
  iconSize?: PulseIconProps['size'];
  clickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
  showLoadMoreSpinner?: boolean;
  variant?: ButtonVariants;
};

export const PCSButtonBase = (props: PCSButtonBasePropsTypes): ReactElement => {
  const {
    children,
    classes = [],
    clickHandler,
    color = Colors.default,
    disabled = false,
    icon = false,
    iconClasses,
    iconName,
    iconSize,
    label = 'My Button',
    showLoadMoreSpinner = false,
    variant = ButtonVariants.contained,
  } = props;
  const buttonClassNameArray = [
    styles['pcs-button'],
    styles['pcs-button--base'],
    styles[variant],
    styles[color],
    ...classes,
  ];
  const buttonClassName = composeClassNameFromArray(buttonClassNameArray);
  return !children ? (
    <button data-testid="pcs-button" className={buttonClassName} onClick={clickHandler} disabled={disabled}>
      {icon && (
        <PulseIcon
          classes={{
            icon: iconClasses,
          }}
          iconName={iconName || ''}
          size={iconSize}
        />
      )}
      {label}
      {showLoadMoreSpinner && <PCSSpinner variant={PCSSpinnerVariants.circularSpinner} />}
    </button>
  ) : (
    <button
      data-testid="pcs-button-with-children"
      className={buttonClassName}
      onClick={clickHandler}
      disabled={disabled}
    >
      {children}
      {showLoadMoreSpinner && <PCSSpinner variant={PCSSpinnerVariants.circularSpinner} />}
    </button>
  );
};

export default PCSButtonBase;
