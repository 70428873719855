import React, { useState, ReactElement, useCallback, useEffect } from 'react';
import PulseInput from 'components/pulse-input/pulse-input';
import styles from '../user-setting-modal.module.scss';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import { IconSizes } from 'pulse-commons/types';
import clsx from 'clsx';
import { AssignedGroupsTabProps } from '../user-setting-modal-types';
import qs from 'qs';
import { groupsUrl, params } from '../user-setting-modal';
import { debounce } from 'lodash';
import { v2Endpoint } from 'pulse-api/base';
import { Group } from '../user-setting-modal-types';

const AssignedGroupsTab = ({
  availableGroups,
  selectedGroup,
  handleAddGroup,
  handleRemoveGroup,
}: AssignedGroupsTabProps): ReactElement => {
  const [searchKey, setSearchKey] = useState<string>('');
  const [groupList, setGroupList] = useState<Group[]>(availableGroups);

  const fetchGroups = (search: string) => {
    const filterParam = { ...params, keyword: search };
    const queryParamsString = qs.stringify(filterParam, { encode: false });

    if (search === '') {
      setGroupList(availableGroups);
      return;
    }

    v2Endpoint
      .get(`${groupsUrl}?${queryParamsString}`)
      .then(res => {
        setGroupList(res.data.data);
      })
      .catch(error => {
        window.utilities && window.utilities?.notification.danger(error);
      });
  };

  const deboundSearch = useCallback(
    debounce(searchKey => fetchGroups(searchKey), 300),
    [],
  );

  const handleChangeSearchKey = event => {
    setSearchKey(event.target.value);
    deboundSearch(event.target.value);
  };

  useEffect(() => {
    setGroupList(
      availableGroups.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      }),
    );
  }, [handleAddGroup, handleRemoveGroup]);

  return (
    <div className={styles['setting-modal__ctn']}>
      <div className={styles['setting-modal__row']}>
        <div className={styles['setting-modal__column']}>
          <PulseInput
            classes={{
              root: styles['search-input__root'],
              input: styles['search-input__input'],
              label: styles['search-input__label'],
            }}
            InputBaseProps={{
              placeholder: 'Search for Groups...',
              value: searchKey,
              onChange: handleChangeSearchKey,
              startAdornment: (
                <PulseIcon
                  classes={{
                    icon: clsx('fal fa-search', styles['input-icon__ctn']),
                  }}
                  iconName=""
                  size={IconSizes.lg}
                />
              ),
            }}
          />
        </div>
      </div>
      <div className={styles['setting-modal__row']}>
        <div className={styles['setting-modal__column']}>
          <p>Available</p>
          <i id={styles['setting-member-arrow']} className="fas fa-arrows-h"></i>
          <div className={styles['available-groups-list']}>
            {groupList ? (
              groupList.map(group => {
                return (
                  <div className={styles['list-item__groups']} key={group.id}>
                    <i className="fal fa-plus" onClick={() => handleAddGroup(group)}></i> {group.name}
                  </div>
                );
              })
            ) : (
              <i className="fa fa-circle-o-notch fa-spin"></i>
            )}
          </div>
        </div>
        <div className={styles['setting-modal__column']}>
          <p>Selected</p>
          <div className={styles['selected-groups-list']}>
            {selectedGroup.map(group => {
              return (
                <div className={styles['list-item__groups']} key={group.id}>
                  {group.name} <i className="fal fa-times" onClick={() => handleRemoveGroup(group)}></i>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedGroupsTab;
