import { TJsonaModel } from 'jsona/lib/JsonaTypes';
import { parseJsonApi } from 'pulse-commons/helpers';
import { PulseHistoryGridDataTypes } from './pulse-history-grid-types';

const transformResponse = (resData: TJsonaModel, exceptedKeysProp: string[]): PulseHistoryGridDataTypes => {
  const exceptedKeys = ['created_by', 'updated_by', ...exceptedKeysProp];
  const { user, new_values, old_values, id, updated_at } = resData;

  const filterKeys = (): string[] =>
    [...new Set([...Object.keys(new_values), ...Object.keys(old_values)])].filter(key => !exceptedKeys.includes(key));

  const filterAndMapValues = (values: TJsonaModel): string[] => filterKeys().map(key => values[key]);

  return {
    userName: user?.name || '',
    optionChanged: filterKeys(),
    id,
    oldValue: filterAndMapValues(old_values),
    newValue: filterAndMapValues(new_values),
    updatedDate: updated_at,
  };
};

/**
 * Transform json-api response data for request Bryntum grid
 */
const transformGridAjaxStoreData = (jsonData = {}, exceptedKeysProp: string[]): PulseHistoryGridDataTypes[] => {
  const parsedJsonApi = parseJsonApi(jsonData);
  return parsedJsonApi.map((item: TJsonaModel) => transformResponse(item, exceptedKeysProp));
};

export default transformGridAjaxStoreData;
