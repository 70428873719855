import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { PulseAnimatedPlusMinusProps } from './pulse-animated-plus-minus-types';
import styles from './pulse-animated-plus-minus.module.scss';

export const PulseAnimatedPlusMinus = (props: PulseAnimatedPlusMinusProps): ReactElement => {
  const { classes, open = false } = props;

  return (
    <div
      data-testid="animatedPlusMinusRoot"
      className={clsx(styles.animatedPlusMinusRoot, classes?.root, open && styles.open)}
    ></div>
  );
};

export default PulseAnimatedPlusMinus;
