import clsx from 'clsx';
import PulseSelectOption, { PulseSelectOptionProps } from 'components/pulse-select/base/components/pulse-select-option';
import React, { ReactElement, Ref, useMemo } from 'react';
import styles from './pulse-tags-option.module.scss';

export const PulseTagsOption = (props: PulseSelectOptionProps, ref: Ref<HTMLDivElement>): ReactElement => {
  const { classes, data = {}, isValue = false, PulseLabelProps, removeable } = props;

  const { classes: PulseLabelClasses } = PulseLabelProps || {};

  /**
   * We want to pass a custom PulseLabelProps
   */
  const internalPulseLabelProps: PulseSelectOptionProps['PulseLabelProps'] = useMemo(() => {
    return {
      classes: {
        ...PulseLabelClasses,
      },
      icon: false,
      isShowFull: !isValue,
      label: data?.label,
      ...PulseLabelProps,
    };
  }, [PulseLabelClasses, PulseLabelProps, isValue, data]);

  return (
    <PulseSelectOption
      {...props}
      classes={{
        ...classes,
        root: clsx([
          classes?.root,
          styles['pulse-tags-option__root'],
          isValue && styles['pulse-tags-option__root--is-value'],
          removeable && styles['pulse-tags-option__removeable'],
        ]),
      }}
      innerRef={ref}
      PulseLabelProps={internalPulseLabelProps}
    />
  );
};

export default React.forwardRef<HTMLDivElement, PulseSelectOptionProps>(PulseTagsOption);
