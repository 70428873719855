import React, { ReactElement } from 'react';
import { Card, CardContent, CardMedia, Skeleton } from '@mui/material';
import PulseIcons from 'components/pulse-icons/pulse-icons';
import { PulseGalleryItemProps } from './pulse-gallery-item-types';
import styles from './pulse-gallery-item.module.scss';
import { IconSizes } from 'pulse-commons/types';
import clsx from 'clsx';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import PulseGalleryItemTooltip from '../pulse-gallery-item-tooltip/pulse-gallery-item-tooltip';
import { isFunction } from 'lodash';

/**
 * Get a random interger for setting
 * random width on Skeleton
 */
function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const PulseGalleryItem = (props: PulseGalleryItemProps): ReactElement => {
  const {
    assetMetaData,
    assetThumbnailSrc,
    CardProps,
    CardMediaProps,
    CardContentProps,
    classes,
    isLoading,
    onClick,
    PulseTooltipProps,
    selected,
  } = props;

  const handleOnClick = () => {
    isFunction(onClick) && onClick(assetMetaData.assetId);
  };

  return (
    <div
      data-testid="pulseGalleryItemRoot"
      className={clsx(styles.pulseGalleryItemRoot, classes?.root)}
      onClick={handleOnClick}
    >
      <Card
        elevation={0}
        {...CardProps}
        classes={{
          ...CardProps?.classes,
          root: clsx(
            styles.pulseGalleryItem__CardRoot,
            selected && styles.pulseGalleryItemRootSelected,
            CardProps?.classes?.root,
          ),
        }}
      >
        {isLoading ? (
          <Skeleton height={CardMediaProps?.height || 100} sx={{ transform: 'none', borderRadius: 0 }} />
        ) : (
          <CardMedia
            component="img"
            height="100"
            src={assetThumbnailSrc}
            {...CardMediaProps}
            classes={{
              ...CardMediaProps?.classes,
              root: clsx(styles.pulseGalleryItem__CardMediaRoot, CardMediaProps?.classes?.root),
            }}
          />
        )}
        <CardContent
          {...CardContentProps}
          classes={{
            ...CardContentProps?.classes,
            root: clsx(styles.pulseGalleryItem__CardContentRoot, CardContentProps?.classes?.root),
          }}
        >
          <span className={styles.pulseGalleryItem__CardTitle}>
            {isLoading ? (
              <Skeleton
                sx={{
                  width: getRandomInt(100, 300),
                }}
              />
            ) : (
              assetMetaData.title
            )}
          </span>
          <span className={styles.pulseGalleryItem__CardSubtitle}>
            <PulseTooltip
              {...PulseTooltipProps}
              TippyProps={{
                disabled: !Boolean(Object.keys(assetMetaData).length) || isLoading,
                content: !isLoading && <PulseGalleryItemTooltip data={assetMetaData} />,
                ...PulseTooltipProps?.TippyProps,
              }}
            >
              <PulseIcons
                classes={{ root: styles.pulseGalleryItem__SubtitleIcon, icon: 'fal fa-info-circle' }}
                iconName=""
                size={IconSizes.lg}
              />
            </PulseTooltip>
            <span className={styles.pulseGalleryItem__ImageSize}>
              {isLoading ? (
                <Skeleton
                  sx={{
                    width: getRandomInt(60, 100),
                  }}
                />
              ) : (
                `${assetMetaData.imagewidth}px x ${assetMetaData.imageheight}px`
              )}
            </span>
          </span>
        </CardContent>
      </Card>
    </div>
  );
};

export default PulseGalleryItem;
