import React, { ReactElement } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import { PulsePanelProps } from './pulse-panel-types';
import styles from './pulse-panel.module.scss';
import PCSSpinner, { PCSSpinnerVariants } from 'components/spinner/pcs-spinner';

export const PulsePanel = (props: PulsePanelProps): ReactElement => {
  const {
    isLoading = false,
    classes,
    SkeletonProps,
    spinnerClass,
    CardProps,
    CardHeaderProps,
    CardContentProps,
    variant = 'default',
  } = props;
  const { height = 12, width = 180, ...RestSkeletonProps } = SkeletonProps || {};
  const { classes: cardClasses, ...RestCardProps } = CardProps || {};
  const { classes: headerClasses, title, ...RestCardHeaderProps } = CardHeaderProps || {};

  const internalCardClasses = {
    root: clsx(
      styles['pulse-panel__card-root'],
      cardClasses?.root,
      variant === 'widget' && styles['pulse-panel__card-root--widget'],
    ),
  };

  const internalHeaderClasses = {
    ...headerClasses,
    action: clsx(styles['pulse-panel__header-action'], headerClasses?.action),
    root: clsx(styles['pulse-panel__header-root'], headerClasses?.root),
    title: clsx(styles['pulse-panel__header-title'], headerClasses?.title),
    content: clsx(styles['pulse-panel__header-content'], headerClasses?.content),
  };

  return (
    <div className={clsx(styles['pulse-panel__root'], classes?.root)}>
      <Card classes={internalCardClasses} {...RestCardProps}>
        <CardHeader
          title={isLoading ? <Skeleton height={height} width={width} {...RestSkeletonProps} /> : title}
          classes={internalHeaderClasses}
          {...RestCardHeaderProps}
        />
        <CardContent {...CardContentProps}>
          {isLoading ? (
            <PCSSpinner variant={PCSSpinnerVariants.circularSpinner} classes={spinnerClass} />
          ) : (
            CardContentProps?.children
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PulsePanel;
