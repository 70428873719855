import { EventModel, EventModelConfig } from '@bryntum/schedulerpro-thin';
import { PulseSelectOptionType } from 'components/pulse-select/base/pulse-select-base-types';
import { OptionType, TaskOptionType, UserOptionType } from '../components/booking-types';
import RPUserModel from './rp-user-model';
import { PulseColorData } from 'components/pulse-color-picker/pulse-color-picker-types';

export enum PriorityType {
  standard = 'standard',
  escalated = 'escalated',
}

export enum LockedType {
  y = 'y',
  n = 'n',
}

export type RPBookingJobType = {
  brandId: RPBookingJobType['raw']['brandId'];
  brandTitle: RPBookingJobType['raw']['brandTitle'];
  isNC: boolean;
  jobExtension: RPBookingJobType['raw']['jobExtension'];
  label: RPBookingJobType['raw']['jobTitle'];
  raw: {
    brandId: string | number;
    brandTitle: string;
    editMode: boolean;
    isAgresso: boolean;
    isNC: boolean;
    is_finance_manager: boolean;
    jobExtension: string;
    jobId: string | number;
    jobTitle: string;
    status: string;
  };
  value: RPBookingJobType['raw']['jobId'];
};

export type RPBookingModelConfig = Partial<RPBookingModel> & Partial<EventModelConfig>;

type RPJobType = PulseSelectOptionType & {
  jobExtension?: string;
  brandId?: string | number;
  brandTitle?: string;
  isNC?: boolean;
  isAgresso?: boolean;
};

interface RPBookingModel {
  bookingId: number | null;
  bookingStatus: {
    label: string;
    value: string;
  };
  bookedUser: {
    value: string;
    label: string;
    avatarUrl?: string;
    raw?: RPUserModel;
  };
  createdAt?: Date;
  description?: string;
  editable?: boolean;
  hoursPerDay: string;
  job?: RPJobType;
  linkedTasks?: TaskOptionType[];
  locked: LockedType;
  priority?: PriorityType;
  ptoRequestId: number | null;
  reportedBy: UserOptionType;
  requestedBy: UserOptionType[];
  showDescription?: boolean;
  timesheetActivity?: OptionType | null;
  totalHours: string;
  updatedBy?: UserOptionType | null;
  updatedAt?: Date;
  originalData: Record<string, any>;
  weekends?: Record<string, Date | number>[];
  bookingCustomColor?: PulseColorData;
  // flat booking color is customer
  isCustomBookingColor?: boolean;
}

class RPBookingModel extends EventModel {
  constructor(config: RPBookingModelConfig) {
    super(config);
  }

  static get fields(): Record<string, any>[] {
    return [
      { name: 'bookedUser' },
      { name: 'bookingId', type: 'number' },
      { name: 'bookingStatus' },
      { name: 'createdAt' },
      { name: 'description', type: 'string', dataSource: 'description' },
      { name: 'editable', type: 'boolean' },
      { name: 'eventColor' },
      { name: 'hoursPerDay' },
      { name: 'job', dataSource: 'job' },
      { name: 'linkedTasks', dataSource: 'linkedTasks' },
      { name: 'locked', dataSource: 'locked' },
      { name: 'priority' },
      { name: 'ptoRequestId', dataSource: 'ptoRequestId' },
      { name: 'reportedBy' },
      { name: 'requestedBy' },
      { name: 'showDescription', dataSource: 'showDescription' },
      { name: 'timesheetActivity', dataSource: 'timesheetActivity' },
      { name: 'totalHours' },
      { name: 'updatedBy' },
      { name: 'updatedAt' },
      { name: 'weekends' },
      { name: 'bookingCustomColor' },
      { name: 'isCustomBookingColor' },
    ];
  }
}

RPBookingModel.idField = 'bookingId';

export default RPBookingModel;
