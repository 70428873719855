import { PulseChipType } from 'pulse-commons/types';

export const getChipType = (chipLabel: string): string => {
  switch (chipLabel.toLowerCase()) {
    case 'pending approval':
    case 'uploading':
    case 'changes required':
    case 'pending client approval':
    case 'pending brief approval':
    case 'pending final approval':
    case 'awaiting approval':
    case 'clarification required':
    case 'feedback requested':
    case 'follow-up required':
    case 'pending requestor approval':
    case 'pending spec sheet':
    case 'ready to review':
      return PulseChipType.pending;
    case 'approved':
    case 'approved for delivery':
    case 'approved for submission':
    case 'approved with changes':
      return PulseChipType.approved;
    case 'in-progress':
    case 'in progress':
    case 'final testing':
    case 'with client':
      return PulseChipType.progress;
    case 'cancelled':
    case 'not relevant':
      return PulseChipType.cancelled;
    default:
      return PulseChipType.pending;
  }
};
