import { UserAuthenticate } from 'pulse-types/users';
import RPUserModel from '../models/rp-user-model';

export interface OptionType {
  label: string;
  value: string;
}

interface TicketStatusType {
  active: string;
  hexcolor: string;
  name: string;
}

interface TicketType {
  color: string;
  name: string;
}

interface TagType {
  name: string;
  tag_id: number;
}

export interface TaskOptionType extends OptionType {
  raw?: {
    [key: string]: any;
    ticketUrl: string;
    ticketStatus?: TicketStatusType;
    ticketType?: TicketType;
    tags?: TagType[];
    startDate?: string;
    dueDate?: string;
    ticketid?: number;
  };
  url?: string;
  taskUrl?: string;
}

export interface OptionWithRawType extends OptionType {
  raw?: any;
}

export interface CurrentUserType extends UserOptionType {
  authenticate: UserAuthenticate;
}

export interface UserOptionType extends OptionType {
  avatarUrl?: string;
  raw?: RPUserModel;
}

export interface JobOptionType extends OptionType {
  jobExtension?: string;
  brandId?: number;
  brandTitle?: string;
  isNC?: boolean;
}
export interface ClientType {
  clienid: number;
  company: string;
  finance_profile: string;
}

export enum BookingEventColor {
  red = 'red',
  pink = 'pink',
  purple = 'purple',
  violet = 'violet',
  indigo = 'indigo',
  blue = 'blue',
  cyan = 'cyan',
  teal = 'teal',
  green = 'green',
  lime = 'lime',
  yellow = 'yellow',
  orange = 'orange',
  'deep-orange' = 'deep-orange',
  gray = 'gray',
}

export enum BookingEventColorMap {
  standard = '#02979B',
  escalated = '#D52B14',
  pto = '#FFD14A',
  nonCharge = '#6015D9',
  standardTentative = 'background: repeating-linear-gradient(135deg,#2AB0AE,#2AB0AE 12px,#32BFBD 7.5px,#32BFBD 20px)',
  completedTentative = 'background: repeating-linear-gradient(135deg,#02979B,#02979B 12px,#32BFBD 7.5px,#32BFBD 20px)',
  escalatedTentative = 'background: repeating-linear-gradient(135deg,#FF3D22,#FF3D22 12px,#FF5C45 7.5px,#FF5C45 20px)',
  ptoTentative = 'background: repeating-linear-gradient(135deg,#FFDB6F,#FFDB6F 12px,#FFE7A2 7.5px,#FFE7A2 20px)',
  nonChargeTentative = 'background: repeating-linear-gradient(135deg,#7C2CFD,#7C2CFD 12px,#9757FF 7.5px,#9757FF 20px)',
  unApproved = 'background: repeating-linear-gradient(135deg,#0AB3FF,#0AB3FF 12px,#3BC2FF 7.5px,#3BC2FF 20px)',
}

export enum BookingEventColorType {
  standard = 'standard',
  escalated = 'escalated',
  pto = 'pto',
  nonCharge = 'non-charge',
  standardTentative = 'standard-tentative',
  completedTentative = 'completed-tentative',
  escalatedTentative = 'escalated-tentative',
  ptoTentative = 'pto-tentative',
  nonChargeTentative = 'no-charge-tentative',
  unApproved = 'un-approved',
}

export interface OverBookingList {
  date: string;
  overbooked_hours: number;
}
export interface ResourceOverbookedProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  userInfo?: { value: string; label: string; avatarUrl?: string | undefined; raw?: RPUserModel | undefined };
  overBookingList: OverBookingList[];
}
