import { DependencyModel, DependencyStore } from '@bryntum/gantt-thin';
import { PulseTimelineModel, PulseTimelineTaskModel } from 'components/pulse-timeline/model/pulse-timeline-model';
import { GanttDisplayModel, TaskMenuBeforeShowParam } from 'components/pulse-timeline/pulse-timeline-types';
import { add, addBusinessDays, differenceInBusinessDays, isSunday, isWeekend } from 'date-fns';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { v2Endpoint } from 'pulse-commons/api';
import QueryString from 'qs';
import { SESSION_STORAGE_GANTT_DISPLAY_CONFIG } from './column-helpers';
import { Model } from '@bryntum/core-thin';

export const DEFAULT_CONSTRAINT_TYPE = 'startnoearlierthan';

type BryntumObject = Record<string, any>;

const FILTER_TO_DEEP_LINK = {
  departments: 'department',
  tags: 'tag',
};

export const deepLinktoFilterInitialState = (): Partial<Record<string, any>> => {
  const selectedFilter: Partial<Record<string, any>> = {
    status: [{ label: 'Any Status', value: 'any' }],
  };
  const deepLinks = window?.pulse?.request?.deepLinks;
  if (!isEmpty(deepLinks)) {
    Object.keys(deepLinks).forEach(key => {
      const filterValue: Array<{ label: string; value: string }> = [];
      Object.keys(deepLinks[key]).forEach(deepLinkValue => {
        filterValue.push({
          value: deepLinkValue,
          label: deepLinks[key][deepLinkValue],
        });
      });
      selectedFilter[key] = filterValue;
    });
  }
  return selectedFilter;
};

export const taskMenuBeforeShow = ({ items, taskRecord }: TaskMenuBeforeShowParam): void => {
  const taskDependencies = taskRecord.allDependencies;
  if (!taskDependencies.length || !items.removeDependency) {
    items.removeDependency = undefined;
    return;
  }

  items.removeDependency.menu = taskDependencies.map(taskDependency => ({
    text:
      taskRecord.id.toString() === taskDependency.from.toString()
        ? `${taskDependency.toTask.name} (${taskDependency.toTask.id})`
        : `${taskDependency.fromTask.name} (${taskDependency.fromTask.id})`,
    ...taskDependency,
    onItem: () => {
      taskDependency.remove();
    },
  }));
};

export const getPDFExportURL = (): string => {
  let url = '';

  try {
    url = window.pulse.config.urls.pdfgenerator;
  } catch (err) {
    window?.utilities?.notification.warning('PDF/PNG Export will be disabled due to missing pdfgenerator variable.');
  }

  return url;
};

export const getJWTToken = (): Promise<any> => v2Endpoint.post('/v2/api/auth/get-token');

interface PulseTimelineWeekendUpdater {
  startDate?: Date;
  endDate?: Date;
}

/**
 * When updating tasks
 * we need to update start and end dates for
 * weekend if it is not working
 *
 * @param {Object} source - Refer to https://www.bryntum.com/docs/gantt/api/Gantt/data/TaskStore#event-beforeUpdate
 * @param {boolean} isWeekendWorking
 */
export const beforeUpdate = async (
  source: { record: BryntumObject; changes: BryntumObject },
  isWeekendWorking: boolean,
): Promise<boolean> => {
  const { changes, record } = source;
  if (changes?.startDate || changes?.endDate) {
    const startDate = changes?.startDate || record.startDate;
    const endDate = changes?.endDate || record.endDate;
    const validDates: PulseTimelineWeekendUpdater = {};

    if (!isWeekendWorking) {
      // Somehow, the enddate and startdate is becoming same day when we drag a task on the weekend if if it is from sat to sun
      // Increase startDate if it is weekend
      if (isWeekend(startDate)) {
        validDates.startDate = add(startDate, { days: isSunday(startDate) ? 1 : 2 });
      }

      // Increase endDate if it is weekend
      if (isWeekend(endDate)) {
        validDates.endDate = add(endDate, { days: isSunday(endDate) ? 2 : 3 });
      }
    }

    // Check if start or end dates is weekend and set it to next weekdays
    if (!isEmpty(validDates)) {
      record.set({ ...validDates });
    }

    // Remove all children contraint if current items was removed constraint
    if (record.children?.length && Object.keys(changes).includes('constraintType') && changes.constraintType !== null) {
      record.setConstraint(null);
      return false;
    }
  }

  return true;
};

/**
 * When doing add task above or below
 * gantt is setting the startDate to the
 * no constraint date
 *
 * @param {Object} event - Refer to https://www.bryntum.com/docs/gantt/api/Gantt/data/TaskStore#event-beforeAdd
 * @param {Object} event.records
 */
export const beforeAddGanttRecord = async ({ records }: { records: Array<BryntumObject> }): Promise<void> => {
  records.forEach(record => {
    record.set({
      constraintType: DEFAULT_CONSTRAINT_TYPE,
      startDate: record.originalData.startDate,
    });
  });
};

export const filterToParams = (appliedFilter: BryntumObject): any => {
  let params = {};
  Object.keys(appliedFilter).forEach(key => {
    const filterArray: Array<any> = [];

    if (appliedFilter[key]?.forEach) {
      appliedFilter[key].forEach(element => {
        filterArray.push(element.value);
      });
    }

    if (filterArray.length) {
      params[key] = filterArray;
    }
  });
  params = renameKeys(params, FILTER_TO_DEEP_LINK);
  const queryString = stringifyQueryString(params);
  window.history.replaceState('', '', `${window.location.pathname}${queryString}`);
  return params;
};

const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

const stringifyQueryString = (params, options = {}) =>
  QueryString.stringify(params, {
    encode: false,
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    ...options,
  });

/**
 * Sync assignments between gantt and task form
 * @param {Object} targetTask // task in gantt chart
 * @param {Object} sourceTask // task in task form
 */
export const syncAssignments = (targetTask: BryntumObject, sourceTask: BryntumObject): void => {
  // Remove all current assignment
  targetTask.assignmentStore.remove(
    targetTask.assignmentStore.findByField('eventId', sourceTask.id).map(({ id }) => id),
  );

  targetTask.assignmentStore.add(
    sourceTask.assignedTo.map(assignedUser => ({
      id: assignedUser.assignedId,
      eventId: sourceTask.id,
      resourceId: assignedUser.id,
    })),
  );

  targetTask.assignmentStore.commit();
};

/**
 * Sync users between gantt and task form
 * @param {Object} targetTask // task in gantt chart
 * @param {Object} sourceTask // task in task form
 */
export const syncUsers = async (targetTask: BryntumObject, sourceTask: BryntumObject): Promise<void> => {
  const newUsers = sourceTask.assignedTo
    .filter(assignedUser => !targetTask.resourceStore.getById(assignedUser.id))
    .map(assignedUser => ({
      id: assignedUser.id,
      name: assignedUser.name,
      imageUrl: `/getUserProfileImage.php?${assignedUser.avatarUrl}`,
    }));

  await targetTask.resourceStore.add(newUsers);
};

/**
 * Sync data between gantt and task form
 * @param {Object} targetTask // task in gantt chart
 * @param {Object} sourceTask // task in task form
 * @returns {Promise<void>}
 */
export const syncSingleTaskFromForm = async (targetTask: BryntumObject, sourceTask: BryntumObject): Promise<void> => {
  if (!targetTask || !sourceTask) {
    return;
  }

  // Sync kpi
  targetTask.set('isKPI', sourceTask.kpi ? 'y' : 'n');

  // Sync users
  await syncUsers(targetTask, sourceTask);

  // Sync assignment
  syncAssignments(targetTask, sourceTask);
};

export class PulseTimelineApplyTemplateHelper {
  private taskMap: Map<number | string, any>;
  private dependencyStore: DependencyStore;
  private dependencies: Array<any>;
  private projectStart: string;
  private projectEnd: string;

  constructor(dependencyStore: DependencyStore) {
    this.taskMap = new Map();
    this.dependencyStore = dependencyStore;
    this.dependencies = [];
    this.projectEnd = '';
    this.projectStart = '';
  }

  public generatedTask(tasks: Array<any>): Array<PulseTimelineTaskModel> {
    return [...tasks].map(this.parseTask, this);
  }

  public generatedDependency(): Array<DependencyModel> {
    const data: Array<any> = [];

    for (const dependency of this.dependencies) {
      const from = this.taskMap.get(dependency.from_timeline_item_id);
      const to = this.taskMap.get(dependency.to_timeline_item_id);

      if (from && to) {
        data.push(
          new this.dependencyStore.modelClass({
            ...dependency,
            fromTask: from.id,
            fromEvent: from.id,
            toTask: to.id,
            toEvent: to.id,
          }),
        );
      }
    }
    return data;
  }

  public getTemplateDates(): any {
    return {
      startDate: this.projectStart,
      endDate: this.projectEnd,
    };
  }

  public shiftTemplateTaks(
    tasks: Array<any>,
    projectStartDate: string | Date,
    templateStartDate?: string | Date,
  ): Array<any> {
    let actualTemplateStartDate = templateStartDate ?? '';

    if (!actualTemplateStartDate) {
      const startDates = tasks.map(item => item.startDate);
      const orderedDates = startDates.sort(function (a, b) {
        return Date.parse(a) - Date.parse(b);
      });
      actualTemplateStartDate = orderedDates[0];
    }

    tasks.forEach((item: any) => {
      // eslint-disable-next-line prefer-const
      let taskItem = cloneDeep(item);
      const deltaStartDate = differenceInBusinessDays(new Date(taskItem.startDate), new Date(actualTemplateStartDate));
      const itemDuration =
        differenceInBusinessDays(moment(taskItem.endDate).toDate(), moment(taskItem.startDate).toDate()) + 1; // adding '1' day to match up with Bryntum's logic
      const newItemStartDate = addBusinessDays(new Date(projectStartDate), deltaStartDate).toString();

      const newStartDate = moment(newItemStartDate);
      newStartDate.set('hour', 9); // Making the tim efor start date to be 9:00 am
      taskItem['startDate'] = newStartDate.format('YYYY-MM-DD hh:mm:ss');
      taskItem['constraintDate'] = '';
      taskItem['duration'] = itemDuration;

      // shift the child tasks as well
      if (taskItem.children && (taskItem.children as Array<any>).length) {
        taskItem.children = this.shiftTemplateTaks(taskItem.children, projectStartDate, actualTemplateStartDate);
      }
    });

    return tasks;
  }

  private parseTask(data: any) {
    const { id = '', timeline_item_id = '', children, dependency = [], ...rest } = data;
    this.dependencies.push(...dependency);
    if (!rest.constraintType) {
      rest.constraintType = DEFAULT_CONSTRAINT_TYPE;
      rest.constraintDate = rest.startDate;
    }

    //TODO: Commenting this out as it is not required now. But not removing it until we make sure nothings breaks.
    // const taskStartDate = moment(rest.startDate);
    // const taskEndDate = moment(rest.endDate);

    // if (this.projectStart) {
    //   const currentStartDate = moment(this.projectStart);
    //   // Set project start date to import start date if current start date > import start date
    //   if (currentStartDate.isAfter(taskStartDate)) {
    //     this.projectStart = taskStartDate.format();
    //   }
    // } else {
    //   this.projectStart = taskStartDate.format();
    // }

    // if (this.projectEnd) {
    //   const currentEndDate = moment(this.projectEnd);

    //   // Set project end date to import end date if current end date < import start date
    //   if (currentEndDate.isBefore(taskEndDate)) {
    //     this.projectEnd = taskEndDate.format();
    //   }
    // } else {
    //   this.projectEnd = taskEndDate.format();
    // }

    const task = new PulseTimelineTaskModel(rest);

    // Continue add all children of current task
    if (children && (children as Array<any>).length) {
      task.appendChild((children as Array<any>).map(this.parseTask, this));
    }

    task.set('_id', id);

    // Push to map for mapping dependencies, assigments and resources
    this.taskMap.set(timeline_item_id, task);

    return task;
  }
}

export const updateSessionStorageGanttDisplayConfig = (display: GanttDisplayModel): void => {
  let localSetting = localStorage.getItem(SESSION_STORAGE_GANTT_DISPLAY_CONFIG) || 'NONE';
  const sessionValue = `,${display.name}`;
  if (display.enabled && !localSetting.includes(display.name)) {
    // Add value to storage
    localSetting += sessionValue;
  } else if (!display.enabled) {
    // Remove value in storage
    localSetting = localSetting.replace(sessionValue, '');
  }

  localStorage.setItem(SESSION_STORAGE_GANTT_DISPLAY_CONFIG, localSetting);
};

export const getStatusString = precentValue => {
  let updatedParentStatus = '';
  if (precentValue > 0 && precentValue <= 99) {
    updatedParentStatus = 'In Progress';
  } else if (precentValue > 99) {
    updatedParentStatus = 'Complete';
  } else updatedParentStatus = 'New';

  return updatedParentStatus;
};

export const isOpenViaPublicLinks = (): boolean => window.location?.href?.includes('public-links');
