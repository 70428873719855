import React, { FC, useMemo, useState } from 'react';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseTabs from 'components/pulse-tabs';
import { PulseTabData } from 'components/pulse-tabs/pulse-tabs-types';
import { PulseTabsTheme } from 'pulse-commons/types';

import { NonWorkingDaysModalProps, NonWorkingDaysModel } from './non-working-days-modal-types';
import styles from './non-working-days-modal.module.scss';
import OfficeNonWorkinDaysTabPanel from './components/office-non-working-days-tab-panel';
import TimelineNonWorkinDaysTabPanel from './components/timeline-non-working-days-tab-panel';
import WeekendNonWorkingDaysTabPanel from './components/weekend-non-working-days-modal';

const NonWorkingDaysModal: FC<NonWorkingDaysModalProps> = props => {
  const { timelineId, isWeekendWorking: defaultWeekendWorking, onCancel, onSave } = props;

  const [isWeekendWorking, setWeekendWorking] = useState<boolean>(defaultWeekendWorking);
  const [officeHolidays, setOfficeHolidays] = useState<Array<NonWorkingDaysModel>>([]);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const handleUpdateNonWorkingDays = () => {
    if (!onSave) {
      return;
    }
    const nonWorkingDays: Array<NonWorkingDaysModel> = [...officeHolidays];

    if (hasChanged) {
      nonWorkingDays.push({
        recurrentEndDate: 'on Mon at 0:00',
        recurrentStartDate: 'on Sat at 0:00',
        cls: 'weekend',
        name: 'weekend',
        isWorking: isWeekendWorking,
      });
    }

    onSave(nonWorkingDays, hasChanged);
  };

  const handleNonWorkingDayChange = () => setHasChanged(true);

  const handleCloseModal = () => {
    // Call save when close modal after changed timeline non-working days
    if (hasChanged && onSave) {
      return onSave([], true);
    }

    return onCancel();
  };

  const tabData: Array<PulseTabData> = useMemo(
    () => [
      {
        id: 'office-non-working-days',
        tabLabel: 'Office Non-Working days',
        TabPanelProps: {
          classes: {
            root: styles['non-working-days-modal__tabs__root--office'],
            content: styles['non-working-days-modal__tabs__content'],
          },
        },
        tabContent: (
          <OfficeNonWorkinDaysTabPanel
            timelineId={timelineId}
            officeNonWorkingDays={officeHolidays}
            onWorkingChanged={setOfficeHolidays}
          />
        ),
      },
      {
        id: 'timeline-non-working-days',
        tabLabel: 'Timeline Non-Working days',
        tabContent: (
          <TimelineNonWorkinDaysTabPanel timelineId={timelineId} onNonWorkingDayChange={handleNonWorkingDayChange} />
        ),
        TabPanelProps: {
          classes: {
            root: styles['non-working-days-modal__tabs__root--timeline'],
            content: styles['non-working-days-modal__tabs__content'],
          },
        },
      },
      {
        id: 'manage-weekends',
        tabLabel: 'Manage Weekends',
        TabPanelProps: {
          classes: {
            root: styles['non-working-days-modal__tabs__root--weekend'],
            content: styles['non-working-days-modal__tabs__content'],
          },
        },
        tabContent: (
          <WeekendNonWorkingDaysTabPanel
            isWeekendWorking={isWeekendWorking}
            onWeekendWorkingChanged={isWorking => {
              setWeekendWorking(isWorking);
              setHasChanged(true);
            }}
          />
        ),
      },
    ],
    [timelineId, isWeekendWorking, officeHolidays],
  );

  return (
    <PulseModalBase
      variant="jqconfirm"
      open
      headerIconClasses="fal fa-calendar-alt"
      classes={{
        dialog: {
          paper: styles['non-working-days-modal__dialog'],
        },
      }}
      PulseModalHeaderProps={{
        onCloseClick: handleCloseModal,
        primaryText: 'MANAGE NON-WORKING DAYS',
      }}
      onModalClose={handleCloseModal}
      PulseModalFooterProps={{
        classes: {
          root: [styles['non-working-days-modal__footer']],
        },
        confirmText: 'update',
        confirmIconClasses: 'fal fa-sync-alt',
        confirmDisabled: !(hasChanged || officeHolidays.length),
        onConfirmClick: handleUpdateNonWorkingDays,
      }}
    >
      <div className={styles['non-working-days-modal']}>
        <PulseTabs
          TabsProps={{
            classes: {
              flexContainer: styles['non-working-days-modal__tabs__flex-container'],
            },
          }}
          tabData={tabData}
          theme={PulseTabsTheme.light}
        />
      </div>
    </PulseModalBase>
  );
};

export default NonWorkingDaysModal;
