import React, { ReactElement, useEffect, useRef, Ref, forwardRef } from 'react';
import { PulseBryntumCalendarProps } from './pulse-bryntum-calendar-types';
import { BryntumCalendar } from '@bryntum/calendar-react-thin';
import '@bryntum/core-thin/core.stockholm.css';
import '@bryntum/grid-thin/grid.stockholm.css';
import '@bryntum/scheduler-thin/scheduler.stockholm.css';
import '@bryntum/schedulerpro-thin/schedulerpro.stockholm.css';
import '@bryntum/gantt-thin/gantt.stockholm.css';
import '@bryntum/calendar-thin/calendar.stockholm.css';
import styles from './pulse-bryntum-calendar.module.scss';
import clsx from 'clsx';
import mergeRefs from 'react-merge-refs';

const DEFAULT_CONFIG = {
  autoHeight: true,
};

export const PulseBryntumCalendar = (props: PulseBryntumCalendarProps, ref: Ref<any>): ReactElement => {
  const { ajaxStoreConfig, calendarProps, classes } = props;
  const calendarRef = useRef<BryntumCalendar | null>(null);

  const { listeners, ...restCalendarProps } = calendarProps || {};
  const bryntumCalendarClasses = clsx(styles['bryntumCalendar__root'], classes?.root);

  useEffect(() => {
    if (calendarRef.current) {
      console.log();
      calendarRef.current.instance.eventStore.on('beforeEventEdit', () => {
        console.log('beforeEventEdit');
      });
    }
  }, [calendarRef]);

  return (
    <div className={bryntumCalendarClasses}>
      <BryntumCalendar
        {...DEFAULT_CONFIG}
        ref={mergeRefs([ref, calendarRef])}
        listeners={{
          ...listeners,
        }}
        crudManager={ajaxStoreConfig}
        {...restCalendarProps}
      />
    </div>
  );
};

PulseBryntumCalendar.displayName = 'PulseBryntumCalendar';
export default forwardRef(PulseBryntumCalendar);
