import React from 'react';
import { PulseResourcePlannerProps } from './pulse-resource-planner-types';
import './pulse-resource-planner.theme.css';
import { ResourcePlannerProvider, ResourcePlannerProviderProps } from './context/resource-planner-context';
import ResourcePlanner from './components/resource-planner';
import { ResourcePlannerDeepLinkTransformer } from './transformers/resource-planner-transformers';

const PulseResourcePlanner = (props: PulseResourcePlannerProps): JSX.Element => {
  const { ResourcePlannerProviderProps, deepLinkFiltersMeta, ...restProps } = props;

  if (!ResourcePlannerProviderProps.hasOwnProperty('bookingPermissions')) {
    console.log(
      'User permissions was not provided. The following will default to false - canEditBooking, canViewBooking, isAgresso.',
    );
  }

  const ResourcePlannerProviderPropsWithDeepLinks: ResourcePlannerProviderProps = {
    ...ResourcePlannerProviderProps,
    deepLinkFilters: deepLinkFiltersMeta,
  };

  return (
    <ResourcePlannerProvider {...ResourcePlannerProviderPropsWithDeepLinks}>
      <ResourcePlanner {...restProps} />
    </ResourcePlannerProvider>
  );
};

PulseResourcePlanner.transformer = ResourcePlannerDeepLinkTransformer;

export default PulseResourcePlanner;
