import React, { ComponentType, FC, forwardRef, ReactElement, Ref, useEffect, useRef, useState } from 'react';
import './pulse-select-base.scss';
import { AsyncPaginate, withAsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
import { CancelToken, v2Endpoint } from 'pulse-commons/api';
import { groupOptionRequestTransformer, handlerSearch, optionTransformer } from '../utils';
import {
  AsyncSelectOptionType,
  AsyncSelectReturnTypes,
  PulseSelectBasePropTypes,
  PulseSelectOptionGroupTypes,
  PulseSelectOptionType,
} from './pulse-select-base-types';
import { transformQueryParams } from 'pulse-commons/helpers';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import clsx from 'clsx';
import Creatable from 'react-select/creatable';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PulseSelectMenuList from './components/pulse-select-menu-list';
import PulseSelectOption, { PulseSelectOptionProps } from './components/pulse-select-option';
import PulseWithMore from 'components/pulse-with-more/pulse-with-more';
import styles from './pulse-select-base.module.scss';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import { parseJsonApi } from 'pulse-commons/helpers';
import PulseMenu from 'components/pulse-menu/pulse-menu';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PulseFieldError from 'components/pulse-field-error/pulse-field-error';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import PCSSpinner, { PCSSpinnerVariants } from 'components/spinner/pcs-spinner';
import { PulseClassName } from 'pulse-commons/types';
import { useTranslation } from 'react-i18next';
import PulseSelectGroup from './components/pulse-select-group';
import { groupOptionApi } from 'pulse-api/group-options/group-options';
import { ResourcesType } from 'pulse-api/group-options/group-options-types';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import { ValueType } from 'react-select';

const isDevelopment = process.env.NODE_ENV === 'development';
const DEFAULT_ADDITIONAL = { page: 1 };

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

export const PulseSelectDropdownIndicator = React.forwardRef(
  (
    props: { onClick: (e: React.MouseEvent<HTMLDivElement>) => void; isMenuOpen?: boolean },
    ref: Ref<HTMLDivElement>,
  ) => {
    const { onClick, isMenuOpen = false } = props;
    return (
      <div
        data-testid="pulse-select__dropdownIndicator"
        ref={ref}
        className={clsx(
          styles['pulse-select__indicator'],
          styles['pulse-select__dropdownIndicator'],
          isMenuOpen && styles['pulse-select__dropdownIndicator--opened'],
        )}
        onClick={onClick}
      >
        <PulseIcon
          classes={{
            icon: 'fal fa-chevron-down',
          }}
          iconName=""
        />
      </div>
    );
  },
);

interface PulseSelectClearIndicator {
  classes?: {
    root?: PulseClassName;
  };
  clearValue: PulseSelectBasePropTypes['onChange'];
}

export const PulseSelectClearIndicator = React.forwardRef<HTMLDivElement, PulseSelectClearIndicator>(
  (props, ref): ReactElement => {
    const { classes, clearValue } = props;

    const handleClick = () => {
      isFunction(clearValue) && clearValue(null, { action: 'clear' });
    };

    return (
      <div
        ref={ref}
        data-testid="pulse-select__clearIndicator"
        className={clsx(styles['pulse-select__indicator'], classes?.root, 'pulse-select__clearIndicator')}
        onClick={handleClick}
      >
        <PulseIcon
          classes={{
            icon: 'fal fa-times',
          }}
          iconName=""
        />
      </div>
    );
  },
);

type ScrollContextType = {
  menuListScrollTop: number;
  onRemoveOption: () => void;
};

const scrollContextProviderValue: ScrollContextType = {
  menuListScrollTop: 0,
  onRemoveOption: (): void => {
    return;
  },
};

/**
 * Create a scroll context to ensure that PulseScrollbar scrollTop
 * remains where the user was.
 *
 * The wrappedMenuList component re initialises everytime a user
 * makes a selection. So scrollContext is here to set scrollTop
 * when the wrappedMenuList re-initialises.
 */
export const ScrollContext: React.Context<ScrollContextType> = React.createContext(scrollContextProviderValue);

const initialMaxWidth = 500;

let source: CancelTokenSource;

const cancelSource = (message = '') => {
  source && source.cancel(message);
};

export const PulseSelectBase = (props: PulseSelectBasePropTypes): ReactElement => {
  const { t } = useTranslation();
  const {
    changeHandler,
    classes = {},
    components,
    dataStructure,
    defaultOptions = false,
    error,
    extraParams,
    hideMenuOnValueChange = false,
    isCreatable = false,
    isDisabled: isDisabledProps = false,
    isInvalid,
    isMulti = false,
    isRequired,
    labelName,
    minCharacters,
    onMenuOpen,
    onMenuClose,
    renderSelectedValues = true,
    selectFirst = false,
    selectOnlyOption = false,
    staticOptions,
    selectMoreOptionLabel = 'more options',
    url,
    value = [],
    TippyProps,
    variant = 'default',
    isSearchable = true,
    insideModal = false,
    isGroup = false,
    handleGroupOptions,
    labelIcon,
    labelTag,
    requestBatching,
    customContainer,
    inheritedMaxWidth,
    isNew = false,
    valueLabelProps = {},
    ...restProps
  } = props;

  const [isShowNewLabel, setIsShowNewLabel] = useState<boolean>(false);

  let selectedValue;

  Array.isArray(value) && (selectedValue = value);
  !Array.isArray(value) && value && (selectedValue = [value]);
  !Array.isArray(value) && !value && (selectedValue = []);

  const [isEndpointError, setIsEndpointError] = useState(false);

  const getOptionsGroup = (requests: ResourcesType[]): Promise<AxiosResponse<{ data: PulseSelectOptionType[] }>> =>
    groupOptionApi.get({
      requests,
      jq: extraParams?.jq,
      axiosOptions: {
        cancelToken: source.token,
        withCredentials: true,
      },
      url,
    });

  const getOptions = (page: number, search: string): Promise<AxiosResponse<{ data: PulseSelectOptionType[] }>> => {
    const { isSolrEndpoint = false, isJsonApi = false, pageSize = 25, searchKey = 'search' } = dataStructure || {};

    /**
     * Set the default params for v1 or v2 old endpoints
     */
    let params: {
      [index: string]: any;
    } = {
      [searchKey]: search,
      page,
      ...extraParams,
    };

    /**
     * If it is a SOLR endpoint, then the query params
     * are constructed differently
     */
    isSolrEndpoint &&
      (params = {
        filters: [{ type: 'q', value: search }],
        page,
      });

    /**
     * If it is a JSON:API standard endpoint,
     * then push the pagination params first.
     * Then if there is a search term, append it
     * to the query params.
     *
     * Null values are not enabled on JSON:API yet
     */
    isJsonApi &&
      (params = {
        ...extraParams,
        page: {
          number: page,
          size: pageSize,
          per_page: pageSize,
        },
      });
    isJsonApi &&
      search &&
      (params = {
        ...params,
        filter: { ...params.filter, [searchKey]: search },
      });

    return v2Endpoint.get(url, {
      cancelToken: source.token,
      params,
      paramsSerializer: params => {
        return transformQueryParams(params, isSolrEndpoint ? 'indices' : undefined);
      },
      transformRequest: [
        data => {
          setIsLoading(true);
          return data;
        },
      ],
      withCredentials: true,
    });
  };

  const handleOptionOrOptionGroup = async (
    getData: () => Promise<AxiosResponse<{ data: PulseSelectOptionType[] }>>,
    page: number,
  ): Promise<{ options: AsyncSelectOptionType[]; hasMore: boolean }> => {
    const options: AsyncSelectOptionType[] = [];
    let hasMore = false;
    try {
      const res = await getData();

      if (isGroup) {
        options.push(...res.data.data);
      } else {
        if (dataStructure) {
          const { lastPage, dataKey, isJQ = false, isJsonApi = false } = dataStructure || {};

          const response =
            isJsonApi && !isJQ
              ? {
                  ...res,
                  data: {
                    ...res.data,
                    [dataKey]: parseJsonApi(res.data),
                  },
                }
              : res;

          hasMore = page < get(response.data, lastPage);
          const optionsFormat = optionTransformer({
            dataArray: response.data[dataKey] as Record<string, any>[],
            dataStructure,
          }) as AsyncSelectOptionType[];

          options.push(...optionsFormat);
        }
      }
      if (isFunction(changeHandler)) {
        const firstOptions = isGroup ? options[0].options : options;
        selectFirst && firstOptions[0] && changeHandler(firstOptions[0]);
        firstOptions.length === 1 && selectOnlyOption && changeHandler(firstOptions[0]);
      }
    } catch (error) {
      isDevelopment && !axios.isCancel(error) && console.log(error);
      /** Throw an error here so that cancelled request do not get
       * cached as an empty array
       */
      setIsEndpointError(true);
      return {
        options,
        hasMore: false,
      };
    }

    return { options, hasMore };
  };

  /* istanbul ignore next */
  const loadOptions = async (
    search: string,
    loadedOptions: AsyncSelectReturnTypes['options'],
    additional: AsyncSelectReturnTypes['additional'],
  ): Promise<AsyncSelectReturnTypes> => {
    let options: AsyncSelectReturnTypes['options'] = [];
    let hasMore = false;
    const { page = 1 } = additional || {};
    source = CancelToken.source();

    switch (true) {
      case isFunction(handleGroupOptions) && isGroup:
        if (handleGroupOptions) {
          options = await handleGroupOptions(source.token, search);
        }
        break;
      case isGroup && !!staticOptions && !!requestBatching:
        options = staticOptions
          ? (staticOptions as PulseSelectOptionGroupTypes[]).map(staticOption => {
              return {
                label: staticOption.label,
                options: staticOption.options.filter(option => handlerSearch(option, search)),
              };
            })
          : [];
        const shouldCallApi = search && (minCharacters ? search.length >= minCharacters : true);
        if (shouldCallApi && requestBatching) {
          const requests = groupOptionRequestTransformer(requestBatching as ResourcesType[], search);

          const results = await handleOptionOrOptionGroup(() => getOptionsGroup(requests), page);

          options.push({
            label: selectMoreOptionLabel,
            options: results.options.filter(it => !options[0].options.find(opt => opt.value === it.value)) as any,
          });
        }
        break;
      case !!requestBatching && isGroup:
        if (requestBatching) {
          const requests = groupOptionRequestTransformer(requestBatching, search);
          const results = await handleOptionOrOptionGroup(() => getOptionsGroup(requests), page);
          options = results.options;
        }
        break;
      case isGroup && !!staticOptions:
        options = staticOptions
          ? (staticOptions as PulseSelectOptionGroupTypes[]).map(staticOption => {
              return {
                label: staticOption.label,
                options: staticOption.options.filter(option => handlerSearch(option, search)),
              };
            })
          : [];
        break;
      case !staticOptions && !!dataStructure:
        if (dataStructure) {
          const result = await handleOptionOrOptionGroup(() => getOptions(page, search), page);
          options = result.options;
          hasMore = result.hasMore;
        }
        break;
      default:
        options = staticOptions ? staticOptions.filter(staticOption => handlerSearch(staticOption, search)) : [];
        break;
    }

    setIsLoading(false);

    return {
      options,
      hasMore,
      additional: {
        page: hasMore ? page + 1 : page,
      },
    };
  };

  /**
   * Handle load options and select first record
   */
  useEffect(() => {
    if (selectFirst) {
      loadOptions('', [], DEFAULT_ADDITIONAL);
    }
  }, [selectFirst]);

  useEffect(() => {
    if (!isEmpty(value) && hideMenuOnValueChange) {
      setIsMenuOpen(false);
    }
  }, [value]);

  useEffect(() => {
    return () => {
      resetScrollTop();
      cancelSource('Component unmounted');
    };
  }, []);

  /**
   *  Display label tag when isNew is true
   */
  useEffect(() => {
    setIsShowNewLabel(isNew);
  }, [isNew]);

  const SelectComponent = isCreatable ? CreatableAsyncPaginate : AsyncPaginate;

  const handleValueChange: PulseSelectBasePropTypes['onChange'] = (value: ValueType<any>, actionMeta) => {
    setIsShowNewLabel(!!value?.__isNew__);
    isFunction(changeHandler) && changeHandler(value, actionMeta);
    !isMulti && actionMeta.action !== 'clear' && closeMenu();
  };

  /* istanbul ignore next */
  const handleInputChange = () => {
    cancelSource('Cancelling previous request');
  };

  /**
   * This is to handle removing options from the PulseWithMore component
   * @param data The option that was removed from the PulseWithMore component
   */
  const handleRemoveOption = (data: any) => {
    const newValue = selectedValue.filter((selectedOption: any) => {
      return selectedOption.value !== data.value;
    });
    if (isFunction(changeHandler)) {
      changeHandler(newValue);
    }
  };

  const groupClasses = clsx(classes?.groupContainer);
  const defaultComponents: PulseSelectBasePropTypes['components'] = {
    ClearIndicator: () => null,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    LoadingIndicator: () => null,
    LoadingMessage: () => null,
    MenuList: wrapMenuList(PulseSelectMenuList) as FC<any>,
    Option: forwardRef((props, ref) => <PulseSelectOption variant={variant} {...props} innerRef={ref} />),
    Group: props => <PulseSelectGroup {...props} className={groupClasses} />,
    ...components,
  };

  if (isMulti) {
    defaultComponents.MultiValue = () => null;
  }

  if (!isSearchable) {
    defaultComponents.Control = () => null;
  }

  const defaultStyles: PulseSelectBasePropTypes['styles'] = {
    ...props.styles,
    groupHeading: provided => ({
      ...provided,
      color: '#000',
      fontSize: 11,
      fontWeight: 'bold',
      padding: 0,
      marginLeft: 4,
    }),
    control: provided => {
      return {
        ...provided,
      };
    },
    menu: provided => ({
      ...provided,
      borderRadius: 0,
      border: 0,
      boxShadow: 'none',
      position: 'relative',
      marginBottom: 0,
      marginTop: variant === 'v3' ? 0 : 4,
    }),
    placeholder: provided => ({
      ...provided,
    }),
    input: provided => ({
      ...provided,
      padding: 0,
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
    }),
  };

  const [inputValue, setInputValue] = useState('');

  const withMoreEl: Ref<HTMLDivElement> = useRef(null);
  const pulseSelectRef: Ref<HTMLDivElement> = useRef(null);
  const pulseClearIndicatorRef: Ref<HTMLDivElement> = useRef(null);
  const pulseSelectValueContainerRef = useRef(null);
  const pulseSelectIndicatorContainerRef: Ref<HTMLDivElement> = useRef(null);
  const dropdownIndicatorRef: Ref<HTMLDivElement> = useRef(null);
  const hiddenTippyRef: Ref<HTMLDivElement> = useRef(null);
  const moreLabelRef: Ref<HTMLSpanElement> = useRef(null);
  const [maxWidth, setMaxWidth] = useState(initialMaxWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectStyles, setSelectStyles] = useState(defaultStyles);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(isDisabledProps);

  /**
   * Calculate the maxWidth for the PulseWithMore
   * component so it does not push the indicators
   * over
   * @returns
   */
  const calcMaxWidth = (): number => {
    let maxWidth = 0;
    const pulseSelectRefEl = pulseSelectRef.current;
    const pulseSelectIndicatorContainerRefEl = pulseSelectIndicatorContainerRef.current;
    if (!pulseSelectRefEl || !pulseSelectIndicatorContainerRefEl) {
      return maxWidth;
    }

    const { marginLeft, marginRight } = window.getComputedStyle(pulseSelectIndicatorContainerRefEl);
    const pulseSelectContainer = pulseSelectRef.current?.querySelector(`.${styles['pulse-select__container']}`);
    const { paddingLeft = '0', paddingRight = '0', borderLeftWidth = '0', borderRightWidth = '0' } =
      (pulseSelectContainer && window.getComputedStyle(pulseSelectContainer)) || {};
    const indicatorWidth =
      pulseSelectIndicatorContainerRefEl.offsetWidth + parseFloat(marginLeft) + parseFloat(marginRight);

    const selectRootWidth =
      pulseSelectRefEl.clientWidth -
      parseFloat(paddingLeft) -
      parseFloat(paddingRight) -
      parseFloat(borderLeftWidth) -
      parseFloat(borderRightWidth) -
      10; // For some reason the width is 10px more

    maxWidth = selectRootWidth - indicatorWidth;

    return maxWidth;
  };

  /**
   * This will prevent react-select from setting the input value to
   * empty when an option is selected
   *
   * @param query The search string the user types in the input field
   * @param action Action meta from react-select component
   */
  const onInputChange = (query: string, { action }: { action: string }) => {
    /**
     * Need to check if query is set as
     * clicking outside triggers an inputChange...
     */
    query && handleInputChange();
    if (action !== 'set-value') {
      setInputValue(query);
    }
  };

  useEffect(() => {
    if (!pulseSelectRef.current || !pulseSelectIndicatorContainerRef) {
      console.warn('Indicators cannot align properly');
      return;
    }
    if (pulseSelectRef.current && pulseSelectIndicatorContainerRef.current) {
      setSelectStyles({
        ...selectStyles,
        container: provided => ({
          ...provided,
          width: pulseSelectRef.current?.clientWidth,
        }),
      });

      setMaxWidth(calcMaxWidth() || 0);
    }
  }, [pulseSelectRef.current, selectedValue.length]);

  const resetScrollTop = () => {
    scrollContextProviderValue.menuListScrollTop = 0;
    setIsMenuOpen(false);
  };

  const closeMenu = () => {
    if (isMenuOpen) {
      resetScrollTop();
      cancelSource('Menu closing');
      setIsMenuOpen(false);
      isFunction(onMenuClose) && onMenuClose();
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      pulseSelectRef.current?.querySelector('input')?.focus();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setIsLoading(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    defaultOptions && isLoading ? setIsDisabled(true) : setIsDisabled(isDisabledProps);
  }, [defaultOptions, isDisabledProps, isLoading]);

  const openMenu = (e: React.MouseEvent) => {
    const isTargetMoreTippy =
      (moreLabelRef.current && moreLabelRef.current.contains(e.target as HTMLSpanElement)) ||
      (hiddenTippyRef.current && hiddenTippyRef.current.contains(e.target as HTMLDivElement));
    const isTargetDropdown =
      dropdownIndicatorRef.current && dropdownIndicatorRef.current.contains(e.target as HTMLDivElement);
    isTargetMoreTippy && closeMenu();
    const isClearIndicator =
      pulseClearIndicatorRef.current && pulseClearIndicatorRef.current.contains(e.target as HTMLDivElement);

    if (isTargetDropdown || isTargetMoreTippy || isClearIndicator || isDisabled) {
      return false;
    }
    isEndpointError && setIsEndpointError(false);
    isFunction(onMenuOpen) && onMenuOpen();
    setIsMenuOpen(true);
  };

  const toggleMenu = () => {
    isEndpointError && setIsEndpointError(false);
    isMenuOpen && cancelSource('Menu closing');
    setIsMenuOpen(!isMenuOpen);
  };

  /* istanbul ignore next */
  window.onresize = debounce(
    /* istanbul ignore next */
    () => {
      setSelectStyles({
        ...selectStyles,
        container: provided => ({
          ...provided,
          width: pulseSelectRef.current?.clientWidth,
        }),
      });

      setMaxWidth(calcMaxWidth || 0);
    },
    300,
  );

  const ValueComponent = selectedValue.map((value: { value: string; label: string }) => {
    const DefaultOptionComponent = defaultComponents.Option as ComponentType<PulseSelectOptionProps>;
    return (
      <DefaultOptionComponent
        classes={{
          removeButton: clsx(
            styles['pulse-select-multi__optionRemoveButton'],
            variant === 'select2' && styles['pulse-select-multi__optionRemoveButton--select2'],
            variant === 'v3' && styles['pulse-select-multi__optionRemoveButton--v3'],
          ),
          root: clsx(
            styles['pulse-select-multi__optionRoot'],
            isDisabled && styles['pulse-select-multi__optionRoot-disabled'],
            classes.selectOptionRoot,
            variant === 'v3' && styles['pulse-select-multi__optionRoot--v3'],
          ),
        }}
        data={value}
        key={value.value}
        isValue
        label={value.label}
        onClick={handleRemoveOption}
        PulseLabelProps={{
          classes: {
            root: clsx(
              !isMulti && styles['pulse-select__labelRoot--single'],
              !isMulti && variant === 'select2' && styles['pulse-select__labelRoot--select2'],
              !isMulti && variant === 'v3' && styles['pulse-select__labelRoot--v3'],

              !isMulti && variant === 'select2' && isDisabled && styles['pulse-select__labelRoot--select2-disabled'],
              !isMulti && variant === 'v3' && isDisabled && styles['pulse-select__labelRoot--v3-disabled'],

              isMulti && styles['pulse-select-multi__pulseLabelRoot'],
              isMulti && variant === 'select2' && styles['pulse-select-multi__pulseLabelRoot--select2'],
              isMulti && variant === 'v3' && styles['pulse-select-multi__pulseLabelRoot--v3'],

              classes.selectOptionLabel,
            ),
            labelText: clsx(styles['pulse-select__labeltext']),
          },
          isShowFull: true,
          variant: variant === 'v3' ? 'v3' : undefined,
          ...valueLabelProps,
        }}
        removeable={isMulti && !isDisabled}
        variant={variant}
      />
    );
  });

  /**
   * We need to provide our own shouldLoadMore
   * logic as we are using react custom scrollbars
   */
  const shouldLoadMore = (scrollHeight: number, clientHeight: number): boolean => {
    const bottomBorder = scrollHeight - clientHeight - 10;
    return bottomBorder < scrollContextProviderValue.menuListScrollTop;
  };

  let shouldOpenMenu = isMenuOpen && (minCharacters ? inputValue.length >= minCharacters : true);
  if (staticOptions && !!requestBatching) {
    const hasRecentValue = !!staticOptions.length && !!(staticOptions[0].options || []).length;
    shouldOpenMenu = shouldOpenMenu || hasRecentValue;
  }

  return (
    <ScrollContext.Provider value={scrollContextProviderValue}>
      <ClickAwayListener onClickAway={closeMenu}>
        <div
          data-testid="pulse-select__root"
          ref={pulseSelectRef}
          className={clsx([
            styles['pulse-select__root'],
            classes.root,
            isDisabled && styles['pulse-select__root--disabled'],
          ])}
        >
          {labelName && (
            <label
              data-testid="pulse-select__label"
              className={clsx(
                styles['pulse-select__label'],
                variant === 'select2' && styles['pulse-select__label--select2'],
                variant === 'v3' && styles['pulse-select__label--v3'],
                classes.label,
              )}
              onClick={toggleMenu}
            >
              {t(labelName)}
              {isShowNewLabel && labelTag && (
                <div
                  data-testid="select-label-tag"
                  className={clsx(styles['pulse-select__label-tag'], labelTag?.newTag)}
                >
                  {labelTag.content}
                </div>
              )}
              {labelIcon && (
                <PulseTooltip
                  contentTooltip={labelIcon.tippyDescription}
                  classes={{
                    tooltipRoot: styles['pulse-select__label-icon'],
                  }}
                  TippyProps={{
                    ...labelIcon.TippyProps,
                    visible: !labelIcon.tippyDescription ? false : undefined,
                  }}
                >
                  <i className={labelIcon.icon} />
                </PulseTooltip>
              )}
              {isRequired && (
                <span
                  className={clsx(
                    styles['pulse-select__label-required'],
                    isInvalid && styles['pulse-select__label--invalid'],
                  )}
                >
                  *Required
                </span>
              )}
            </label>
          )}
          <PulseMenu
            classes={{
              childrenRoot: styles['pulse-select__dropdownRoot'],
              menuCtn: clsx(styles['pulse-select__dropdown'], classes.menu?.menuCtn),
            }}
            menuChildren={
              <div>
                <SelectComponent
                  additional={DEFAULT_ADDITIONAL}
                  backspaceRemovesValue={!isMulti}
                  blurInputOnSelect={!isMulti}
                  cacheUniqs={[staticOptions, isEndpointError]}
                  captureMenuScroll={false}
                  className={clsx('pulse-select__ctn', classes.select, variant)}
                  classNamePrefix="pulse-select"
                  closeMenuOnSelect={!isMulti}
                  components={defaultComponents}
                  controlShouldRenderValue={false}
                  debounceTimeout={500}
                  defaultOptions={defaultOptions}
                  hideSelectedOptions
                  inputValue={inputValue}
                  isMulti={isMulti}
                  key={labelName}
                  loadOptions={loadOptions}
                  menuIsOpen={shouldOpenMenu}
                  onChange={handleValueChange}
                  onInputChange={onInputChange}
                  pageSize={dataStructure?.pageSize || 25}
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={value}
                  shouldLoadMore={shouldLoadMore}
                  {...restProps}
                />
              </div>
            }
            TippyProps={{
              arrow: false,
              offset: [0, 0],
              zIndex: 1000,
              maxWidth: 350,
              ...TippyProps,
              popperOptions: {
                strategy: insideModal ? 'fixed' : 'absolute',
                ...TippyProps?.popperOptions,
              },
              visible: isMenuOpen, // force tippy's visibility listens on component state
              onHide: closeMenu, // update isMenuOpen when tippy was closed by mouse leave
            }}
          >
            {customContainer ? (
              <div onClick={openMenu}>{customContainer}</div>
            ) : (
              <div
                className={clsx(
                  styles['pulse-select__container'],
                  styles[
                    `pulse-select__container${variant === 'select2' ? '--select2' : variant === 'v3' ? '--v3' : ''}`
                  ],
                  classes.selectContainer,
                  isInvalid && styles['pulse-select__container--isInvalid'],
                  isMenuOpen && styles['pulse-select__container--focused'],
                  isDisabled && styles['pulse-select__container--disabled'],
                )}
                onClick={openMenu}
              >
                {((!Boolean(selectedValue.length) && !isMenuOpen) || !renderSelectedValues) && (
                  <div className={styles['pulse-select__placeholder']}>
                    {t(restProps.placeholder as string) || t('Search...')}
                  </div>
                )}
                <div ref={pulseSelectValueContainerRef} className={styles['pulse-select__valueContainer']}>
                  {!Boolean(selectedValue.length) && isMenuOpen && renderSelectedValues && (
                    <div className={clsx([styles['pulse-select__placeholder'], classes.placeholder])}>
                      No option selected
                    </div>
                  )}
                  {Boolean(selectedValue.length) && renderSelectedValues && (
                    <PulseWithMore
                      classes={{
                        hidden: {
                          root: clsx(styles['pulse-select-multi__withMoreHiddenRoot'], classes?.withMore?.hidden?.root),
                          content: clsx(
                            styles['pulse-select-multi__withMoreHiddenContent'],
                            classes?.withMore?.hidden?.content,
                          ),
                        },
                        more: {
                          root: clsx(styles['pulse-select-multi__withMore'], classes?.withMore?.more?.root),
                          label: {
                            root: clsx(styles['pulse-select-multi__withMoreLabelRoot'], classes?.withMore?.more?.label),
                            active: clsx(
                              styles['pulse-select-multi__withMoreLabel--active'],
                              classes?.withMore?.more?.label,
                            ),
                          },
                        },
                        root: clsx([styles['pulse-select-multi__withMoreRoot']], classes?.withMore?.root),
                      }}
                      childrenKey="label"
                      maxWidth={inheritedMaxWidth ? inheritedMaxWidth : maxWidth}
                      MoreLabelProps={{
                        innerRef: moreLabelRef,
                      }}
                      HiddenChildProps={{
                        PulseLabelProps: {
                          classes: {
                            root: styles['pulse-select-multi__pulseLabelRoot'],
                          },
                          isShowFull: true,
                          TippyProps: {
                            disabled: false,
                            appendTo: document.querySelector('body'),
                          },
                        },
                      }}
                      ref={withMoreEl}
                      hiddenTippyRef={hiddenTippyRef}
                      TippyPropsHidden={{
                        arrow: false,
                        popperOptions: {
                          strategy: insideModal ? 'fixed' : 'absolute',
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 4],
                              },
                            },
                          ],
                        },
                      }}
                      designVariant={variant === 'v3' ? 'v3' : 'v2'}
                    >
                      {ValueComponent}
                    </PulseWithMore>
                  )}
                </div>
                <div ref={pulseSelectIndicatorContainerRef} className={styles['pulse-select__indicators']}>
                  {Boolean(defaultOptions) && isLoading && !isMenuOpen && (
                    <PCSSpinner
                      variant={PCSSpinnerVariants.circularSpinner}
                      classes={{
                        root: [styles['pulse-select__indicator']],
                      }}
                    />
                  )}
                  {Boolean(selectedValue.length) && restProps.isClearable !== false && !isDisabled && (
                    <PulseSelectClearIndicator
                      ref={pulseClearIndicatorRef}
                      classes={{
                        root: clsx(
                          variant === 'select2' && styles['pulse-select__indicator--select2'],
                          variant === 'v3' && styles['pulse-select__indicator--v3'],
                        ),
                      }}
                      clearValue={handleValueChange}
                    />
                  )}
                  <PulseSelectDropdownIndicator
                    ref={dropdownIndicatorRef}
                    isMenuOpen={isMenuOpen}
                    onClick={toggleMenu}
                  />
                </div>
              </div>
            )}
          </PulseMenu>
          {isInvalid && error && <PulseFieldError error={error} />}
        </div>
      </ClickAwayListener>
    </ScrollContext.Provider>
  );
};

export default PulseSelectBase;
