import React, { FC, ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import {
  PulseSelectPropTypes,
  PulseSelectBasePropTypes,
  PulseSelectDataStructureTypes,
  PulseSelectOptionType,
} from '../base/pulse-select-base-types';
import { ValueType, OptionProps } from 'react-select';
import PulseClientOption from './components/pulse-client-option';
import isFunction from 'lodash/isFunction';

const DATA_STRUCTURE: PulseSelectDataStructureTypes = {
  dataKey: 'data',
  label: 'company',
  lastPage: 'last_page',
  type: 'clients',
  value: 'client_id',
};
const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = 'Filter by client name';
const DEFAULT_CLIENT_ENDPOINT: PulseSelectBasePropTypes['url'] = '/v2/ajax/address-book/clients';

export const PulseClientsSelect = (props: PulseSelectPropTypes): ReactElement => {
  const {
    value = [],
    isMulti = false,
    labelName = 'Clients',
    placeholder = DEFAULT_PLACEHOLDER,
    requestFilterKey = 'officeid',
    url = DEFAULT_CLIENT_ENDPOINT,
    valueChangeHandler,
  } = props;
  const handleValueChange = (value: ValueType<PulseSelectOptionType>): void => {
    const returnValue = requestFilterKey
      ? {
          [requestFilterKey]: value,
        }
      : value;
    isFunction(valueChangeHandler) && valueChangeHandler(returnValue);
  };
  return (
    <PulseSelectBase
      changeHandler={handleValueChange}
      components={{
        Option: PulseClientOption as FC<OptionProps<PulseSelectOptionType>>,
      }}
      dataStructure={DATA_STRUCTURE}
      isMulti={isMulti}
      labelName={labelName}
      placeholder={placeholder}
      url={url}
      value={value}
      {...props}
    />
  );
};

export default React.memo<PulseSelectPropTypes>(PulseClientsSelect);
