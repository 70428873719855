import { SchedulerPro } from '@bryntum/schedulerpro-thin';
import { ViewPreset } from '@bryntum/scheduler-thin';
import { DateHelper } from '@bryntum/core-thin';
import { CurrentUserType } from 'components/pulse-resource-planner/components/booking-types';
import RPBookingModel from 'components/pulse-resource-planner/models/rp-booking-model';
import RPUserModel from 'components/pulse-resource-planner/models/rp-user-model';
import {
  Actions,
  completeEvent,
  formatBookingDate,
  ResourcePlannerAction,
  ResourcePlannerState,
} from 'components/pulse-resource-planner/reducers/resource-planner-reducer';
import { differenceInHours, differenceInMinutes, isSaturday, isSunday } from 'date-fns';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import set from 'date-fns/set';
import isFunction from 'lodash/isFunction';
import { bookingsApi } from 'pulse-api/bookings/bookings';
import { GetConfig } from 'types/pulse-resource';
import { PRESET_ID } from '../../components/presets';
import { REMOVE_LINK_PARAMS } from '../resource-planner';
import styles from './booking-context-menu.module.scss';

const IS_DEV = process.env.NODE_ENV === 'development';

type EventMenuItem = {
  text: string;
  icon: string;
  cls: string;
  style?: string;
  onItem: (params: { eventRecord: RPBookingModel; resourceRecord: RPUserModel }) => void;
};

type BookingContextMenuType = {
  /** Disable Bryntum defaults */
  copyEvent: false;
  cutEvent: false;
  editEvent: false;
  deleteEvent: false;
  unassignEvent: false;
  splitEvent: false;
  /** End of Disable Bryntum defaults */
  editBooking: EventMenuItem;
  deleteBooking: EventMenuItem;
  splitBooking: EventMenuItem;
  // moveBooking: EventMenuItem;
  copyBooking: EventMenuItem;
  // reAssignBooking: EventMenuItem;
  completeBooking: EventMenuItem;
};

export const BookingContextMenu = (
  dispatch: React.Dispatch<ResourcePlannerAction>,
  state: ResourcePlannerState,
  currentUser: CurrentUserType,
  schedulerInstance?: SchedulerPro,
): BookingContextMenuType => {
  const { onCompleteEventSuccess, onCompleteEventFail } = state;

  const editBooking: EventMenuItem['onItem'] = ({ eventRecord, resourceRecord }) => {
    eventRecord &&
      resourceRecord &&
      dispatch({
        type: Actions.editBooking,
        payload: {
          editBooking: eventRecord,
          eventRecord,
          selectedUser: resourceRecord,
        },
      });
  };

  const deleteBooking: EventMenuItem['onItem'] = ({ eventRecord }) => {
    eventRecord &&
      dispatch({
        type: Actions.showDeleteBookingModal,
        payload: {
          eventRecord,
        },
      });
  };

  /**
   * In order to create split booking, we should update the current one and add new one
   * */
  const splitBooking: EventMenuItem['onItem'] = async ({ eventRecord }) => {
    const startDate = eventRecord.startDate;
    const endDate = eventRecord.endDate;
    const duration = differenceInCalendarDays(endDate as Date, startDate as Date);
    const eventDuration = Math.ceil(eventRecord.duration);
    const startHours = getHours(startDate as Date);
    const startMinutes = getMinutes(startDate as Date);
    const endHours = getHours(endDate as Date);
    const endMinutes = getMinutes(endDate as Date);
    const newRecord = eventRecord.split(0.5) as RPBookingModel;
    const newStartDate = newRecord.startDate;
    const newEndDate = newRecord.endDate;
    const setStarHours = { hours: startHours, minutes: startMinutes };
    const setEndHours = { hours: endHours, minutes: endMinutes };

    if (newRecord) {
      // handle hours time span
      if ((schedulerInstance?.viewPreset as ViewPreset)?.id === PRESET_ID.pulseDayAndHourly && eventDuration === 1) {
        const getDurationHours = differenceInHours(endDate as Date, startDate as Date);
        await eventRecord.setEndDate(
          set(newStartDate as Date, {
            hours: getHours(newStartDate as Date),
            minutes: getMinutes(newStartDate as Date),
          }),
        );
        if (getDurationHours % 2 === 1) {
          await newRecord.setStartDate(
            set(newStartDate as Date, {
              hours: getHours(newStartDate as Date),
              minutes: getMinutes(newStartDate as Date),
            }),
          );
        }

        const getHoursPerDayNewRecord = Math.ceil(
          differenceInMinutes(newRecord.endDate as Date, newRecord.startDate as Date) / 60,
        );
        const getHoursPerDayRecord = Math.ceil(
          differenceInMinutes(eventRecord.endDate as Date, eventRecord.startDate as Date) / 60,
        );

        newRecord.hoursPerDay = getHoursPerDayNewRecord.toString();
        newRecord.totalHours = getHoursPerDayNewRecord.toString();
        eventRecord.hoursPerDay = getHoursPerDayRecord.toString();
        eventRecord.totalHours = getHoursPerDayRecord.toString();
      } else {
        if (eventDuration === 1) {
          await eventRecord.setEndDate(DateHelper.add(startDate, eventRecord.duration, 'd'));
        } else if (eventDuration === 2) {
          await newRecord.setEndDate(set(newEndDate as Date, setEndHours), false);
          await newRecord.setStartDate(
            DateHelper.add(set(newStartDate as Date, setStarHours), duration % 2 === 0 ? 1 : 0, 'd'),
            false,
          );
          await eventRecord.setEndDate(DateHelper.add(set(newRecord.startDate as Date, setEndHours), -1, 'd'));
        } else if (eventDuration % 2 === 0 && eventDuration !== 2) {
          let calculateStartDays = eventDuration / 2 - 1;
          let calculateStartDaysNewRecord = eventDuration / 2;
          const newStartDateFirstRecord = DateHelper.add(set(startDate as Date, setEndHours), calculateStartDays, 'd');

          /* if the end date is on Saturday, move up 3 days, if Sunday will move up 2 days,and also the start date of new record move to the same for 3 and 4 days, 
           these works will avoid non working time */
          if (isSunday(newStartDateFirstRecord)) {
            calculateStartDays = 2;
            calculateStartDaysNewRecord = 3;
          }
          if (isSaturday(newStartDateFirstRecord)) {
            calculateStartDays = 3;
            calculateStartDaysNewRecord = 4;
          }

          await eventRecord.setEndDate(
            DateHelper.add(set(startDate as Date, setEndHours), calculateStartDays, 'd'),
            false,
          );
          ``;
          await eventRecord.setStartDate(set(startDate as Date, setStarHours), false);

          await newRecord.setStartDate(
            DateHelper.add(set(startDate as Date, setStarHours), calculateStartDaysNewRecord, 'd'),
            false,
          );
          await newRecord.setEndDate(set(endDate as Date, setEndHours), false);
        } else if (eventDuration % 2 === 1 && eventDuration !== 1) {
          await eventRecord.setStartDate(set(startDate as Date, setStarHours), false);

          const firstEvent = eventRecord;
          const firstEventHours = {
            hours: getHours(firstEvent.endDate as Date),
            minutes: getMinutes(firstEvent.endDate as Date),
          };

          await newRecord.setStartDate(DateHelper.add(set(firstEvent.endDate as Date, firstEventHours), 1, 'd'), false);
          await newRecord.setEndDate(set(endDate as Date, setEndHours), false);
        }
      }

      const {
        bookingStatus,
        bookedUser,
        description,
        hoursPerDay,
        linkedTasks,
        job,
        priority,
        requestedBy,
        timesheetActivity,
      } = newRecord;

      const newBooking: Record<string, any> = {
        activity: timesheetActivity?.value,
        bookedUser: bookedUser.value,
        description: description,
        escalate: priority,
        hoursPerDay: hoursPerDay,
        linkBooking: linkedTasks?.map(task => task.value),
        project: job?.value,
        requestedBy: requestedBy?.map(user => user.value),
        status: bookingStatus.value,
      };
      if ((schedulerInstance?.viewPreset as ViewPreset)?.id === PRESET_ID.pulseDayAndHourly) {
        const getTotalHours = differenceInMinutes(newRecord.endDate as Date, newRecord.startDate as Date) / 60;
        const getHoursPerDay = getTotalHours;
        newBooking.totalHours = getTotalHours;
        newBooking.hoursPerDay = getHoursPerDay;
      }
      const { startDate: originalStartDate, endDate: originalEndDate } = eventRecord;

      const parsedNewStartDate = formatBookingDate(
        eventDuration === 1 ? (newRecord.startDate as Date) : set(newRecord.startDate as Date, setStarHours),
        'start',
      );
      const parsedNewEndDate = formatBookingDate(
        eventDuration === 1 ? (newRecord.endDate as Date) : set(newRecord.endDate as Date, setEndHours),
        'end',
      );
      const parsedStartDate = formatBookingDate(
        eventDuration === 1 ? (originalStartDate as Date) : set(originalStartDate as Date, setStarHours),
        'start',
      );
      const parsedEndDate = formatBookingDate(
        eventDuration === 1 ? (originalEndDate as Date) : set(originalEndDate as Date, setEndHours),
        'end',
      );
      bookingsApi
        .post(
          {
            data: {
              type: 'bookings',
              attributes: {
                ...newBooking,
                endDate: parsedNewEndDate,
                startDate: parsedNewStartDate,
              },
            },
          },
          {
            params: { ...REMOVE_LINK_PARAMS },
            options: {
              serialiseParams: true,
            },
          } as GetConfig,
        )
        .then(
          async ({
            data: {
              data: { attributes: bookingAttributes },
            },
          }) => {
            const { bookingId, hoursPerDay, totalHours } = bookingAttributes;
            const updatedBy = currentUser;
            newRecord.set({
              id: bookingId,
              bookingId,
              startDate: parsedNewStartDate,
              endDate: parsedNewEndDate,
              updatedBy: updatedBy,
              hoursPerDay,
              totalHours,
            });
          },
        )
        .catch(error => {
          window.utilities?.notification?.warning('Split booking error');
          IS_DEV && console.log(error);
        });
      if (!eventRecord.bookingId) {
        return;
      }
      const updatePayload: Record<string, any> = {
        startDate: parsedStartDate,
        endDate: parsedEndDate,
      };
      if ((schedulerInstance?.viewPreset as ViewPreset)?.id === PRESET_ID.pulseDayAndHourly) {
        const getTotalHours = differenceInMinutes(eventRecord.endDate as Date, eventRecord.startDate as Date) / 60;
        const getHoursPerDay = getTotalHours;
        updatePayload.totalHours = getTotalHours;
        updatePayload.hoursPerDay = getHoursPerDay;
      }
      bookingsApi
        .patch(
          {
            data: {
              type: 'bookings',
              id: eventRecord.bookingId?.toString(),
              attributes: {
                ...updatePayload,
              },
            },
          },
          {
            params: { ...REMOVE_LINK_PARAMS },
            options: {
              serialiseParams: true,
            },
          } as GetConfig,
        )
        .then(
          ({
            data: {
              data: { attributes: bookingAttributes },
            },
          }) => {
            const { updatedAt, hoursPerDay, totalHours } = bookingAttributes;
            eventRecord.setAsync(
              {
                startDate: parsedStartDate,
                updatedAt,
                hoursPerDay,
                updatedBy: currentUser,
                endDate: parsedEndDate,
                totalHours,
              },
              null,
            );
          },
        )
        .catch(error => {
          window.utilities?.notification?.warning('Split booking error');
          IS_DEV && console.log(error);
        });
    }
  };

  // const moveBooking: EventMenuItem['onItem'] = ({ eventRecord }) => {
  //   console.log(eventRecord);
  // };

  const copyBooking: EventMenuItem['onItem'] = ({ eventRecord }) => {
    eventRecord &&
      dispatch({
        type: Actions.newBooking,
        payload: {
          editBooking: eventRecord,
        },
      });
  };

  // const reAssignBooking: EventMenuItem['onItem'] = ({ eventRecord }) => {
  //   console.log(eventRecord);
  // };

  const completeBooking: EventMenuItem['onItem'] = ({ eventRecord }) => {
    const completeStatus = { label: 'Work completed', value: 'workCompleted' };
    eventRecord &&
      completeEvent(eventRecord, completeStatus)
        .then(() => {
          isFunction(onCompleteEventSuccess) && onCompleteEventSuccess();
        })
        .catch(err => {
          isFunction(onCompleteEventFail) && onCompleteEventFail(err);
        });
  };

  return {
    copyEvent: false,
    cutEvent: false,
    editEvent: false,
    deleteEvent: false,
    unassignEvent: false,
    splitEvent: false,
    editBooking: {
      text: 'Edit Booking',
      icon: 'context-item__icon fal fa-pencil-alt',
      cls: styles['context-menu__item'],
      onItem: editBooking,
    },
    deleteBooking: {
      text: 'Delete Booking',
      icon: 'context-item__icon fal fa-trash-alt',
      cls: styles['context-menu__item'],
      onItem: deleteBooking,
    },
    splitBooking: {
      text: 'Split Booking',
      icon: 'context-item__icon fal fa-cut',
      cls: styles['context-menu__item'],
      onItem: splitBooking,
    },
    // moveBooking: {
    //   text: 'Bulk Move Bookings',
    //   icon: 'context-item__icon fal fa-arrow-to-right',
    //   cls: styles['context-menu__item'],
    //   onItem: moveBooking,
    // },
    copyBooking: {
      text: 'Copy Booking',
      icon: 'context-item__icon fal fa-copy',
      cls: styles['context-menu__item'],
      onItem: copyBooking,
    },
    // reAssignBooking: {
    //   text: 'Bulk Reassign Bookings',
    //   icon: 'context-item__icon fal fa-user-friends',
    //   cls: styles['context-menu__item'],
    //   onItem: reAssignBooking,
    // },
    completeBooking: {
      text: `Complete`,
      icon: `context-item__icon fal fa-check-circle`,
      cls: styles['context-menu__item'],
      onItem: completeBooking,
    },
  };
};
