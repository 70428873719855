import React from 'react';
import { MultiValueProps } from 'react-select';
import styles from '../pulse-tag.module.scss';

import PulseLabel from '../../pulse-label/pulse-label';
import { LabelSizes } from 'pulse-commons/types';

const PulseTagMultiValue = (props: MultiValueProps<any>): React.ReactElement => {
  const {
    data: { label },
    removeProps,
  } = props;

  return (
    <PulseLabel
      classes={{
        root: [styles['pulse-tag__tag'], styles['pulse-tag__tag--multi-value']],
      }}
      label={label}
      actionButton
      actionButtonProps={{
        classes: {
          pulseIcon: {
            icon: 'fal fa-times',
          },
        },
        ...removeProps,
      }}
      TippyProps={{
        popperOptions: {
          strategy: 'fixed', // to show the tooltip over valueContainer
        },
      }}
      size={LabelSizes.sm}
      icon
      PulseIconProps={{
        classes: {
          root: styles['pulse-tag__icon'],
          icon: 'fal fa-tag',
        },
      }}
    />
  );
};

export default PulseTagMultiValue;
