import React, { ReactElement, Suspense } from 'react';
import { PulseCommentProps } from './pulse-comment-types';
import PulseAvatar from 'components/pulse-avatar/pulse-avatar';
import { AvatarSizes, ButtonSizes, Colors, IconSizes } from 'pulse-commons/types';
import PulseThumbnail from '../pulse-thumbnail/pulse-thumbnail';
import styles from './pulse-comment.module.scss';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import clsx from 'clsx';
import PulseMenu from 'components/pulse-menu/pulse-menu';
import { getAvatarUrl } from 'pulse-commons/helpers';

const PulseEditor = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pulse-comment-pulse-editor" */
      /* webpackMode: "eager" */
      'components/pulse-editor/pulse-editor'
    ),
);
const PULSE_LOCAL_URL = process.env.PULSE_LOCAL_URL || '';

const PulseCommentSkeleton = () => {
  return (
    <div data-testid="pulse-comment__root" className={styles['pulse-comment__root--skeleton']}>
      <div className={styles['pulse-comment__avatar']}></div>
      <div className={styles['pulse-comment__comment-content']}>
        <div className={styles['pulse-comment__comment-content--main']}>
          <span>
            <strong className={styles['pulse-comment__author']}> </strong>
            <span className={styles['pulse-comment__date']}></span>
          </span>
          <span className={styles['pulse-comment__notes']}>
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
    </div>
  );
};

export const PulseComment = React.memo(
  (props: PulseCommentProps): ReactElement => {
    const {
      classes,
      allowReply = true,
      data,
      onOpenMailHistory,
      onReplyClick,
      onDelete,
      PulseAvatarProps,
      PulseEditorProps,
      PulseMenuProps,
      PulseThumbnailProps,
      secondaryActionComponent,
      secondaryEmailActionProps,
      secondaryDeleteActionProps,
      showReply = false,
      skeleton = false,
      type = 'parent',
      childCommentProps,
    } = props;

    const { classes: PulseMenuClasses, ...restPulseMenuProps } = PulseMenuProps || {};
    const { classes: actionEmailClasses, ...restEmailActionProps } = secondaryEmailActionProps || {};
    const { classes: actionDeleteClasses, ...restDeleteActionProps } = secondaryDeleteActionProps || {};

    if (!data && !skeleton) {
      return <div data-testid="pulse-comment__no-data">There was an error loading this comment.</div>;
    }

    if (skeleton) {
      return <PulseCommentSkeleton />;
    }

    const handleReplyClick = () => {
      allowReply && onReplyClick && onReplyClick(data.id);
    };

    const handleDelete = () => {
      onDelete && onDelete(data.id);
    };

    const handleOpenMailHistory = () => {
      onOpenMailHistory && onOpenMailHistory(data.id);
    };

    const imageUrl = getAvatarUrl(data.author ? data.author.avatarUrl : data.avatarUrl);

    return (
      data && (
        <div
          data-testid="pulse-comment__root"
          key={data.id}
          className={clsx(styles['pulse-comment__root'], classes?.root)}
        >
          <PulseAvatar
            classes={{
              root: styles['pulse-comment__avatar'],
            }}
            imageUrl={imageUrl}
            userName={data.author ? data.author.name : data.userName}
            size={AvatarSizes.lg}
            TippyProps={{
              disabled: true,
            }}
            {...PulseAvatarProps}
          />
          <div className={clsx(styles['pulse-comment__comment-content'], classes?.commentContentRoot)}>
            <div className={clsx(styles['pulse-comment__comment-content--main'], classes?.commentContentContainer)}>
              <span className={clsx(styles['pulse-comment__content--header'], classes?.commentContentHeader)}>
                <strong className={styles['pulse-comment__author']}>
                  {data.author ? data.author.name : data.userName}{' '}
                </strong>
                <span className={styles['pulse-comment__date']}>{data.date}</span>
              </span>
              <span className={styles['pulse-comment__notes']} dangerouslySetInnerHTML={{ __html: data.notes }} />
              {data.sticky_notes_number && (
                <a
                  className={styles['pulse-comment__sticky-notes']}
                  target="_blank"
                  rel="noreferrer"
                  href={`${PULSE_LOCAL_URL}/stickynotes_v6.php?assetid=${data.assetId}&initial_page=${data.sticky_notes_page_number}&s=`}
                >
                  View Sticky Notes <i className="fal fa-angle-right" />
                </a>
              )}
              {!!data.assets?.length && (
                <div className={styles['pulse-comment__assets']}>
                  {data.assets.map((asset: any) => {
                    return (
                      <PulseMenu
                        classes={{
                          childrenRoot: clsx(styles['pulse-comment__menuChildrenRoot'], PulseMenuClasses?.childrenRoot),
                          menuCtn: clsx(styles['pulse-comment__menuCtn'], PulseMenuClasses?.menuCtn),
                        }}
                        key={asset.id}
                        TippyProps={{
                          disabled: true,
                        }}
                        {...restPulseMenuProps}
                      >
                        <PulseThumbnail
                          fileType="image/*"
                          fileDetails={asset}
                          src={`${PULSE_LOCAL_URL}/open.php?format=preview&assetid=${asset.id}`}
                          PulseIconProps={{
                            classes: {
                              root: styles['pulse-comment__thumbnailIcon'],
                            },
                          }}
                          {...PulseThumbnailProps}
                        />
                      </PulseMenu>
                    );
                  })}
                </div>
              )}
              <div className={clsx(styles['pulse-comment__secondaryAction'], classes?.secondaryAction)}>
                {secondaryActionComponent}
                {!secondaryActionComponent && (
                  <>
                    <PulseIconButton
                      classes={{
                        root: clsx(styles['pulse-comment__secondaryActionBtn'], actionDeleteClasses?.root),
                        pulseIcon: {
                          root: clsx(
                            styles['pulse-comment__secondaryActionBtnIconRoot'],
                            actionDeleteClasses?.pulseIcon?.root,
                          ),
                          icon: actionDeleteClasses?.pulseIcon?.icon ?? 'fal fa-trash-alt',
                        },
                        label: clsx(styles['pulse-comment__secondaryActionBtnLabel'], actionDeleteClasses?.label),
                      }}
                      {...restDeleteActionProps}
                      tooltip="Delete comment"
                      size={IconSizes.sm}
                      handleClick={handleDelete}
                    />
                    <PulseIconButton
                      classes={{
                        root: clsx(styles['pulse-comment__secondaryActionBtn'], actionEmailClasses?.root),
                        pulseIcon: {
                          root: clsx(
                            styles['pulse-comment__secondaryActionBtnIconRoot'],
                            actionEmailClasses?.pulseIcon?.root,
                          ),
                          icon: actionEmailClasses?.pulseIcon?.icon ?? 'fal fa-envelope',
                        },
                        label: clsx(styles['pulse-comment__secondaryActionBtnLabel'], actionEmailClasses?.label),
                      }}
                      {...restEmailActionProps}
                      size={IconSizes.sm}
                      tooltip="See email history"
                      handleClick={handleOpenMailHistory}
                    />
                  </>
                )}
              </div>
              {type === 'parent' && allowReply && (
                <>
                  {!showReply && (
                    <PulseButtonBase
                      classes={[clsx(styles['pulse-comment__replyBtn'], classes?.replyBtn)]}
                      color={Colors.secondary}
                      label="Reply"
                      icon
                      iconClasses={{
                        icon: 'fal fa-comment',
                      }}
                      onClick={handleReplyClick}
                      sizes={ButtonSizes.sm}
                    />
                  )}
                  {showReply && (
                    <div className={styles['pulse-comments__replyEditor']}>
                      <Suspense fallback={<div>Loading ...</div>}>
                        <PulseEditor
                          initSettings={{
                            max_height: 250,
                          }}
                          classes={{
                            root: styles['pulse-comments__editor'],
                          }}
                          mode="edit-only"
                          SaveButtonProps={{
                            icon: true,
                            iconClasses: {
                              icon: 'fal fa-comment',
                            },
                            label: 'Add Comment',
                          }}
                          {...PulseEditorProps}
                        />
                      </Suspense>
                    </div>
                  )}
                </>
              )}
            </div>
            {data.childTaskNotes && (
              <div className={clsx(styles['pulse-comment__comment--children'], classes?.commentChildren)}>
                {data.childTaskNotes.map((childNote: any) => {
                  return (
                    <PulseComment
                      {...props}
                      key={childNote.id}
                      data={childNote}
                      PulseAvatarProps={{
                        userName: childNote.author ? childNote.author.name : childNote.userName,
                        size: AvatarSizes.md,
                      }}
                      {...childCommentProps}
                      type="child"
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )
    );
  },
);

export default PulseComment;
