import React, { ReactElement } from 'react';
import LinearPillar from './variants/linear-pillar/linear-pillar';
import CicularSpinner from './variants/circular/circular-spinner';
import { Sizes } from 'pulse-commons/types';

export interface PCSSpinnerPropsType {
  variant: PCSSpinnerVariants;
  size?: Sizes;
  classes?: {
    root?: string[];
  };
}

export enum PCSSpinnerVariants {
  linearPillar = 0,
  circularSpinner = 1,
}

const PCSSpinner = (props: PCSSpinnerPropsType): ReactElement => {
  const { variant, ...variantProps } = props;
  switch (variant) {
    case PCSSpinnerVariants.linearPillar:
      return <LinearPillar />;
    case PCSSpinnerVariants.circularSpinner:
      return <CicularSpinner {...variantProps} />;
  }
};

export default PCSSpinner;
