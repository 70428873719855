/* eslint-disable @typescript-eslint/no-unused-vars */

import clsx from 'clsx';
import PulseMenuButton from 'components/pulse-button/menu/pulse-menu-button';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { ButtonVariants } from 'pulse-commons/types';
import React, { FC } from 'react';
import styles from '../../pulse-resource-planner.module.scss';

/* eslint-enable @typescript-eslint/no-unused-vars */

type ResourcePlannerPresetProps = {
  selectedPreset: PRESET_VIEW;
  onChange: (view: PRESET_VIEW) => void;
};

export enum PRESET_VIEW {
  Hours = 4,
  Days = 3,
  Weeks = 2,
  Months = 1,
  Quarters = 0,
}

const VIEW_BY_DATA = [
  { label: 'Hours', view: PRESET_VIEW.Hours },
  { label: 'Days', view: PRESET_VIEW.Days },
  { label: 'Weeks (Default)', view: PRESET_VIEW.Weeks },
  { label: 'Months', view: PRESET_VIEW.Months },
  { label: 'Quarters', view: PRESET_VIEW.Quarters },
];

const ResourcePlannerPreset: FC<ResourcePlannerPresetProps> = (props: ResourcePlannerPresetProps) => {
  const { selectedPreset, onChange } = props;

  const handleClick = (view: PRESET_VIEW) => (): void => {
    isFunction(onChange) && onChange(view);
  };

  const menuButtonData = VIEW_BY_DATA.map(viewData => {
    return {
      label: viewData.label,
      classes: [clsx(styles['rp-menu-item'], selectedPreset === viewData.view && styles['active'])],
      icon: false,
      onClick: handleClick(viewData.view),
    };
  });

  const currentView = VIEW_BY_DATA.filter(view => view.view === selectedPreset);

  return (
    <PulseMenuButton
      classes={{
        menuCtn: styles['rp-preset-menu-ctn'],
      }}
      menuButtonData={menuButtonData}
      PulseButtonProps={{
        classes: [styles['rp-menu-btn']],
        iconClasses: {
          icon: `${styles['rp-menu-btn-icon']} fal fa-chevron-down`,
        },
        variant: ButtonVariants.contained,
        label: `${currentView[0].label}`,
        onClick: noop,
      }}
      TippyProps={{
        arrow: false,
        offset: [0, 0],
        zIndex: 1000,
        trigger: 'mouseenter click',
      }}
    />
  );
};

export default React.memo(ResourcePlannerPreset);
