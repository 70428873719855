import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import { PulseLeftNavProps } from './pulse-left-nav-types';
import styles from './pulse-left-nav.module.scss';
import PulseList from 'components/pulse-list/pulse-list';

export const PulseLeftNav = (props: PulseLeftNavProps): ReactElement => {
  const { children, classes, title, designVariant } = props;
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div data-testid="pulse-left-nav" className={styles['pulse-left-nav']}>
      {title && (
        <div
          className={styles['pulseLeftNavTitle']}
          onClick={() => {
            title ? setIsOpen(!isOpen) : null;
          }}
        >
          <span>{title ? title : 'Filter Presets'}</span>
          {title && <i className={isOpen ? 'fal fa-chevron-up' : 'fal fa-chevron-down'}></i>}
        </div>
      )}
      {isOpen && (
        <PulseList
          classes={{
            ...classes,
            root: clsx(styles.pulseLeftNavRoot, classes?.root, styles[designVariant || 'v2']),
          }}
        >
          {children}
        </PulseList>
      )}
    </div>
  );
};

export default PulseLeftNav;
