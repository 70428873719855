import React, { ReactElement } from 'react';
import { PCSSpinnerPropsType } from '../../pcs-spinner';
import styles from './circular-spinner.module.scss';
import { Sizes } from 'pulse-commons/types';
import clsx from 'clsx';

type CircularSpinnerPropsType = {
  size?: Sizes;
  classes?: PCSSpinnerPropsType['classes'];
};

const CicularSpinner = (props: CircularSpinnerPropsType): ReactElement => {
  const { size = Sizes.sm, classes = { root: [] } } = props;
  return (
    <div
      data-testid="pcs-circular-spinner"
      className={clsx(styles[`spinner--${size}`], styles['circular-spinner'], classes.root)}
    >
      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" data-svg="spinner">
        <circle fill="none" cx="15" cy="15" r="14"></circle>
      </svg>
    </div>
  );
};

export default CicularSpinner;
