import clsx from 'clsx';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { useResourcePlannerState } from 'components/pulse-resource-planner/context/resource-planner-context';
import {
  EditBookingType,
  ResourcePlannerState,
} from 'components/pulse-resource-planner/reducers/resource-planner-reducer';
import { ButtonVariants } from 'pulse-commons/types';
import React, { FC, ReactElement } from 'react';
import styles from '../../pulse-resource-planner.module.scss';
import PulseMenuButton from 'components/pulse-button/menu/pulse-menu-button';
import pulseMenuButtonStyles from 'components/pulse-button/menu/pulse-menu-button.module.scss';
import noop from 'lodash/noop';

type CreateMenuButtonsProps = {
  onClick: (newBookingType: EditBookingType) => void;
};

const CreateMenuButtons: FC<CreateMenuButtonsProps> = (props): ReactElement => {
  const { onClick } = props;
  const rpState: ResourcePlannerState = useResourcePlannerState();
  const { canEditBooking, canViewBooking, inAgressoOffice } = rpState.bookingPermissions;

  const canAddNCBooking = canEditBooking && inAgressoOffice;
  const canRequestBooking = !canEditBooking && canViewBooking;

  /**
   * Please see permissions for Request Booking [here]{@link https://beta.extranet-system.com/v2/passport/1075/tasklist/46295}
   */

  const handleClick = (newBookingType: EditBookingType = EditBookingType.STANDARD) => () => {
    onClick(newBookingType);
  };

  return (
    <PulseMenuButton
      classes={{
        menuCtn: styles['rp-create-menu-ctn'],
      }}
      TippyProps={{
        arrow: false,
        offset: [0, 0],
        zIndex: 1000,
        trigger: 'mouseenter click',
      }}
      PulseButtonProps={{
        classes: [styles['rp-menu-btn']],
        variant: ButtonVariants.contained,
        iconClasses: {
          icon: `${styles['rp-menu-btn-icon']} fal fa-plus`,
        },
        label: 'Create Bookings',
        onClick: noop,
      }}
    >
      {canEditBooking && (
        <PulseButtonBase
          key={'Add booking'}
          label="Add booking"
          icon={true}
          classes={[styles['rp-menu-item'], pulseMenuButtonStyles['pulse-menu-button__menu-item']]}
          iconClasses={{
            icon: clsx('fal fa-calendar-plus', pulseMenuButtonStyles['pulse-menu-button__icon']),
            root: styles['rp-menu-item-icon'],
          }}
          onClick={handleClick(EditBookingType.STANDARD)}
          variant={ButtonVariants.text}
        />
      )}
      {canRequestBooking && (
        <PulseButtonBase
          key={'Request booking'}
          label="Request booking"
          icon={true}
          classes={[styles['rp-menu-item'], pulseMenuButtonStyles['pulse-menu-button__menu-item']]}
          iconClasses={{
            icon: clsx('fal fa-user-clock', pulseMenuButtonStyles['pulse-menu-button__icon']),
            root: styles['rp-menu-item-icon'],
          }}
          onClick={handleClick(EditBookingType.REQUEST)}
          variant={ButtonVariants.text}
        />
      )}
      {canAddNCBooking && (
        <PulseButtonBase
          key={'Add non charge time'}
          label="Add non charge time"
          icon={true}
          classes={[styles['rp-menu-item'], pulseMenuButtonStyles['pulse-menu-button__menu-item']]}
          iconClasses={{
            icon: clsx('fal fa-calendar-exclamation', pulseMenuButtonStyles['pulse-menu-button__icon']),
            root: styles['rp-menu-item-icon'],
          }}
          onClick={handleClick(EditBookingType.NC)}
          variant={ButtonVariants.text}
        />
      )}
    </PulseMenuButton>
  );
};

export default React.memo(CreateMenuButtons);
