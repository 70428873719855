import React, { ReactElement } from 'react';
import { PulseModalBaseProps } from './pulse-modal-base-types';
import styles from './pulse-modal-base.module.scss';
import PulseModalHeader from './components/pulse-modal-header/pulse-modal-header';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PulseModalFooter from './components/pulse-modal-footer/pulse-modal-footer';
import clsx from 'clsx';

const PulseModalBase: React.FunctionComponent<PulseModalBaseProps> = (props: PulseModalBaseProps): ReactElement => {
  const {
    allowClose = true,
    children,
    classes = {
      root: [],
    },
    footer = true,
    footerCancelText = 'Cancel',
    footerComponent,
    footerConfirmDisabled,
    footerConfirmText = 'Confirm',
    footerConfirmIconClasses,
    header = true,
    headerComponent,
    headerIconClasses,
    headerPrimaryText,
    headerSecondaryText,
    onConfirmClick,
    onModalClose,
    open = false,
    footerCancelComponent,
    footerConfirmComponent,
    DialogProps,
    PulseModalHeaderProps,
    PulseModalFooterProps,
    variant = 'new',
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { classes: pulseModalHeaderClasses, ...restPulseModalHeaderProps } = PulseModalHeaderProps || {};

  return (
    <Dialog
      {...DialogProps}
      classes={{
        ...classes?.dialog,
        root: clsx(styles['modal-base__root'], classes?.dialog?.root),
        paper: clsx(styles['modal-base__paper'], classes?.dialog?.paper),
      }}
      fullScreen={fullScreen}
      onClose={onModalClose}
      open={open}
      scroll="paper"
      style={{
        zIndex: 1000,
      }}
      BackdropProps={{
        classes: {
          ...classes?.backdrop,
        },
      }}
    >
      {header &&
        (headerComponent ? (
          headerComponent
        ) : (
          <PulseModalHeader
            allowClose={allowClose}
            classes={{
              ...pulseModalHeaderClasses,
              root: clsx(
                pulseModalHeaderClasses?.root,
                styles['modal-base__header-root'],
                variant === 'jqconfirm' && styles['modal-base__header-root--jqconfirm'],
              ),
              titleContainer: clsx(pulseModalHeaderClasses?.titleContainer, styles['modal-base__title-container']),
              titleContainerText: clsx(
                pulseModalHeaderClasses?.titleContainerText,
                styles['modal-base__title-container-text'],
              ),
            }}
            headerIconClasses={headerIconClasses}
            onCloseClick={onModalClose}
            primaryText={headerPrimaryText}
            secondaryText={headerSecondaryText}
            {...restPulseModalHeaderProps}
          />
        ))}
      <DialogContent
        classes={{
          root: clsx(styles['modal-base__content-root'], classes?.root),
        }}
      >
        <div data-testid="pulse-modal-base__children-ctn" className={styles['pulse-modal-base__children-ctn']}>
          {children}
        </div>
      </DialogContent>
      {footer &&
        (footerComponent ? (
          footerComponent
        ) : (
          <PulseModalFooter
            classes={classes?.footer}
            cancelComponent={footerCancelComponent}
            cancelText={footerCancelText}
            confirmComponent={footerConfirmComponent}
            confirmText={footerConfirmText}
            confirmDisabled={footerConfirmDisabled}
            confirmIconClasses={footerConfirmIconClasses}
            onCancelClick={onModalClose}
            onConfirmClick={onConfirmClick}
            {...PulseModalFooterProps}
          />
        ))}
    </Dialog>
  );
};

export default PulseModalBase;
