import { PulseResourcePlannerProps } from 'components/pulse-resource-planner/pulse-resource-planner-types';
import { UserPermissions } from 'pulse-types/users';
import React, { FC } from 'react';

export type BookingPermissions = {
  /**
   * Permission of user editBooking
   * @default false
   */
  canEditBooking: boolean;
  /**
   * Permission of user viewBooking
   * @default false
   */
  canViewBooking: boolean;
  inAgressoOffice: boolean;
  canManageUsers: boolean;
  isSuperUser: boolean;
};

const withBookingPermissions = (
  WrappedComponent: FC<PulseResourcePlannerProps>,
  permission: UserPermissions,
): FC<PulseResourcePlannerProps> => {
  const canViewBooking = permission?.viewBookings === 'y';
  const inAgressoOffice = permission?.inAgressoOffice;
  const canEditBooking = permission?.editBookings === 'y';
  const canManageUsers = permission?.manageUsers === 'y';
  const isSuperUser = permission?.isSuperUser === 'y';

  const ComponentWithBookingPermissions = props => (
    <WrappedComponent
      {...props}
      ResourcePlannerProviderProps={{
        ...props?.ResourcePlannerProviderProps,
        bookingPermissions: {
          canEditBooking,
          canViewBooking,
          inAgressoOffice,
          canManageUsers,
          isSuperUser,
        },
      }}
    />
  );

  ComponentWithBookingPermissions.displayName = `withBookingPermissions(${
    WrappedComponent.displayName || WrappedComponent.name || 'Components'
  })`;
  return ComponentWithBookingPermissions;
};

withBookingPermissions.displayName = 'permissions';

export default withBookingPermissions;
