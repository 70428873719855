import { AxiosResponse } from 'axios';
import { getConfig as baseGetConfig, v2Endpoint } from 'pulse-api/base';
import { GetConfig, PulseSubRequestObject } from 'types/pulse-resource';

const USER_URL = `/v2/api/users`;

const getConfig = (params: GetConfig, url: string = USER_URL): PulseSubRequestObject => baseGetConfig(params, url);

const get: (params: Partial<GetConfig>, url?: string) => Promise<AxiosResponse<any>> = (
  params,
): Promise<AxiosResponse<any>> => {
  const config = getConfig(params);
  return v2Endpoint.request(config);
};

const getById: (id: string | number, params: Partial<GetConfig>) => Promise<AxiosResponse<any> | any> = (
  id,
  params,
): Promise<AxiosResponse<any>> => {
  const url = `${USER_URL}/${id}`;
  const config = getConfig(params, url);
  return v2Endpoint.request(config).then(res => {
    if (res.status === 200 && !params.jq) {
      return res.data.data;
    }
    return res.data;
  });
};

export const usersApi = {
  get,
  getConfig,
  getById,
  USER_URL,
};
