import React, { ReactElement, CSSProperties } from 'react';
import { OptionProps } from 'react-select';
import styles from '../pulse-tag.module.scss';
import clsx from 'clsx';

export const PulseTagOption = (props: OptionProps<any>): ReactElement => {
  const { children, className, getStyles, innerProps, innerRef } = props;
  const defaultStyles: CSSProperties = getStyles('option', props);
  delete defaultStyles.display;
  return (
    <div className={clsx(className, styles['pulse-tag__option'])} style={defaultStyles} ref={innerRef} {...innerProps}>
      <span className={styles['pulse-tag__label']}>{children}</span>
    </div>
  );
};

export default PulseTagOption;
