import React, { ReactElement } from 'react';

import PulseSelectBase from '../base/pulse-select-base';
import { PulseSubActivitiesGroupTypes, PulseSubActivitiesSelectPropTypes } from './pulse-sub-activities-types';
import { CancelToken } from 'axios';
import { groupOptions } from '../utils';
import { PulseSelectArgsStructureGroupTypes } from '../base/pulse-select-base-types';

const SUB_ACTIVITY_URL = '/v2/api/agresso-ratecard-sub-activities';

const PulseSubActivitiesSelect = (props: PulseSubActivitiesSelectPropTypes): ReactElement => {
  const { extraParams, isGroup = true, ...restProps } = props;

  const handleGroupOptions = async (
    cancelToken: CancelToken,
    searchValue = '',
  ): Promise<PulseSubActivitiesGroupTypes[]> => {
    const groupOptionsData: PulseSelectArgsStructureGroupTypes = {
      cancelToken,
      searchValue,
      searchKey: 'name',
      groupTitleKey: 'department',
      optionTitleKey: 'name',
      identifierKey: 'department_id',
      url: SUB_ACTIVITY_URL,
      extraParams,
    };
    return groupOptions(groupOptionsData) as Promise<PulseSubActivitiesGroupTypes[]>;
  };

  return (
    <PulseSelectBase {...restProps} isGroup={isGroup} dataStructure={null} handleGroupOptions={handleGroupOptions} />
  );
};

export default React.memo(PulseSubActivitiesSelect);
