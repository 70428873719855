import * as yup from 'yup';
import { differenceInDays, isValid } from 'date-fns';
import { FilterStateProps } from 'components/pulse-filter/slice/types';

const errorMessage = ({ value }: Record<string, any>): string => {
  return `Date range should be in ${value?.limitDays} days`;
};

const limitDate = (key: string, isCustom = false) => (
  value: FilterStateProps['selectedFilter'],
  { parent }: FilterStateProps['selectedFilter'],
): boolean => {
  const option = parent[key];
  if (
    value &&
    isValid(new Date(value.startDate)) &&
    isValid(new Date(value.endDate)) &&
    value.limitDays &&
    (option?.value === 'custom' || isCustom)
  ) {
    const checkLimitDay = Math.abs(differenceInDays(new Date(value.startDate), new Date(value.endDate)));
    return checkLimitDay < value.limitDays;
  }
  return true;
};
const FilterValidationSchema = yup.object({
  dateRangeCustom: yup.object().test('limitDate', errorMessage, limitDate('dateRange')),
  createdDateCustom: yup.object().test('limitDate', errorMessage, limitDate('createdDate')),
  dueDateCustom: yup.object().test('limitDate', errorMessage, limitDate('dueDate')),
  validDateCustom: yup.object().test('limitDate', errorMessage, limitDate('validDate')),
  pulseRangePicker: yup.object().test('limitDate', errorMessage, limitDate('pulseRangePicker', true)),
});

export default FilterValidationSchema;

export { errorMessage, limitDate };
