import clsx from 'clsx';
import RPBookingModel from 'components/pulse-resource-planner/models/rp-booking-model';
import { getEventColorCustom } from 'components/pulse-resource-planner/utils';
import { EventRendererParams, EventTemplateParams } from '../../pulse-resource-planner-types';
import styles from '../../pulse-resource-planner.module.scss';
import { BookingEventColorMap } from '../booking-types';
export enum BookingStatus {
  Completed = 'workCompleted',
  Confirmed = 'confirmed',
  Tentative = 'tentative',
  Unapproved = 'unapproved',
}

export const EventTemplate = (params: EventTemplateParams): string | boolean => {
  const { bookingStatus, hoursPerDay, job, priority, ptoRequestId, totalHours, weekends, linkedTasks } = params;

  /**
   * For new bookings, job is not set so we default
   * job to prevent `TypeError: Cannot read property 'isNC' of undefined`
   */
  const { isNC, label } = job || {};

  const { value } = bookingStatus || {};

  let iconName = 'user';

  if (value === 'workCompleted') {
    iconName = 'check-circle';
  }

  if (priority === 'escalated') {
    iconName = 'fire';
  }

  if (isNC) {
    iconName = 'user-cog';
  }

  if (ptoRequestId) {
    iconName = 'suitcase-rolling';
  }

  const statusIcon = iconName ? `<i class="fal fa-${iconName}"></i>` : '';

  const linkedTaskIcon = !!linkedTasks?.length ? `<i class="fal fa-link"></i>` : '';

  let weekendEls = '';
  weekends?.forEach(weekend => {
    weekendEls += `<span class="weekend" style=" width: ${weekend.width}px; left: ${weekend.left}px;"></span>`;
  });

  return `<div class="${clsx(styles['rp-event-root'], !job?.value && 'rp-event-hidden')}">
  ${weekendEls}
    <div class="${styles['rp-event-content']}">
      <span class="${clsx(styles['rp-event-name'], ptoRequestId && styles['rp-event-name__text--black'])}">
        <span class="${styles['rp-inner-content']}">
          ${statusIcon}
          <span class="${styles['rp-event-project-name']}">${label}</span>
          ${linkedTaskIcon}
        </span>
        <span class="${styles['rp-event-second-row']}">
          <span class="${styles['rp-event-hours']}">[${hoursPerDay}h Daily / ${totalHours}h Total]</span>
        </span>
      </span>
    </div>
  </div>`;
};

export const EventRenderer = (params: unknown): RPBookingModel => {
  const { eventRecord, renderData } = params as EventRendererParams;

  renderData.wrapperCls = clsx(
    renderData.wrapperCls,
    'rp-event-wrapper',
    eventRecord?.locked === 'y' && styles['rp-event-locked'],
  );

  /**
   * renderData.height controls the height
   * for the booking in the grid
   */
  renderData.height = 44;

  renderData.eventColor = BookingEventColorMap.standard;

  switch (eventRecord?.bookingStatus?.value) {
    case BookingStatus.Completed:
    case BookingStatus.Confirmed:
      if (eventRecord.priority === 'escalated') {
        renderData.eventColor = BookingEventColorMap.escalated;
        renderData.wrapperCls = clsx(renderData.wrapperCls, 'rp-event-wrapper__escalated-hover');
      } else if (eventRecord.ptoRequestId) {
        renderData.eventColor = BookingEventColorMap.pto;
        renderData.style = `color: #000000, background-color: ${BookingEventColorMap.pto}`;
        renderData.wrapperCls = clsx(renderData.wrapperCls, 'rp-event-wrapper__pto-hover');
      } else if (eventRecord?.job?.isNC) {
        renderData.eventColor = BookingEventColorMap.nonCharge;
        renderData.wrapperCls = clsx(renderData.wrapperCls, 'rp-event-wrapper__non-charge-hover');
      } else {
        renderData.wrapperCls = clsx(
          renderData.wrapperCls,
          !eventRecord?.isCustomBookingColor && 'rp-event-wrapper__standard-hover',
        );
      }
      break;
    case BookingStatus.Tentative:
      renderData.style = BookingEventColorMap.standardTentative;
      if (eventRecord.priority === 'escalated') {
        renderData.style = BookingEventColorMap.escalatedTentative;
      }
      if (eventRecord?.job?.isNC) {
        renderData.style = BookingEventColorMap.nonChargeTentative;
      }
      if (eventRecord.ptoRequestId) {
        renderData.style = BookingEventColorMap.ptoTentative;
      }
      break;
    case BookingStatus.Unapproved:
      renderData.style = BookingEventColorMap.unApproved;
      break;
    default:
      renderData.eventColor = BookingEventColorMap.standard;
  }

  // custom event color
  const canUseCustomColor =
    eventRecord?.isCustomBookingColor && !(eventRecord?.job?.isNC || eventRecord?.job?.raw?.isNC);
  if (canUseCustomColor) {
    renderData.style = `background: ${getEventColorCustom(
      eventRecord.bookingCustomColor,
      eventRecord.bookingStatus?.value,
    )}`;
  }
  return eventRecord;
};
