import Grid from '@mui/material/Grid';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import PulseInput from 'components/pulse-input/pulse-input';
import { Colors, IconSizes } from 'pulse-commons/types';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { PulsePaginationProps } from './pulse-pagination-types';
import styles from './pulse-pagination.module.scss';
import { TippyProps, useSingleton } from '@tippyjs/react';
import isFunction from 'lodash/isFunction';
import { isNaN } from 'lodash';
import clsx from 'clsx';
import PulseSimpleSelect from 'components/pulse-simple-select/pulse-simple-select';
import PulseSimpleSelectOption from 'components/pulse-simple-select/components/pulse-simple-select-option/pulse-simple-select-option';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import { PulseSimpleSelectProps } from 'components/pulse-simple-select/pulse-simple-select-types';
import { InputProps } from '@mui/material';

export const PulsePagination: FC<PulsePaginationProps> = (props): ReactElement => {
  const {
    children,
    classes,
    currentPage,
    FirstPageIconButtonProps,
    lastPage,
    LastPageIconButtonProps,
    NextPageIconButtonProps,
    onPageChange,
    onPerPageChange,
    perPage,
    PrevPageIconButtonProps,
    PulseInputProps,
    PulseSimpleSelectProps,
    PulseTooltipProps,
    total = 0,
    sticky,
    placement,
    isShowPerPage = false,
  } = props;
  const [source, target] = useSingleton();

  const [selectedPage, setSelectedPage] = useState(currentPage);
  const [internalLastPage, setInternalLastPage] = useState(lastPage);

  const sharedTippyProps: Partial<TippyProps> = {
    singleton: target,
  };

  useEffect(() => {
    if (currentPage !== selectedPage) {
      setSelectedPage(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    /**
     * Check if a last page props is supplied.
     *
     * If not supplied, then calcualte the last
     * page value internally
     */
    if (!lastPage) {
      setInternalLastPage(Math.ceil(total / perPage));
    }
  }, [perPage]);

  useEffect(() => {
    if (selectedPage !== currentPage) {
      isFunction(onPageChange) && onPageChange(selectedPage);
    }
  }, [selectedPage]);

  const handleFirstPage = () => {
    setSelectedPage(1);
  };

  const handleLastPage = () => {
    internalLastPage && setSelectedPage(internalLastPage);
  };

  const handlePrevPage = () => {
    if (selectedPage === 1) {
      return;
    }
    setSelectedPage(selectedPage - 1);
  };

  const handleNextPage = () => {
    if (selectedPage === internalLastPage) {
      return;
    }
    setSelectedPage(selectedPage + 1);
  };

  const handlePageChange: InputProps['onChange'] = event => {
    const inputValue = parseInt(event.target.value);
    if (!internalLastPage) {
      return;
    }
    if (isNaN(inputValue) || inputValue > internalLastPage || !inputValue) {
      return;
    }
    setSelectedPage(inputValue);
    isFunction(onPageChange) && onPageChange(inputValue);
  };

  const handlePerPageChange: PulseSimpleSelectProps['onChange'] = e => {
    /**
     * On perPage change, we send the user back to page 1
     *
     * This will trigger the useEffect listening to selectedPage
     * and trigger onPageChange function which should fetch the
     * correct amount of paged results
     */
    handleFirstPage();
    /**
     * However, when selectedPage = currentPage = 1, the useEffect
     * will not trigger onPageChange.
     *
     * The following ensures that the onPageChange is called on page 1
     */
    selectedPage === 1 && isFunction(onPageChange) && onPageChange(selectedPage);
    isFunction(onPerPageChange) && onPerPageChange(e.target.value);
  };

  const handleInputFocus: InputProps['onFocus'] = e => {
    e.target.select();
  };

  return (
    <>
      <PulseTooltip
        {...PulseTooltipProps}
        TippyProps={{
          className: styles.paginationTippy,
          singleton: source,
          placement: 'top',
          ...PulseTooltipProps?.TippyProps,
        }}
      />
      <Grid
        data-testid="pulse-pagination-root"
        classes={{
          root: clsx(classes?.root, placement === 'top' && styles.pulsePaginationTop),
        }}
        container
      >
        {children}
        <Grid
          container
          item
          classes={{
            root: clsx(
              styles.paginationWrapperRoot,
              classes?.paginationWrapperRoot,
              sticky && styles.pulsePaginationRootSticky,
            ),
          }}
        >
          <Grid container item spacing={'8px'} className={styles.paginationSection}>
            <Grid item>
              <PulseIconButton
                disabled={selectedPage === 1}
                classes={{ root: styles.paginationBtn, pulseIcon: { icon: 'fal fa-chevron-double-left' } }}
                color={Colors.default}
                onClick={handleFirstPage}
                TippyProps={{
                  content: 'First Page',
                  ...sharedTippyProps,
                }}
                size={IconSizes.sm}
                {...FirstPageIconButtonProps}
              />
            </Grid>
            <Grid item>
              <PulseIconButton
                disabled={selectedPage === 1}
                classes={{ root: styles.paginationBtn, pulseIcon: { icon: 'fal fa-chevron-left' } }}
                color={Colors.default}
                onClick={handlePrevPage}
                TippyProps={{
                  content: 'Previous Page',
                  ...sharedTippyProps,
                }}
                size={IconSizes.sm}
                {...PrevPageIconButtonProps}
              />
            </Grid>
            <Grid item className={styles.paginationLabel}>
              Page
            </Grid>
            <Grid item>
              <PulseInput
                InputBaseProps={{
                  classes: { root: styles.paginationInputRoot, input: styles.paginationInput },
                  onChange: handlePageChange,
                  onFocus: handleInputFocus,
                  type: 'number',
                  value: selectedPage,
                }}
                {...PulseInputProps}
              />
            </Grid>
            <Grid item className={styles.paginationLabel}>
              of {internalLastPage}
            </Grid>
            <Grid item>
              <PulseIconButton
                disabled={selectedPage === internalLastPage}
                classes={{ root: styles.paginationBtn, pulseIcon: { icon: 'fal fa-chevron-right' } }}
                color={Colors.default}
                onClick={handleNextPage}
                TippyProps={{
                  content: 'Next Page',
                  ...sharedTippyProps,
                }}
                size={IconSizes.sm}
                {...NextPageIconButtonProps}
              />
            </Grid>
            <Grid item>
              <PulseIconButton
                disabled={selectedPage === internalLastPage}
                classes={{ root: styles.paginationBtn, pulseIcon: { icon: 'fal fa-chevron-double-right' } }}
                color={Colors.default}
                onClick={handleLastPage}
                TippyProps={{
                  content: 'Last Page',
                  ...sharedTippyProps,
                }}
                size={IconSizes.sm}
                {...LastPageIconButtonProps}
              />
            </Grid>
          </Grid>
          {isShowPerPage && (
            <Grid className={clsx(styles.paginationSection, styles.perPageSection)}>
              Show{' '}
              <PulseSimpleSelect
                classes={{
                  select: styles.paginationPerPage,
                }}
                onChange={handlePerPageChange}
                PulseIconsProps={{
                  size: IconSizes.sm,
                }}
                value={perPage}
                {...PulseSimpleSelectProps}
              >
                <PulseSimpleSelectOption value={25}>25</PulseSimpleSelectOption>
                <PulseSimpleSelectOption value={50}>50</PulseSimpleSelectOption>
                <PulseSimpleSelectOption value={100}>100</PulseSimpleSelectOption>
              </PulseSimpleSelect>{' '}
              per page
            </Grid>
          )}
          <Grid
            item
            data-testid="paginationDisplaySummary"
            className={clsx(styles.paginationSection, styles.paginationDisplaySummary)}
          >
            Displaying records {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1} -{' '}
            {currentPage === internalLastPage ? total : currentPage * perPage} of {total}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PulsePagination;
