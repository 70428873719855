import React, { FC, useContext, useEffect, useRef } from 'react';
import { MenuListComponentProps } from 'react-select';
import styles from './pulse-select-menu-list.module.scss';
import clsx from 'clsx';
import PulseScrollbar from 'components/pulse-scrollbar/pulse-scrollbar';
import { ScrollContext } from '../pulse-select-base';
import { PulseClassName } from 'pulse-commons/types';
import { DesignVariant } from 'components/v3/types/v3-component.types';

export interface PulseSelectMenuListProps extends Partial<MenuListComponentProps<{ label: string; value: string }>> {
  classes?: { root: PulseClassName };
  isLoading?: boolean;
  variant?: DesignVariant;
}

const PulseSelectMenuList: FC<PulseSelectMenuListProps> = (props: PulseSelectMenuListProps) => {
  const { children, className, isLoading, isMulti, innerRef, maxHeight = 350, variant } = props;

  const loadingEl = useRef(null);
  const scrollContext = useContext(ScrollContext);
  const scrollbarRef = useRef<any>();

  const OptionComponent = props.selectProps?.components?.Option;

  const updateScrollContext = (event: React.MouseEvent) => {
    scrollContext.menuListScrollTop = event.currentTarget.scrollTop;
  };

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current?.scrollTop(scrollContext.menuListScrollTop);
    }
  }, [scrollbarRef.current, scrollContext.menuListScrollTop]);

  const loadingElements = (): number[] => {
    const loadingElementsArray = [];
    let i = 0;
    const expectedOptionsNumber = props.selectProps?.pageSize || 0;
    while (i < expectedOptionsNumber) {
      // eslint-disable-next-line
      // @ts-ignore
      loadingElementsArray.push(<OptionComponent key={i} isLoading />);
      i++;
    }
    return loadingElementsArray;
  };

  return (
    <div
      className={clsx(
        {
          'menu-list': true,
          'menu-list--is-multi': isMulti,
        },
        styles['pulse-select-menu-list__root'],
        className,
      )}
    >
      <PulseScrollbar
        ScrollbarProps={{
          autoHeight: true,
          autoHeightMax: maxHeight,
          onScroll: updateScrollContext,
          ref: scrollbarRef,
        }}
      >
        <div ref={innerRef} style={{ maxHeight }}>
          {children}
          {isLoading && (
            <div ref={loadingEl} className={styles['pulse-select-menu-list__loading']}>
              {loadingElements()}
            </div>
          )}
        </div>
      </PulseScrollbar>
    </div>
  );
};

export default PulseSelectMenuList;
