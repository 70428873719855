import { AxiosResponse } from 'axios';
import { getConfig as baseGetConfig, v2Endpoint } from 'pulse-api/base';
import { GetConfig, PulseSubRequestObject } from 'types/pulse-resource';

const url = `/v2/api/groups`;

const getConfig = (params: GetConfig): PulseSubRequestObject => baseGetConfig(params, url);

const get: (params: Partial<GetConfig>) => Promise<AxiosResponse<any>> = (params): Promise<AxiosResponse<any>> => {
  return v2Endpoint.request(getConfig(params));
};

export const groupsApi = {
  get,
  getConfig,
};
