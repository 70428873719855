import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import PulseDatePicker from 'components/pulse-date-picker/pulse-datepicker';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import {
  useResourcePlannerDispatch,
  useResourcePlannerState,
} from 'components/pulse-resource-planner/context/resource-planner-context';
import { Actions, RP_DATE_SERVER_FORMAT } from 'components/pulse-resource-planner/reducers/resource-planner-reducer';
import parseISO from 'date-fns/parseISO';
import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './grid-navigation.module.scss';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import { SchedulerPro } from '@bryntum/schedulerpro-thin';

interface GridNavigationProps {
  scheduler: SchedulerPro;
}

const GridNavigation: FC<GridNavigationProps> = ({ scheduler }) => {
  const rpState = useResourcePlannerState();
  const rpDispatch = useResourcePlannerDispatch();

  const [currentDate, setCurrentDate] = useState<Date>(parseISO(rpState.resourcePlannerDateRange.startDate));

  const navLeft = () => {
    const targetDate = subMonths(currentDate, 1);
    scheduler
      ?.scrollToDate(targetDate, {
        block: 'start',
      })
      .then(() => {
        setCurrentDate(targetDate);
      });
  };

  const handleGridDateRangeChange = useCallback(
    ({ values }) => {
      /** Check if the selected startDate is different from the current startDate */
      scheduler?.scrollToDate(values.startDate, {
        block: 'start',
      });

      if (scheduler?.visibleDateRange) {
        const { startDate, endDate } = scheduler?.visibleDateRange;
        const startDateNew = format(startDate, RP_DATE_SERVER_FORMAT);
        const endDateNew = format(endDate, RP_DATE_SERVER_FORMAT);
        rpDispatch({
          type: Actions.setInfiniteScroll,
          payload: {
            resourcePlannerDateRange: {
              startDate: startDateNew,
              endDate: endDateNew,
            },
          },
        });
      }

      setCurrentDate(values.startDate);
    },
    [scheduler],
  );

  const navRight = () => {
    const targetDate = addMonths(currentDate, 1);
    scheduler
      ?.scrollToDate(targetDate, {
        block: 'start',
      })
      .then(() => {
        setCurrentDate(targetDate);
      });
  };

  // Set date on calendar when scrolling
  // useEffect(() => {
  //   const currentDateOnView = scheduler.visibleDateRange();
  //   currentDateOnView && setCurrentDate(currentDateOnView['startDate']);
  // }, [rpState.resourcePlannerDateRange]);

  const Component = useMemo(() => {
    return (
      <div className={styles['gridNav__root']}>
        <Tippy
          content={`Back 1 month (${format(subMonths(currentDate, 1), 'yyyy-MM-dd')})`}
          placement="top"
          appendTo={document.body}
        >
          <PulseButtonBase
            classes={[styles['pulse-rp__actionBtn']]}
            disabled={rpState.rpNav === 'left'}
            icon
            iconClasses={{ icon: clsx('fal fa-chevron-left', styles['pulse-rp__actionIcon']) }}
            label=""
            onClick={navLeft}
          />
        </Tippy>
        <PulseDatePicker
          DatepickerProps={{
            PulseButtonBaseProps: {
              classes: [styles['pulse-rp__actionBtn'], styles['action__calendar']],
            },
          }}
          values={{
            startDate: currentDate,
          }}
          onChange={handleGridDateRangeChange}
        />
        <Tippy
          content={`Forward 1 month (${format(addMonths(currentDate, 1), 'yyyy-MM-dd')})`}
          placement="top"
          appendTo={document.body}
        >
          <PulseButtonBase
            classes={[styles['pulse-rp__actionBtn']]}
            disabled={rpState.rpNav === 'right'}
            icon
            iconClasses={{ icon: clsx('fal fa-chevron-right', styles['pulse-rp__actionIcon']) }}
            label=""
            onClick={navRight}
          />
        </Tippy>
      </div>
    );
  }, [currentDate, rpState.resourcePlannerDateRange.startDate, rpState.rpNav, scheduler]);

  return Component;
};

export default React.memo(GridNavigation);
