import { format, addDays } from 'date-fns';

const pulseTimelineWeekAndDate = {
  id: 'pulseTimelineWeekAndDate',
  name: 'Pulse Timeline Week and Date',
  tickWidth: 24, // Time column width in horizontal mode
  tickHeight: 24, // T
  shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
  shiftUnit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
  timeResolution: {
    // Dates will be snapped to this resolution
    unit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
  },

  headers: [
    // This defines your header rows from top to bottom
    {
      // For each row you can define 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
      unit: 'week',
      increment: 1,
      renderer: (startDate: Date, endDate: Date): string =>
        `${format(startDate, 'dd MMM')} - ${format(addDays(endDate, -1), 'dd MMM')}`,
    },
    {
      unit: 'day',
      increment: 1,
      renderer: (startDate: Date): string => `${format(startDate, 'EEEEE')}`,
    },
  ],
  columnLinesFor: 1,
};

export const presets = [pulseTimelineWeekAndDate];
