import React, { ReactElement, useEffect, useState } from 'react';
import { PulseAvatarProps } from './pulse-avatar-types';
import styles from './pulse-avatar.module.scss';
import Avatar from '@mui/material/Avatar';
import { getLetterAvatar } from 'pulse-commons/helpers';
import { AvatarSizes } from 'pulse-commons/types';
import axios from 'axios';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import clsx from 'clsx';

const isDevelopment = process.env.NODE_ENV === 'development';

const isEqual = (prevProps: PulseAvatarProps, nextProps: PulseAvatarProps) => {
  return prevProps.imageUrl === nextProps.imageUrl;
};

export const PulseAvatar = (props: PulseAvatarProps): ReactElement => {
  const {
    userName,
    onClick,
    imageUrl,
    classes = { root: '' },
    TippyProps = { placement: 'top', arrow: true, interactive: true },
    AvatarProps,
    size = AvatarSizes.md,
  } = props;

  const [isLoading, setIsLoading] = useState(imageUrl && true);
  const [imgSrc, setImgSrc] = useState<string>('');

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    /* istanbul ignore next */
    imageUrl &&
      axios
        .get(imageUrl, {
          withCredentials: true,
          cancelToken: source?.token,
          onDownloadProgress: () => {
            !isLoading && setIsLoading(true);
          },
          responseType: 'arraybuffer',
        })
        .then(response => {
          const base64 = btoa(
            new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
          );
          setImgSrc(`data:${response.headers['content-type']};base64,${base64}`);
          setIsLoading(false);
        })
        .catch(error => isDevelopment && console.log(`Download image failed.`, JSON.stringify(error.message, null, 2)));

    return () => {
      source && source.cancel('Component unmounted');
    };
  }, [imageUrl]);

  /**
   * Generate the class names for root
   */
  const rootStyle = clsx([styles['pulse-avatar__root'], classes?.root, styles[size]]);

  return (
    <div className={rootStyle} data-testid="pulse-avatar__ctn" onClick={onClick}>
      <Tippy content={userName} {...TippyProps}>
        <Avatar alt={userName} src={imgSrc} className={styles[size]} {...AvatarProps}>
          {getLetterAvatar(userName)}
        </Avatar>
      </Tippy>
    </div>
  );
};

export default React.memo<PulseAvatarProps>(PulseAvatar, isEqual);
