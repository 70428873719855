import { ResourceModel } from '@bryntum/schedulerpro-thin';

interface RPUserModel {
  avatarUrl: string;
  departments?: {
    department_id: string;
    name: string;
  };
  maxHours: string;
  defaultTimesheetActivity: {
    name: string;
    timesheetactivityid: string;
  };
  isAgresso: boolean;
  job?: {
    type: 'jobs';
    id: string;
    attributes: {
      brandId: string | number;
      brandTitle: string;
      editMode: boolean;
      isAgresso: boolean;
      isNC: boolean;
      is_finance_manager: boolean;
      jobExtension: string;
      jobId: string | number;
      jobTitle: string;
      status: string;
    };
  };
  client: string;
  primaryClient?: {
    clientid: number;
    company: string;
  };
  roles?: {
    role_id: number;
    name: string;
  };
}

class RPUserModel extends ResourceModel {
  static get fields(): Record<string, any>[] {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'name', type: 'string', dataSource: 'name' },
      { name: 'maxHours', type: 'string' },
      { name: 'avatarUrl', type: 'string' },
      { name: 'defaultTimesheetActivity' },
      { name: 'departments' },
      { name: 'isAgresso' },
      { name: 'job' },
      { name: 'imageUrl', type: 'string', dataSource: 'avatarUrl' },
      { name: 'primaryClient' },
      { name: 'client' },
    ];
  }
}

export default RPUserModel;
