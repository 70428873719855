import React, { ReactElement } from 'react';
import styles from '../user-setting-modal.module.scss';
import PulseRadioGroup from 'components/pulse-radio-group/pulse-radio-group';
import PulseSelectBase from 'components/pulse-select/base/pulse-select-base';
import PulseInput from 'components/pulse-input/pulse-input';
import { Option, ResourceBookingsTabProps } from '../user-setting-modal-types';

const bookableData: Option[] = [
  {
    value: 'y',
    label: 'Yes',
  },
  {
    value: 'n',
    label: 'No',
  },
];

const plannerPermission: Option[] = [
  {
    value: '0',
    label: 'No Access',
  },
  {
    value: '1',
    label: 'View Access',
  },
  {
    value: '2',
    label: 'Manage bookings',
  },
];

export const convertData = (arrayData: any[]): Option[] => {
  return arrayData.map(each => {
    return {
      label: each.name,
      value: each.id,
    };
  });
};

export const getDefaultValue = (id: number, arrayData: any[]): Option[] | undefined => {
  if (!id && !arrayData) {
    return undefined;
  }
  return arrayData.filter(each => each.value === id);
};

const ResourceBookingsTab = ({
  userData,
  handleChangeBookable,
  handleChangeDepartment,
  handleChangeActivity,
  handleChangeRole,
  handleChangeBookableHours,
  handleChangePermission,
}: ResourceBookingsTabProps): ReactElement => {
  const {
    roles,
    timesheet_activities,
    departments,
    bookable,
    department_id,
    default_timesheet_activity_id,
    role_id,
    max_hours,
    scheduler_access_edit,
    scheduler_access_view,
  } = userData;

  const getPlannerPermission = (): string => {
    if (scheduler_access_edit === 'y' && scheduler_access_view === 'y') {
      return '2';
    }

    if (scheduler_access_edit === 'n' && scheduler_access_view === 'n') {
      return '0';
    }

    if (scheduler_access_edit === 'n' && scheduler_access_view === 'y') {
      return '1';
    }

    return '0';
  };

  return (
    <div className={styles['setting-modal__ctn']}>
      <div className={styles['setting-modal__row']}>
        <div className={styles['setting-modal__column']}>
          <div>
            <h4>Make user bookable?</h4>
            <PulseRadioGroup
              data={bookableData}
              RadioGroupProps={{ value: bookable, onChange: event => handleChangeBookable(event) }}
              classes={{ root: styles['radio-selected__root'] }}
              FormControlLabelProps={{
                control: <div></div>,
                label: '',
                classes: {
                  root: styles['radio-selected__root--bookable'],
                },
              }}
              RadioProps={{
                classes: {
                  root: styles['radio-selected__btn--root'],
                },
              }}
            />
          </div>
        </div>
        <div className={styles['setting-modal__column']}>
          <PulseSelectBase
            isSearchable={true}
            labelName="Default resource booking activity"
            staticOptions={convertData(timesheet_activities)}
            variant="select2"
            TippyProps={{ appendTo: document.body }}
            dataStructure={null}
            value={getDefaultValue(default_timesheet_activity_id, convertData(timesheet_activities))}
            changeHandler={handleChangeActivity}
          />
        </div>
      </div>
      <div className={styles['setting-modal__row']}>
        <div className={styles['setting-modal__column']}>
          <PulseSelectBase
            isSearchable={true}
            labelName="User department"
            staticOptions={convertData(departments)}
            variant="select2"
            TippyProps={{ appendTo: document.body }}
            dataStructure={null}
            value={getDefaultValue(department_id, convertData(departments))}
            changeHandler={handleChangeDepartment}
          />
        </div>
        <div className={styles['setting-modal__column']}>
          <PulseSelectBase
            isSearchable={true}
            labelName="Resource planner role"
            staticOptions={convertData(roles)}
            variant="select2"
            TippyProps={{ appendTo: document.body }}
            dataStructure={null}
            value={getDefaultValue(role_id, convertData(roles))}
            changeHandler={handleChangeRole}
          />
        </div>
      </div>
      <div className={styles['setting-modal__row']}>
        <div className={styles['setting-modal__column']}>
          <PulseInput
            label="Maximum bookable hours per day"
            classes={{
              root: styles['bookable-hours__root'],
              label: styles['bookable-hours__label'],
              input: styles['bookable-hours__input'],
            }}
            InputBaseProps={{
              value: max_hours,
              onChange: handleChangeBookableHours,
              placeholder: 'Set Maximum Hours...',
            }}
          />
        </div>
        <div className={styles['setting-modal__column']}>
          <div>
            <h4>Resource planner permissions</h4>
            <PulseRadioGroup
              data={plannerPermission}
              RadioGroupProps={{ value: getPlannerPermission(), onChange: event => handleChangePermission(event) }}
              classes={{ root: styles['radio-selected__root'] }}
              FormControlLabelProps={{
                control: <div></div>,
                label: '',
                classes: {
                  root: styles['radio-selected__root--permission'],
                },
              }}
              RadioProps={{
                classes: {
                  root: styles['radio-selected__btn--root'],
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceBookingsTab;
