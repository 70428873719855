/* istanbul ignore next */
const _imageToPrint = (source: string): string => {
  return (
    '<html><head><script>function step1(){\n' +
    "setTimeout('step2()', 10);}\n" +
    'function step2(){window.print();window.close()}\n' +
    '</scri' +
    "pt></head><body onload='step1()'>\n" +
    "<img src='" +
    source +
    "' /></body></html>"
  );
};

/* istanbul ignore next */
export const handlePrintImage = (source: string): void => {
  const Pagelink = 'about:blank';
  const pwa = window.open(Pagelink, '_new');
  if (pwa) {
    pwa.document.open();
    pwa.document.write(_imageToPrint(source));
    pwa.document.close();
  }
};

/* istanbul ignore next */
export const handleDownloadImage = (downloadUrl: string): void => {
  window.location.href = downloadUrl;
};
