import React, { FC, useState } from 'react';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import { PulseUploaderDropzone } from 'components/pulse-uploader/pulse-uploader';
import { PulseUploaderFile } from 'components/pulse-uploader/pulse-uploader-types';
import { METHODS, v2Endpoint } from 'pulse-api/base';
import { PulseSelectOptionType } from 'components/pulse-select/base/pulse-select-base-types';
import PulseLoader from 'components/pulse-loader/pulse-loader';
import styles from './import-file-modal.module.scss';
import PulseInfoBar from 'components/pulse-info-bar/pulse-info-bar';
import clsx from 'clsx';

const appURL = window.pulse?.config?.urls?.app || 'http://pulse-dev.local/';

const ImportProjectFileModal: FC<any> = props => {
  const { isOpen, showMock, projectId, onCancel, onImportData, actions, onHandleImportData } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [projectFile, setProjectFile] = useState<PulseUploaderFile[]>([]);
  const [folder, setFolder] = useState<PulseSelectOptionType | null>(null);

  const handleOnFileDrop = (newFiles: PulseUploaderFile[]): void => {
    //TODO: Need to remove this once we integrate api for uploader
    setProjectFile([...newFiles]);
  };

  const handleRemoveFile = (removedFile: PulseUploaderFile): void => {
    const newAssets = [...projectFile.filter(file => file.name !== removedFile.name)];
    setProjectFile([...newAssets]);
  };

  const handleChangeFolder = (value: Record<'dirId', PulseSelectOptionType>) => {
    setFolder(value.dirId);
  };

  const handleImportData = async () => {
    if (showMock) {
      return onImportData({});
    }
    setLoading(true);
    if (projectFile.length > 0 && !!folder) {
      const file = new FormData();
      const fileType: string = projectFile[0].name.split('.').pop() || '';
      if (fileType.toLowerCase() === 'mpp' || fileType.toLowerCase() === 'xml') {
        onHandleImportData && (await onHandleImportData(projectFile, folder));
        const url = `${actions.loadUrl.slice(0, actions.loadUrl.lastIndexOf('/'))}/import`;
        file.append('mpp-file', projectFile[0]);
        await v2Endpoint
          .request({
            method: METHODS.POST,
            url: url,
            data: file,
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(res => {
            const msProjectData = res.data;
            onImportData(msProjectData);
            setProjectFile([]);
          })
          .catch(() => {
            window.utilities?.notification?.danger('Failed to import data');
          })
          .finally(() => {
            setLoading(false);
            // Close modal after process import data
            onCancel();
          });
      } else {
        window.utilities?.notification?.danger(
          'The uploaded file format cannot be used. Please upload an .MMP to complete your import.',
        );
      }
    } else {
      setLoading(false);
      window.utilities?.notification?.danger('Please select folder and file.');
    }
  };

  return (
    <>
      <PulseLoader isVisible={isLoading} />
      <PulseModalBase
        variant="jqconfirm"
        open={isOpen}
        headerIconClasses="fal fa-file-import"
        PulseModalHeaderProps={{
          onCloseClick: onCancel,
          primaryText: 'Import Data',
          classes: {
            root: 'header-root',
            closeBtn: 'header-root__close-btn',
          },
        }}
        onConfirmClick={handleImportData}
        footer={true}
        footerConfirmText="Import Data"
        PulseModalFooterProps={{
          confirmIconClasses: 'fal fa-file-import',
        }}
        footerConfirmDisabled={!projectFile || isLoading}
        onModalClose={onCancel}
      >
        <div className={styles['import-file']}>
          <PulseInfoBar infoType="info" title="Accepted File Types">
            <div>
              Only MPP and XML files are allowed to be imported for use in Project Timelines. Please refer to the sample
              files below for accepted file types.
              <div className={styles['import-file__banner']}>
                <a href={`${appURL}timeline/sample.mpp`} download="sample.mpp" target="_blank" rel="noreferrer">
                  <i className={clsx(['fal fa-file', styles['import-file__banner__file-icon']])} />
                  Sample MPP file
                </a>
                |
                <a href={`${appURL}timeline/sample.xml`} download="sample.xml" target="_blank" rel="noreferrer">
                  <i className={clsx(['fal fa-file', styles['import-file__banner__file-icon']])} />
                  Sample XML file
                </a>
              </div>
            </div>
          </PulseInfoBar>
          <PulseUploaderDropzone
            classes={{
              root: styles.uploader__root,
              dropzone: styles.uploader__dropzone,
              fileEditor: styles.uploader__text,
            }}
            isMultiple={false}
            onFileDrop={handleOnFileDrop}
            onFileRemove={handleRemoveFile}
            files={projectFile}
            PulseFolderSelectProps={{
              classes: {
                selectOptionRoot: styles['dropzone__select-option--root'],
                selectOptionLabel: styles['dropzone__select-option--label'],
              },
              value: folder,
              valueChangeHandler: handleChangeFolder,
              labelName: 'Select folder',
              selectFirst: true,
              isMulti: false,
              isClearable: false,
              jobId: projectId,
              insideModal: true,
            }}
          />
        </div>
      </PulseModalBase>
    </>
  );
};

export default ImportProjectFileModal;
