import React, { ReactElement, useRef, useMemo } from 'react';
import PulseBryntumGrid from 'components/pulse-bryntum-grid';
import { PULSE_LOCAL_URL } from 'pulse-commons/helpers';
import styles from './booking-history.module.scss';
import { BOOKING_HISTORIES_GRID_JQ } from '../utils/index';
import clsx from 'clsx';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const FORMATTED_DATE = 'yyyy-MM-dd HH:mm aaa';

const getBryntumCellContent = () => ({ value }: { value: string | string[] }): string => {
  let label;
  return `<ul class="${clsx(styles['booking-history-grid__cell-ul'])}">
  ${(Array.isArray(value) && value.length > 1 ? value : [value])
    .map((item: any) => {
      switch (item) {
        case 'totalhours':
          label = 'Total Hours';
          break;
        case 'hoursperday':
          label = 'Hours per day';
          break;
        case 'startdate':
          label = 'Start Date';
          break;
        case 'enddate':
          label = 'End Date';
          break;
        default:
          label = item;
      }
      if (typeof item === 'object') {
        label = item.name ?? item.jobtitle;
      }
      return `<li class="${clsx(
        styles['booking-history-grid__cell-li'],
        styles['booking-history-grid__cell-content'],
      )}">${label ? label : ''}</li>`;
    })
    .join(' ')}
</ul>`;
};

const commonColumnData = {
  draggable: false,
  htmlEncode: false,
  renderer: getBryntumCellContent(),
};

const bookingHistoryColumns = [
  {
    id: 1,
    field: 'userName',
    text: 'USER NAME',
    width: 150,
    autoHeight: true,
    cellCls: styles['custom-history-col-review'],
  },
  {
    id: 2,
    field: 'optionChanged',
    text: 'FIELD CHANGED',
    width: 175,
    autoHeight: true,
    cellCls: styles['custom-history-col-review'],
    ...commonColumnData,
  },
  {
    id: 3,
    field: 'oldValue',
    text: 'OLD VALUE',
    autoHeight: true,
    width: 150,
    cellCls: styles['custom-history-col-review'],
    ...commonColumnData,
  },
  {
    id: 4,
    field: 'newValue',
    text: 'NEW VALUE',
    autoHeight: true,
    width: 150,
    cellCls: styles['custom-history-col-review'],
    ...commonColumnData,
  },
  {
    id: 5,
    field: 'updateDate',
    text: 'UPDATED DATE/TIME',
    autoHeight: true,
    cellCls: styles['custom-history-col-review'],
    width: 150,
    ...commonColumnData,
    renderer: ({ value }) => {
      return format(parseISO(value), FORMATTED_DATE);
    },
  },
];

const BookingHistory = ({ bookingId }: { bookingId: number | null | undefined }): ReactElement => {
  const gridRef = useRef<any>(null);
  const URL_ENDPOINT = `${PULSE_LOCAL_URL}/v2/api/audit-history`;
  const AJAX_STORE_CONFIG = useMemo(() => {
    return {
      readUrl: URL_ENDPOINT,
      autoLoad: true,
      pageSize: 5,
      params: {
        jq: BOOKING_HISTORIES_GRID_JQ,
        'filter[model]': 'App\\Models\\Booking',
        'filter[resourceId]': bookingId,
        include: 'user',
      },
      pageParamName: 'page[number]',
      pageSizeParamName: 'page[per_page]',
      responseTotalProperty: 'page.total',
    };
  }, []);

  return (
    <div id={styles['booking-history-grid__ctn']}>
      <PulseBryntumGrid
        selectable={false}
        ref={gridRef}
        gridProps={{
          columns: bookingHistoryColumns,
          sortFeature: true,
          rowHeight: 48,
          cellMenuFeature: false,
          headerMenuFeature: true,
          selectionMode: {
            preserveOnDataChange: false,
          },
        }}
        ajaxStoreConfig={AJAX_STORE_CONFIG}
        paginate={true}
        classes={{
          root: styles['booking-history-grid__root'],
        }}
      />
    </div>
  );
};

export default BookingHistory;
