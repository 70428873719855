import clsx from 'clsx';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import PulseSelectOption, { PulseSelectOptionProps } from 'components/pulse-select/base/components/pulse-select-option';
import React, { ReactElement, Ref, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './pulse-project-option.module.scss';

const PulseProjectLoadingComponent = (): ReactElement => {
  return (
    <>
      <span className={clsx(styles['pulse-project-option__icon'], styles['pulse-project-option__icon--skeleton'])}>
        <PulseIcon
          classes={{ root: styles['pulse-project-option__iconRoot'], icon: 'fal fa-rocket-launch' }}
          iconName=""
        />
      </span>
      <span>
        <Skeleton
          className={clsx([styles['pulse-skeleton'], styles['pulse-project-option__label--skeleton']])}
          height={12}
          width={120}
        />
        <Skeleton
          className={clsx([styles['pulse-skeleton'], styles['pulse-project-option__extension--skeleton']])}
          height={9}
          width={50}
        />
      </span>
    </>
  );
};

type PulseProjectOptionProps = PulseSelectOptionProps;

export const PulseProjectOption = (props: PulseProjectOptionProps, ref: Ref<HTMLDivElement>): ReactElement => {
  const { classes, data = {}, isValue = false, PulseLabelProps, removeable } = props;

  const { classes: PulseLabelClasses } = PulseLabelProps || {};

  /**
   * Compose the jobExtension here
   */
  let jobExtension = '';

  if (data.raw?.jobExtension) {
    jobExtension = `[${data.raw?.jobExtension}]`;
  } else if (data.jobExtension) {
    jobExtension = `[${data.jobExtension}]`;
  }

  /**
   * We want to pass a custom PulseLabelProps
   */
  const internalPulseLabelProps: Partial<typeof PulseLabelProps> = {
    classes: {
      ...PulseLabelClasses,
      root: clsx(styles['pulse-project-option__pulseLabelRoot'], PulseLabelClasses?.root),
    },
    icon: true,
    iconComponent: (
      <PulseIcon classes={{ root: styles['pulse-project-option__icon'], icon: 'fal fa-rocket-launch' }} iconName="" />
    ),
    isShowFull: !isValue,
    label: !isValue ? (
      <span className={styles['pulse-project__label']}>
        {data.label}
        <span className={styles['pulse-project__extension']}>{jobExtension}</span>
      </span>
    ) : (
      `${jobExtension} ${data.label}`
    ),
    ...PulseLabelProps,
  };

  return (
    <PulseSelectOption
      {...props}
      classes={{
        ...classes,
        root: clsx([
          classes?.root,
          styles['pulse-project-option__root'],
          removeable && styles['pulse-project-option__removeable'],
        ]),
      }}
      forwardedRef={ref}
      PulseLabelProps={useMemo(() => internalPulseLabelProps, [PulseLabelProps])}
      isLoadingComponent={<PulseProjectLoadingComponent />}
    />
  );
};

export default React.forwardRef<HTMLDivElement, PulseProjectOptionProps>(PulseProjectOption);
