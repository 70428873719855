import React, { FC } from 'react';
import clsx from 'clsx';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { Colors, IconSizes } from 'pulse-commons/types';
import { OverrideBaselineModalProps } from './baseline-modal-types';
import styles from './baseline-modal.module.scss';

const PromoteToMasterModal: FC<OverrideBaselineModalProps> = props => {
  const { isOpen, baseline, onCancel, onConfirm } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses="fal fa-exclamation-circle"
      PulseModalHeaderProps={{
        onCloseClick: onCancel,
        primaryText: 'OVERRIDE EXISTING BASELINE',
        classes: {
          root: 'header-root',
          closeBtn: 'header-root__close-btn',
        },
      }}
      footer={false}
      onModalClose={onCancel}
    >
      <div className={styles['baseline-modal']}>
        <div className={styles['baseline-modal__icon']}>
          <i className="fal fa-exclamation-circle"></i>
        </div>
        <div className={styles['baseline-modal__heading']}>OVERRIDE EXISTING BASELINE</div>

        <div className={styles['baseline-modal__title']}>
          <div>
            Are you sure you want to <strong>override Baseline {baseline}</strong>?
          </div>
          <div className={styles['baseline-modal__title--warning']}>This action is not reversible.</div>
        </div>
        <div className={styles['baseline-modal__actions']}>
          <PulseButtonBase classes={[styles['baseline-modal__button']]} label={'Cancel'} onClick={onCancel} />
          <PulseButtonBase
            classes={[clsx(styles['baseline-modal__button'], styles['baseline-modal__button--confirm'])]}
            iconSize={IconSizes.sm}
            color={Colors.success}
            onClick={onConfirm}
            label="Confirm"
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default PromoteToMasterModal;
