import React, { ReactElement } from 'react';
import { BookingEventColorMap } from '../booking-types';
import styles from './legend-resource-planner.module.scss';
import { BookingStatusLegendProps, ResourceCapacityProps } from './legend-resource-planner-types';
import clsx from 'clsx';

export const bookingStatuses = [
  {
    label: 'Standard',
    status: 'Confirmed',
    style: {
      color: '#ffffff',
      background: BookingEventColorMap.standard,
    },
    icon: 'fal fa-user',
  },
  {
    label: 'Completed',
    status: 'Confirmed',
    style: {
      color: '#ffffff',
      background: BookingEventColorMap.standard,
    },
    icon: 'fal fa-check-circle',
  },
  {
    label: 'Escalated',
    status: 'Confirmed',
    style: {
      color: '#ffffff',
      background: BookingEventColorMap.escalated,
    },
    icon: 'fal fa-fire',
  },
  {
    label: 'Unapproved',
    status: 'Confirmed',
    style: {
      color: '#ffffff',
      background: 'repeating-linear-gradient(135deg,#0AB3FF,#0AB3FF 12px,#3BC2FF 7.5px,#3BC2FF 20px)',
    },
    icon: 'fal fa-user',
  },
  {
    label: 'Standard',
    status: 'Tentative',
    style: {
      color: '#ffffff',
      background: 'repeating-linear-gradient(135deg,#2AB0AE,#2AB0AE 12px,#32BFBD 7.5px,#32BFBD 20px)',
    },
    icon: 'fal fa-user',
  },
  {
    label: 'Completed',
    status: 'Tentative',
    style: {
      color: '#ffffff',
      background: 'repeating-linear-gradient(135deg,#02979B,#02979B 12px,#32BFBD 7.5px,#32BFBD 20px)',
    },
    icon: 'fal fa-check-circle',
  },
  {
    label: 'Escalated',
    status: 'Tentative',
    style: {
      color: '#ffffff',
      background: 'repeating-linear-gradient(135deg,#FF3D22,#FF3D22 12px,#FF5C45 7.5px,#FF5C45 20px)',
    },
    icon: 'fal fa-fire',
  },
];

export const capacities = [
  {
    value: '0-60%',
    color: '#C9C9C9',
    border: '#F0F0F0',
  },
  {
    value: '61-99%',
    color: '#04DB89',
    border: '#F0F0F0',
  },
  {
    value: '100%',
    color: '#03A869',
    border: '#FFE3C3',
  },
  {
    value: '>100%',
    color: '#D93A40',
    border: '#FFD8DA',
  },
];

export const flags = [
  { label: 'Timeline Marker', flagText: 'START/END' },
  { label: 'Timeline Phase', flagText: 'MULTI-DAY' },
  { label: 'Timeline Marker', flagText: 'SINGLE DAY' },
];

const BookingStatusLegend = ({ label, status, icon, background }: BookingStatusLegendProps): ReactElement => {
  return (
    <div className={styles['rp-legend__root']}>
      <div className={styles['rp-legend__root--icon']} style={{ background: background }}>
        <i className={icon}></i>
      </div>
      <div className={styles['rp-legend__root--status']}>
        <p>{label}</p>
        <p>{status}</p>
      </div>
    </div>
  );
};

const ResourceCapacity = (props: ResourceCapacityProps): ReactElement => {
  const { value, color, borderColor } = props;
  return (
    <div className={styles['rp-legend__root']}>
      <div
        className={styles['rp-legend__root--box']}
        style={{
          background: color,
          borderColor: borderColor,
        }}
      ></div>
      <div className={styles['rp-legend__root--text']}>
        <p>{value}</p>
      </div>
    </div>
  );
};

const PulseResourceLegend = (): ReactElement => {
  return (
    <div className={styles['pulse-rp__legend']}>
      <div className={clsx(styles['pulse-rp__legend--box'], styles['pulse-rp__legend--booking-statuses'])}>
        {bookingStatuses.map((status, index) => {
          return (
            <BookingStatusLegend
              key={index}
              label={status.label}
              icon={status.icon}
              status={status.status}
              background={status.style.background}
            />
          );
        })}
      </div>
      <div className={clsx(styles['pulse-rp__legend--box'], styles['pulse-rp__legend--capacity'])}>
        <h4>Resource Utilization (Booked / Max. Booking Hours)</h4>
        <div className={styles['pulse-rp__legend--capacity__container']}>
          {capacities.map((capacity, index) => {
            return (
              <ResourceCapacity
                key={index}
                value={capacity.value}
                color={capacity.color}
                borderColor={capacity.border}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PulseResourceLegend;
