import React, { ReactElement } from 'react';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { PulseAccordionSummaryProps } from './pulse-accordion-summary-types';
import styles from '../../pulse-accordion.module.scss';
import PulseAnimatedPlusMinus from 'components/pulse-icons/variants/pulse-animated-plus-minus/pulse-animated-plus-minus';
import clsx from 'clsx';

export const PulseAccordionSummary = (props: PulseAccordionSummaryProps): ReactElement => {
  const { children, classes, expanded, iconFirst = true, showPlusMinusIcon = true, ...restProps } = props;

  return (
    <MuiAccordionSummary
      data-testid="accordionSummaryRoot"
      classes={{
        ...classes,
        root: clsx(styles.accordionSummaryRoot, classes?.root, !iconFirst && styles.accordionSummaryRootReOrder),
        content: clsx(styles.accordionSummaryContent, classes?.content),
        expanded: clsx(styles.accordionSummaryExpanded, classes?.expanded),
      }}
      {...restProps}
    >
      {showPlusMinusIcon && (
        <PulseAnimatedPlusMinus
          classes={{
            root: clsx(styles.accordionSummaryIcon, classes?.expandIconWrapper),
          }}
          open={expanded}
        />
      )}
      {children}
    </MuiAccordionSummary>
  );
};

export default PulseAccordionSummary;
