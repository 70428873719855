import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PulseSuggestionSelectPayload } from '../types';

type PulseSuggestionState = Record<string | number, Record<string, Array<any>>>;

const suggestionSlice = createSlice({
  initialState: {} as PulseSuggestionState,
  name: 'suggestionSlice',
  reducers: {
    changeSuggestion: (state, { payload }: PayloadAction<PulseSuggestionSelectPayload>) => {
      const { option, userId, dataType } = payload;

      // Get options base on userId and data type
      const options = (state[userId] || {})[dataType] || [];

      if (options.length) {
        const isExists = options.find(it => it.value === option.value);

        if (isExists) {
          return state;
        }

        // Max recently selected is 10
        if (options.length === 10) {
          return {
            // Remove last options and add selected to first selections
            [userId]: {
              [dataType]: [option, ...options.slice(0, 8)],
            },
          };
        }
      }

      return {
        [userId]: {
          [dataType]: [option, ...options],
        },
      };
    },
  },
});

export default suggestionSlice.reducer;

export const { changeSuggestion } = suggestionSlice.actions;
