import React, { FC, ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import {
  PulseSelectBasePropTypes,
  PulseSelectOptionType,
  PulseSelectPropTypes,
  PulseSelectDataStructureTypes,
} from '../base/pulse-select-base-types';
import { ValueType } from 'react-select';
import PulseTagsOption from './components/pulse-tags-option';
import { OptionProps } from 'react-select';
import isFunction from 'lodash/isFunction';

const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = 'Filter by tags';
const DEFAULT_URL: PulseSelectBasePropTypes['url'] = `/v2/api/tags`;

const DATA_STRUCTURE: PulseSelectDataStructureTypes = {
  dataKey: 'data',
  isJsonApi: true,
  label: 'name',
  lastPage: 'meta.page.last_page',
  pageSize: 25,
  searchKey: 'search',
  type: 'tags',
  value: 'tag_id',
};

export const PulseTagsSelect = React.memo(
  (props: PulseSelectPropTypes): ReactElement => {
    const {
      value = [],
      isMulti = true,
      url = DEFAULT_URL,
      requestFilterKey = 'tag',
      valueChangeHandler,
      labelName = 'Tags',
      placeholder = DEFAULT_PLACEHOLDER,
      dataStructure = DATA_STRUCTURE,
    } = props;

    const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = (
      value: ValueType<PulseSelectOptionType>,
    ): void => {
      isFunction(valueChangeHandler) &&
        valueChangeHandler({
          [requestFilterKey]: value,
        });
    };

    return (
      <PulseSelectBase
        dataStructure={dataStructure}
        changeHandler={handleValueChange}
        components={{
          Option: PulseTagsOption as FC<OptionProps<PulseSelectOptionType>>,
        }}
        isMulti={isMulti}
        labelName={labelName}
        placeholder={placeholder}
        url={url}
        value={value}
        variant={'select2'}
        insideModal={true}
        {...props}
      />
    );
  },
);

PulseTagsSelect.displayName = 'PulseTagsSelect';

export default PulseTagsSelect;
