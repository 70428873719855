import React, { useState, FC, useEffect } from 'react';
import styles from './timeline-public-link-modal.module.scss';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import { TimelinePublicLinkModalProps, OptionType } from './timeline-public-link-modal-types';
import PulseInput from 'components/pulse-input/pulse-input';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import PulseSelectBase from 'components/pulse-select/base/pulse-select-base';
import 'react-select';
import { Skeleton } from '@mui/material';
import { METHODS, v2Endpoint } from 'pulse-api/base';
import { add, formatISO } from 'date-fns';

const staticOptions = [
  { value: 1, label: '1-month' },
  { value: 6, label: '6-month' },
  { value: 12, label: '12-month' },
];

const TimelinePublicLinkModal: FC<TimelinePublicLinkModalProps> = props => {
  const { isOpen, handleCloseModel, title, loadUrl } = props;
  const options = staticOptions;
  const [value, setValue] = useState<OptionType>(options[1]);
  const [linkURL, setLinkURL] = useState<string>('');
  const [expiryDate, setExpiryDate] = useState<string>(formatISO(add(new Date(), { months: value.value })));

  const handleCopyToClipBoard = (): void => {
    if (!navigator.clipboard) {
      return;
    }

    navigator.clipboard.writeText(linkURL);
    window.utilities?.notification?.success('Link copied.');
  };

  const handleChangetimeValue = (option: OptionType) => {
    const formattedDate = formatISO(add(new Date(), { months: Number(option.value) }));
    setExpiryDate(formattedDate);
    setValue(option);
  };

  const requestForLink = async () => {
    const url = `${loadUrl.slice(0, loadUrl.lastIndexOf('/'))}/share-public-link`;
    await v2Endpoint
      .request({
        method: METHODS.POST,
        url: url,
        data: { expiry: expiryDate },
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      })
      .then(res => {
        setLinkURL(res.data.public_link);
      })
      .catch(() => {
        window.utilities?.notification?.danger('Failed to share timeline');
      });
  };

  useEffect(() => {
    if (isOpen) {
      requestForLink();
    }
  }, [isOpen, expiryDate]);

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses={'fal fa-link'}
      footer={false}
      PulseModalHeaderProps={{
        onCloseClick: handleCloseModel,
        primaryText: 'TIMELINE PUBLIC LINK',
        classes: {
          root: 'export-timeline-modal__root',
          closeBtn: 'export-timeline-modal__close-btn',
        },
      }}
      onModalClose={handleCloseModel}
    >
      <div className={styles['modal--content']}>
        <div className={styles['modal--content__timeline--title']}>
          <PulseInput
            label={'TIMELINE'}
            classes={{ input: styles['modal--content__timeline--title__text'] }}
            InputBaseProps={{ value: title }}
          />
        </div>
        <div className={styles['modal--content__timeline--link']}>
          <div className={styles['modal--content__timeline--link__input']}>
            <div className={styles['modal--content__timeline--link__input--text']}>
              <span>PUBLIC LINK</span>
              <PulseTooltip
                TippyProps={{
                  content: 'Link for timeline project',
                  popperOptions: { strategy: 'fixed' },
                  placement: 'top',
                  trigger: 'click',
                }}
              >
                <i className={'fal fa-info-circle'} />
              </PulseTooltip>
            </div>
            <PulseInput
              InputBaseProps={{
                disabled: true,
                placeholder: '',
                classes: {
                  input: styles['public-link__input'],
                },
                endAdornment: (
                  <a className={styles['public-link__copy']} onClick={handleCopyToClipBoard}>
                    Copy link <i className="fal fa-link"></i>
                  </a>
                ),
                value: linkURL,
              }}
            />
          </div>
          <div className={styles['modal--content__timeline--link__expire']}>
            <PulseSelectBase
              dataStructure={null}
              labelName={'LINK EXPIRY'}
              placeholder={'Choose duration'}
              variant="select2"
              TippyProps={{ appendTo: document.body }}
              classes={{ root: styles['link-expiry__root'], label: styles['link-expiry__title'] }}
              value={value}
              hideMenuOnValueChange={true}
              staticOptions={options}
              isClearable={false}
              insideModal={true}
              isSearchable={false}
              isMulti={false}
              changeHandler={value => handleChangetimeValue(value as OptionType)}
              components={{
                Option: (optionProps: any) => {
                  const { data, isLoading, innerProps, label, selectProps } = optionProps;
                  const handleOnChangeValue = () => {
                    selectProps?.onchange(data);
                  };
                  return (
                    <>
                      {isLoading ? (
                        <Skeleton className={styles['link-expiry__loading']} />
                      ) : (
                        <div className={styles['link-expiry__option']} onClick={handleOnChangeValue} {...innerProps}>
                          {label}
                        </div>
                      )}
                    </>
                  );
                },
              }}
            />
          </div>
        </div>
      </div>
    </PulseModalBase>
  );
};

export default TimelinePublicLinkModal;
