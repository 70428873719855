import Jsona from 'jsona';
import isFunction from 'lodash/isFunction';

export const PULSE_LOCAL_URL = process.env.PULSE_LOCAL_URL || '';
const AMERICA = 'America';
const US_DATE_FORMAT = 'MM/dd/yyyy';
const DATE_FORMAT = 'dd/MM/yyyy';

export enum ALLOWED_ENV {
  PULSE_LOCAL_URL = 'PULSE_LOCAL_URL',
}

/**
 * Check if an element has been truncated
 * @param element A selector to pass to document.querySelector()
 */
export const isTruncated = (element: HTMLElement | null): boolean => {
  if (element) {
    return element.scrollWidth > element.clientWidth;
  }
  return false;
};

/**
 * Helper to return a className string and remove
 * double spaces and eol space
 * @param classNameArray An array of className strings
 */
export const composeClassNameFromArray = (classNameArray: string[]): string => {
  const classNameString = classNameArray
    .filter(className => {
      return className;
    })
    .join(' ')
    .replace(/( {2,})|( {1,}$)/g, ' ');
  return classNameString;
};

export const composeDateRange = (dateFrom: string, dateTo: string, separator = 'to'): string => {
  return `${dateFrom.split(' ')[0]} ${separator} ${dateTo.split(' ')[0]}`;
};

/** Check if the device is on mobile or not */
/* istanbul ignore next */
export const isMobileDevice = (): boolean => {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator['msMaxTouchPoints'] > 0;
  } else {
    const mQ = isFunction(window.matchMedia) && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator['userAgent'];
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
};

/**
 * Helper function to toggle css classes to manipulate UI
 * For example, passing "open" and true will add open
 * to the className string
 * @param defaultClasses The default classes to attach to the element
 * @param toggleStyleName The name of the class you want to toggle for instance - open, selected, hidden
 * @param toggleState The state of the toggle
 */
export const toggleClass = ({
  defaultClasses,
  toggleStyleName,
  toggleState,
}: {
  defaultClasses: string[];
  toggleStyleName: string;
  toggleState: boolean;
}): string => {
  toggleState && defaultClasses.push(toggleStyleName);
  return composeClassNameFromArray(defaultClasses);
};

/** @todo move this to a filter HOC helper */
import qs, { IStringifyOptions } from 'qs';
/**
 * The following helper takes an object of parameters and turns it into a query string
 * The function need to be able to interpret the following parameters
 * param = [id1, id2, id3]
 * param = [
 *  { value: 'a', test: 'b', },
 *  { value: 'c', test: 'd', },
 * ]
 * @param params Query string parameters
 */
export const transformQueryParams = (
  params: { [index: string]: any },
  arrayFormat: IStringifyOptions['arrayFormat'] = 'comma',
): string => {
  Object.keys(params).forEach(paramKey => {
    switch (paramKey) {
      case 'filter':
      case 'filters':
        if (!Array.isArray(params['filters'])) {
          for (const filterKey in params[paramKey]) {
            if (Array.isArray(params[paramKey][filterKey])) {
              params[paramKey][filterKey] = params[paramKey][filterKey].map(
                (filter: { value: number; label: string } | string | number) => {
                  if (filter instanceof Object) {
                    return filter.value;
                  } else {
                    return filter;
                  }
                },
              );
            } else if (params[paramKey][filterKey] instanceof Object) {
              params[paramKey][filterKey] = params[paramKey][filterKey]['value'];
            }
          }
        }
        break;
      default:
        break;
    }
  });
  const queryString = qs.stringify(params, { arrayFormat, encode: false });
  return queryString;
};

/**
 * Helper function to make 2 letter avatar from userName
 * @default user to prevent trim of null
 * @param userName
 */
export const getLetterAvatar = (userName: string): string => {
  if (!userName) {
    return '';
  }
  let letterAvatar = '';
  const words = userName.trim().split(' ');
  words.forEach(word => {
    letterAvatar += word.charAt(0);
  });
  return letterAvatar.substring(0, 2).toUpperCase();
};

/**
 * This helper function checks if the provide string is
 * a url and returns it otherwise, the function will
 * assume it is the avatar id and create the url to
 * fetch the avatar image.
 *
 * @param avatarUrl The avatarUrl or avatar id
 * @returns
 */
export const getAvatarUrl = (avatarUrl: string): string => {
  if (!avatarUrl) {
    return '';
  }
  let correctAvatarUrl = avatarUrl;
  !correctAvatarUrl.match('http') && (correctAvatarUrl = `${PULSE_LOCAL_URL}/getUserProfileImage.php?id=${avatarUrl}`);
  return correctAvatarUrl;
};

const dataFormatter = new Jsona();
export const parseJsonApi = (json: Record<string, any>): Record<string, any> | Record<string, any>[] =>
  dataFormatter.deserialize(json);

/**
 * Function to return the first letter as capital letter
 * @param word - Word to capitalize first letter
 */
export const ucFirst = (word: string): string => {
  if (typeof word !== 'string') return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const randomID = (): string => `_${Math.random().toString(36).substr(2, 9)}`;

/**
 * Function to return the date format according to user timezone
 * @returns string
 */
export const timezoneDateFormat = (): string => {
  return window.pulse?.config?.user?.timezone?.includes(AMERICA) ? US_DATE_FORMAT : DATE_FORMAT;
};

const ENV = {
  PULSE_LOCAL_URL: process.env.PULSE_LOCAL_URL,
};

/**
 * Function to get the value of ENV variable
 * @param envVarName name of env variable
 * @param fallback the env variable passed if the envVarName not available
 * @returns string
 */
export const getEnv = (envVarName: string, fallback: string): string => {
  return ENV[envVarName] || fallback;
};
