export interface PulseSearchClasses {
  label?: string;
  input?: string;
}

export interface PulseSearchPropTypes {
  labelText?: string;
  placeholder?: string;
  type?: InputTypes;
  presetValue?: string | number;
  onChangeHandler: (value: { search: string }) => void;
  classes?: PulseSearchClasses;
}

export enum InputTypes {
  number = 'number',
  text = 'text',
}
