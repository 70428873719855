import React, { FC, memo, useMemo } from 'react';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import styles from './timeline-import-task-warning-modal.module.scss';
import { TimelineImportTaskWarningModalProps } from './timeline-import-task-warning-modal.types';

const Component: FC<TimelineImportTaskWarningModalProps> = props => {
  const { onClose, onProceed } = props;
  const content = useMemo(
    () => ({
      title: 'You have selected a Task(s) that are currently linked to other Timeline Items.',
      message:
        'If you proceed, the Tasks will unlinked from the current Timeline Items and relinked to the New Timeline Items created by this import process.',
    }),
    [],
  );
  return (
    <PulseModalBase
      variant="jqconfirm"
      open={true}
      headerIconClasses="fal fa-exclamation-circle"
      PulseModalHeaderProps={{
        primaryText: 'Tasks Already Linked',
        classes: {
          root: 'header-root',
          closeBtn: 'header-root__close-btn',
        },
        onCloseClick: onClose,
      }}
      footerConfirmText="Proceed"
      onCancelClick={onClose}
      onConfirmClick={onProceed}
      onModalClose={onClose}
    >
      <div className={styles['import-task-warning-modal']}>
        <p>{content.title}</p>
        <p>{content.message}</p>
        <p className={styles['import-task-warning-modal__font-bold']}>Do you want to proceed?</p>
      </div>
    </PulseModalBase>
  );
};

export default memo(Component);
