import { Box } from '@mui/material';
import PulseBryntumGrid from 'components/pulse-bryntum-grid';
import { BryntumColumnConfig } from 'components/pulse-bryntum-grid/pulse-bryntum-grid.type';
import { PulseTimelineGanttProps } from 'components/pulse-timeline/pulse-timeline-types';
import { PULSE_LOCAL_URL } from 'pulse-commons/helpers';
import React, { forwardRef, useMemo, useRef } from 'react';
import mergeRefs from 'react-merge-refs';
import taskData from '../../../mock/task-import-data.json';
import { formatDate } from '../../TimelineGantt/column-helpers';
import IMPORT_TASKS_JQ from '../constants/importTasksJQ';
import { filterInCompleteTicket } from '../helpers';
import { TaskTimeline } from '../models/task-model';
import styles from './task-grid.module.scss';

const columns: BryntumColumnConfig = [
  {
    text: 'Task ID',
    field: 'id',
    align: 'center',
    editor: false,
    enableHeaderContextMenu: true,
  },
  {
    text: 'Task Title',
    field: 'title',
    type: 'tree',
    align: 'center',
    editor: false,
    width: 150,
    cellCls: styles['cell-task-title'],
    enableHeaderContextMenu: true,
  },
  {
    text: 'Task Status',
    field: 'status',
    align: 'center',
    editor: false,
    width: 150,
    enableHeaderContextMenu: true,

    renderer: ({ record: task }) => {
      return (
        <Box
          px={2}
          py={'2px'}
          borderRadius={10}
          bgcolor={task?.status?.colour}
          color={'white'}
          fontSize={11}
          minWidth={125}
          textAlign={'center'}
        >
          {task?.status?.title}
        </Box>
      );
    },
  },
  {
    text: 'Start Date',
    field: 'startDate',
    align: 'center',
    editor: false,
    enableHeaderContextMenu: true,

    renderer: ({ record: task }) => task?.startDate && formatDate(task.startDate),
  },
  {
    text: 'End Date',
    field: 'endDate',
    type: 'date',
    align: 'center',
    editor: false,
    enableHeaderContextMenu: true,
    renderer: ({ record: task }) => task?.endDate && formatDate(task.endDate),
  },
  {
    text: 'Linked Item',
    align: 'center',
    editor: false,
    enableHeaderContextMenu: true,
    renderer: ({ record: task }) => {
      if (task?.linkItem) {
        return (
          <div>
            <i className="fa-light fa-chart-gantt"></i> {task?.linkItem}
          </div>
        );
      }
    },
  },
  {
    text: 'Assigned to Task',
    field: 'assignedTo',
    align: 'center',
    enableHeaderContextMenu: true,
    editor: false,
  },
];

const TaskGrid = forwardRef<
  any,
  {
    mockData?: boolean;
    selectionChange?: (selection: Array<any>) => void;
    project: PulseTimelineGanttProps['project'];
    onAfterRequest?: (data) => void;
    data?: Record<string, any>[];
  }
>(({ mockData = false, selectionChange, project, onAfterRequest, data }, gridRef) => {
  const gridRefInner = useRef<any>(null);

  const gridConfig = useMemo(
    () => ({
      columns,
      treeFeature: true,
      searchFeature: true,
      headerMenuFeature: true,
      selectionMode: {
        includeChildren: true,
      },
      maxHeight: 400,
      listeners: {
        selectionChange: ({ selection }) => {
          if (selectionChange) {
            selectionChange(selection);
          }
        },
        // fixing issue clear record when select parent tree
        beforeSelectionChange: undefined,
      },
      loadMask: 'loading task...',
    }),
    [],
  );

  const readData = useMemo(() => {
    let tasksImport = data;
    if (mockData) {
      tasksImport = taskData;
    }
    return !!tasksImport?.length
      ? { data: tasksImport }
      : {
          readUrl: `${PULSE_LOCAL_URL}/v2/api/tickets`,
          autoLoad: true,
          params: {
            'filter[jobid]': project?.jobid,
            sort: 'custom',
            'filter[parent_id][]': 0,
            jq: IMPORT_TASKS_JQ,
            // comments for fetch all task: include completed task
            //'filter[incompleteTickets]': 1,
          },
        };
  }, [mockData, data]);

  const AJAX_STORE_CONFIG = useMemo(() => {
    return {
      ...readData,
      modelClass: TaskTimeline,
      listeners: {
        beforeRequest: event => {
          if (!project?.jobid) {
            event.source?.destroy();
          }
        },
        afterRequest: ({ data: responseData }) => {
          onAfterRequest?.(responseData);
          if (!gridRefInner?.current) {
            return;
          }
          // ignore complete tickets
          const { gridInstance } = gridRefInner?.current;
          gridInstance.store.filter(filterInCompleteTicket);
        },
      },
    };
  }, [data]);

  return (
    <div className={styles['task-import-grid']}>
      {useMemo(
        () => (
          <PulseBryntumGrid
            ref={mergeRefs([gridRefInner, gridRef])}
            gridProps={gridConfig}
            selectable
            ajaxStoreConfig={AJAX_STORE_CONFIG}
            paginate={false}
          />
        ),
        [],
      )}
    </div>
  );
});

export default TaskGrid;
