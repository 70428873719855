import Accordion from '@mui/material/Accordion';
import React, { ReactElement } from 'react';
import { PulseAccordionProps } from './pulse-accordion-types';
import styles from './pulse-accordion.module.scss';
import clsx from 'clsx';

export const PulseAccordion = (props: PulseAccordionProps): ReactElement => {
  const { children, classes, ...restProps } = props;

  return (
    <div className={styles.pulseAccordionRoot}>
      <Accordion
        data-testid="accordionRoot"
        disableGutters
        square
        {...restProps}
        classes={{ ...classes, root: clsx(styles.accordionRoot, classes?.root) }}
      >
        {children}
      </Accordion>
    </div>
  );
};

export default PulseAccordion;
