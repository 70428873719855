import React, { FC, ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import { PulseSelectBasePropTypes, PulseSelectOptionType, PulseSelectPropTypes } from '../base/pulse-select-base-types';
import { ValueType } from 'react-select';
import projectStatusOptions from 'pulse-commons/project-status-options';
import PulseProjectStatusOption from './components/pulse-project-status-option';
import { OptionProps } from 'react-select';
import isFunction from 'lodash/isFunction';

const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = 'Filter by project status';

export const PulseProjectStatusSelect = React.memo(
  (props: PulseSelectPropTypes): ReactElement => {
    const {
      value = [],
      isMulti = false,
      url,
      requestFilterKey = 'statusId',
      valueChangeHandler,
      labelName = 'Project Status',
      placeholder = DEFAULT_PLACEHOLDER,
    } = props;

    const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = (
      value: ValueType<PulseSelectOptionType>,
    ): void => {
      isFunction(valueChangeHandler) &&
        valueChangeHandler({
          [requestFilterKey]: value,
        });
    };

    return (
      <PulseSelectBase
        dataStructure={null}
        changeHandler={handleValueChange}
        components={{
          Option: PulseProjectStatusOption as FC<OptionProps<PulseSelectOptionType>>,
        }}
        isMulti={isMulti}
        labelName={labelName}
        placeholder={placeholder}
        staticOptions={projectStatusOptions}
        url={url}
        value={value}
        {...props}
      />
    );
  },
);

PulseProjectStatusSelect.displayName = 'PulseProjectStatusSelect';

export default PulseProjectStatusSelect;
