import React, { ReactElement, useState, useEffect } from 'react';
import { PulseTabsProps, TabPanelProps } from './pulse-tabs-types';
import PulseIcon from '../pulse-icons/pulse-icons';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from './pulse-tabs.module.scss';
import Scrollbar from 'react-scrollbars-custom';
import clsx from 'clsx';
import { ElementPropsWithElementRef } from 'react-scrollbars-custom/dist/types/types';
import { PulseTabsTheme } from 'pulse-commons/types';

const trackYRenderer = (props: ElementPropsWithElementRef<HTMLDivElement>) => {
  const { elementRef, style, ...restProps } = props;
  const customStyle = {
    ...style,
    width: 4,
  };
  return <span {...restProps} ref={elementRef} style={customStyle} className="trackY" />;
};

const TabPanel = (props: TabPanelProps): ReactElement => {
  const { children, value, index, classes, keepChildren = false } = props;

  return (
    <div role="tabpanel" hidden={value !== index} className={classes?.root}>
      <Scrollbar
        removeTracksWhenNotUsed
        trackYProps={{
          renderer: trackYRenderer,
        }}
      >
        {(value === index || keepChildren) && children}
      </Scrollbar>
    </div>
  );
};

export const PulseTabs = (props: PulseTabsProps): ReactElement => {
  const {
    classes = { root: '' },
    TabsProps = {
      variant: 'scrollable',
    },
    tabData,
    onChangeTab,
    selectedTab = 0,
    theme = PulseTabsTheme.dark,
    variant = 'default',
    keepMounted = false,
    orientation,
  } = props;
  const [activeId, setActiveId] = useState(selectedTab);
  const [indexesRendered, setIndexesRendered] = useState<number[]>([]);

  const isVertical = orientation === 'vertical';

  useEffect(() => {
    setActiveId(selectedTab);
  }, [selectedTab]);

  /**
   * Append custom classes props to root container
   */
  const rootClasses = clsx([
    styles['pulse-tabs__root'],
    classes?.root,
    theme === 'light' && styles['pulse-tabs__light'],
    variant === 'v2' && styles['v2-variant'],
  ]);

  const verticalRootClasses = clsx([
    styles['pulse-vertical-tabs__root'],
    classes?.root,
    theme === 'light' && styles['pulse-vertical-tabs__light'],
    variant === 'v2' && styles['v2-variant'],
  ]);

  /**
   * Internal styling classes that PulseTabs component appends to the @mui/material/Tabs Component
   */
  const { root: tabsRoot, indicator, flexContainer, ...tabsClassesRest } = TabsProps?.classes || {};

  const tabsClasses = {
    root: clsx(styles['pulse-tabs__tabs-root'], tabsRoot),
    indicator: clsx(styles['pulse-tabs__indicator'], indicator),
    flexContainer: clsx(styles['pulse-tabs__flexContainer'], flexContainer),
    ...tabsClassesRest,
  };

  const verticalTabsClasses = {
    root: styles['pulse-vertical_tabs__tab-root'],
    indicator: clsx(styles['pulse-vertical-tabs__indicator'], indicator),
    flexContainer: clsx(styles['pulse-vertical-tabs__flexContainer'], flexContainer),
    ...tabsClassesRest,
  };

  const handleChange: TabsProps['onChange'] = (event, value): void => {
    setActiveId(value);
    onChangeTab && onChangeTab(event, value);
  };

  /**
   * @const tabsHeaders - array of react nodes/components to use for the tab navigation
   * @const tabsPanels - array of react nodes/components contain actual content for the tab navigation
   */
  const tabsHeaders: React.ReactNode[] = [],
    tabsPanels: React.ReactNode[] = [];

  /**
   * Loop through the tabData and add the tab header to tabsHeaders
   * and the content to tabsPanels
   */
  tabData.forEach((tab, index) => {
    const {
      id,
      tabLabel,
      tabContent,
      customIconComponent,
      PulseIconProps,
      TabProps,
      TabPanelProps,
      ...tabDataPropsRest
    } = tab;

    if (keepMounted && index === activeId && !indexesRendered.includes(activeId)) {
      const indexesRenderedUpdate: number[] = [...indexesRendered];
      indexesRenderedUpdate.push(activeId);
      setIndexesRendered(indexesRenderedUpdate);
    }

    /**
     * Destructure the classes API because we want to apply some internal styling
     */
    const { classes: TabClasses, ...restTabProps } = TabProps || {};

    /**
     * Internal styling classes that PulseTabs component appends to the @mui/material/Tab Component
     */
    const { root: tabRoot, selected, labelIcon, wrapped, ...tabClassesRest } = TabClasses || {};

    const tabClasses = {
      root: clsx(styles['pulse-tabs__tab-root'], tabRoot),
      selected: clsx(styles['pulse-tabs__tab-root--selected'], selected),
      labelIcon: clsx(styles['pulse-tabs__label-icon'], labelIcon),
      wrapped: clsx(styles['pulse-tabs__tab-wrapper'], wrapped),
      ...tabClassesRest,
    };

    const verticalTabClasses = {
      root: clsx(styles['pulse-vertical-tabs__tab-root'], tabRoot),
      selected: clsx(styles['pulse-vertical-tabs__tab-root--selected'], selected),
      labelIcon: clsx(styles['pulse-vertical-tabs__label-icon'], labelIcon),
      wrapped: clsx(styles['pulse-vertical-tabs__tab-wrapper'], wrapped),
      ...tabClassesRest,
    };

    /**
     * Destructure the classes API because we want to apply some internal styling
     */
    const { classes: TabPanelClasses, ...restTabPanelProps } = TabPanelProps || {};

    /**
     * Internal styling classes that PulseTabs component appends to the @mui/material/TabPanel Component
     */
    const tabPanelClasses = {
      root: clsx(styles['pulse-tabs__tab-panel-root'], TabPanelClasses?.root),
      content: clsx(styles['pulse-tabs__tab-panel-content'], TabPanelClasses?.content),
    };

    const verticalTabPanelClasses = {
      root: clsx(styles['pulse-vertical-tabs__tab-panel-root'], TabPanelClasses?.root),
      content: clsx(styles['pulse-vertical-tabs__tab-panel-content'], TabPanelClasses?.content),
    };

    /**
     * Render a custom icon component customIconComponent if provided
     * Else if PulseIconProps is provided, then display a PulseIcon
     * If not, then only a label will show
     */
    const tabIcon = customIconComponent
      ? customIconComponent
      : PulseIconProps && <PulseIcon size={PulseIconProps.size} classes={PulseIconProps.classes} iconName={''} />;
    tabsHeaders.push(
      <Tab
        disableRipple
        key={id}
        label={tabLabel}
        icon={tabIcon}
        wrapped
        classes={isVertical ? verticalTabClasses : tabClasses}
        {...restTabProps}
        {...tabDataPropsRest}
      />,
    );

    tabsPanels.push(
      <TabPanel
        key={id}
        value={activeId}
        index={index}
        classes={isVertical ? verticalTabPanelClasses : tabPanelClasses}
        keepChildren={indexesRendered.includes(index)}
        {...restTabPanelProps}
      >
        {typeof tabContent === 'string' && (
          <div className={tabPanelClasses.content} dangerouslySetInnerHTML={{ __html: tabContent }}></div>
        )}
        {typeof tabContent !== 'string' && <div className={tabPanelClasses.content}>{tabContent}</div>}
      </TabPanel>,
    );
  });

  return (
    <div className={isVertical ? verticalRootClasses : rootClasses} data-testid="pulse-tabs__root">
      <Tabs
        value={activeId}
        onChange={handleChange}
        aria-label="pulse tab"
        {...TabsProps}
        classes={isVertical ? verticalTabsClasses : tabsClasses}
      >
        {tabsHeaders}
      </Tabs>
      {tabsPanels}
    </div>
  );
};

export default PulseTabs;
