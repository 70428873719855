import {
  AsyncSelectOptionType,
  AsyncSelectReturnTypes,
  PulseSelectArgsStructureGroupTypes,
  PulseSelectDataStructureTypes,
  PulseSelectOptionGroupTypes,
  PulseSelectOptionType,
} from './base/pulse-select-base-types';
import get from 'lodash/get';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { v2Endpoint } from 'pulse-api/base';
import { ResourcesType } from 'pulse-api/group-options/group-options-types';

export const optionTransformer = ({
  dataArray,
  dataStructure: { value, label },
}: {
  dataArray: Record<string, unknown>[];
  dataStructure: PulseSelectDataStructureTypes;
}): AsyncSelectReturnTypes['options'] => {
  return dataArray.map((data: { [index: string]: any }) => {
    return {
      value: get(data, value),
      label: get(data, label),
      raw: data,
    };
  });
};

const DEFAULT_PARAM = {
  page: {
    number: 1,
    per_page: 100,
  },
};

export const groupOptions = async (
  argsStructure: PulseSelectArgsStructureGroupTypes,
): Promise<PulseSelectOptionGroupTypes[]> => {
  const {
    cancelToken,
    extraParams,
    url,
    identifierKey,
    groupTitleKey,
    optionTitleKey,
    searchKey,
    searchValue = '',
  } = argsStructure;

  try {
    const queryData = {
      ...DEFAULT_PARAM,
      ...extraParams,
    };

    if (searchKey) {
      if (searchValue) {
        queryData['filter'][searchKey] = searchValue;
      } else {
        delete queryData['filter'][searchKey];
      }
    }

    const response: AxiosResponse<{ data: PulseSelectOptionType[] }> = await v2Endpoint.get(
      `${url}${qs.stringify(queryData, { addQueryPrefix: true })}`,
      {
        cancelToken: cancelToken,
        withCredentials: true,
      },
    );
    const options: PulseSelectOptionGroupTypes[] = [];

    response.data.data.forEach(data => {
      const { id, attributes } = data;
      const groupLabel = attributes[groupTitleKey];
      const optionLabel = attributes[optionTitleKey];
      const key = identifierKey ? attributes[identifierKey] : groupLabel;
      const option = {
        label: optionLabel,
        value: id,
        raw: attributes,
      };
      const indexGroup = options.findIndex(option => option.key === key);

      if (indexGroup === -1) {
        options.push({
          label: groupLabel,
          options: [option],
          key,
        });
      } else {
        options[indexGroup].options.push(option);
      }
    });

    return options;
  } catch (error) {
    return [];
  }
};

export const groupOptionRequestTransformer = (requests: ResourcesType[], search = ''): ResourcesType[] => {
  return requests.map(request => {
    const { url, filterKey = 'title', perPage = 10, extraParams = {} } = request;
    const params = {
      'page[per_page]': perPage,
      [`filter[${filterKey}]`]: search,
      ...extraParams,
    };

    const transformParams = Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    const urlFilter = `${url}?${transformParams}`;
    return { ...request, url: urlFilter };
  });
};

export const handlerSearch = (option: AsyncSelectOptionType, search: string): string[] | null =>
  option.label.match(new RegExp(search, 'gi'));
