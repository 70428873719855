import React, { ReactElement, useEffect, useState } from 'react';
import { TimesheetErrorReportPropTypes } from './timesheet-error-report-types';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styles from './timesheet-error-report.module.scss';
import PulseProjectUsersSelect from '../pulse-select/pulse-users/pulse-users-select';
import DateRangePicker from '../pulse-date-picker/pulse-datepicker';
import PulseSearch from '../search/pulse-search';
import { isMobileDevice, transformQueryParams } from 'pulse-commons/helpers';
import qs from 'qs';
import TimesheetErrorReportSection from './components/sections/timesheet-error-report-section';
import { InputTypes } from '../search/pulse-search-types';
import { IconSizes, Colors } from 'pulse-commons/types';
import Icon from '../pulse-icons/pulse-icons';
import { TimesheetErrorReportStateTypes } from './timesheet-error-report-types';
import { ThunkDispatch } from 'redux-thunk';
import {
  archiveTimesheetErrorItem,
  clearFilters,
  exportTimesheetErrorReport,
  fetchTimesheetErrorItems,
  fetchTimesheetErrorTypes,
  resubmitTimesheetErrorItem,
  toggleTimesheetErrorItem,
} from './store/actions';
import { FILTERING_TIMESHEET_ERROR_ITEMS } from './store/action-definitions';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { TimesheetErrorReportDispatchTypes } from './timesheet-error-report-types';
import {
  RootReducedState,
  TimesheetErrorReportActionType,
  TimesheetErrorReportStoreStateType,
  Filters,
} from './store/types';
import { TimesheetErrorReportSectionPropsType } from './components/sections/timesheet-error-report-section-type';
import PCSButtonBase from '../button/base/pcs-button-base';
import PulseIconButton from '../pulse-icon-button/pulse-icon-button';
import Checkbox from '../checkbox/checkbox';

import { Provider } from 'react-redux';
import TimesheetErrorReportStore from './store/timesheet-error-report-store';

const TimesheetErrorReportActions = (props: {
  disableBulkArchive?: boolean;
  disableBulkResubmit?: boolean;
  disableExport?: boolean;
  bulkResubmit(): void;
  bulkArchive(): void;
  exportReport(): void;
}): ReactElement => {
  const {
    disableBulkArchive = true,
    disableBulkResubmit = true,
    disableExport = false,
    bulkResubmit,
    bulkArchive,
    exportReport,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleExportReport = (): void => {
    exportReport();
    setAnchorEl(null);
  };
  const handleBulkResubmit = (): void => {
    bulkResubmit();
    setAnchorEl(null);
  };
  const handleBulkArchive = (): void => {
    bulkArchive();
    setAnchorEl(null);
  };

  return !isMobileDevice() ? (
    <>
      <PCSButtonBase disabled={disableBulkArchive} clickHandler={bulkArchive}>
        <Icon
          classes={{
            icon: 'fal fa-archive',
          }}
          iconName=""
          size={IconSizes.lg}
        />
        Bulk Archive
      </PCSButtonBase>
      <PCSButtonBase disabled={disableBulkResubmit} clickHandler={bulkResubmit}>
        <Icon
          classes={{
            icon: 'fal fa-redo',
          }}
          iconName=""
          size={IconSizes.lg}
        />
        Bulk Resubmit
      </PCSButtonBase>
      <PCSButtonBase disabled={disableExport} clickHandler={exportReport}>
        <Icon
          classes={{
            icon: 'fal fa-file-export',
          }}
          iconName=""
          size={IconSizes.lg}
        />
        Export
      </PCSButtonBase>
    </>
  ) : (
    <>
      <PulseIconButton
        classes={{
          pulseIcon: {
            icon: 'material-icons',
          },
        }}
        className={[styles['timesheet-error-report__action--menu']]}
        color={Colors.default}
        iconName="menu"
        size={IconSizes.lg}
        handleClick={handleClick}
      />
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleExportReport}>
          <Icon
            classes={{
              icon: 'material-icons',
            }}
            iconName="import_export"
            size={IconSizes.lg}
          />
          Export
        </MenuItem>
        <MenuItem onClick={handleBulkResubmit} disabled={disableBulkResubmit}>
          <Icon
            classes={{
              icon: 'material-icons',
            }}
            iconName="refresh"
            size={IconSizes.lg}
          />
          Bulk Resubmit
        </MenuItem>
        <MenuItem onClick={handleBulkArchive} disabled={disableBulkArchive}>
          <Icon
            classes={{
              icon: 'material-icons',
            }}
            iconName="archive"
            size={IconSizes.lg}
          />
          Bulk Archive
        </MenuItem>
      </Menu>
    </>
  );
};

/**
 * Function to retrieve and merge the timesheet error items id from the selected object
 * @param selected
 */
const getSelectedTimesheetErrorIds = (selected: TimesheetErrorReportStoreStateType['selected']): number[] => {
  return Object.values(selected).reduce((acc, value) => {
    return acc.concat(value);
  }, []);
};

/**
 * Function to retrieve and merge the timesheet error types id from the selected object
 * This is required so we can do a request to refresh specific error type section
 * @param selected
 */
const getSelectedTimesheetErrorTypeIds = (selected: TimesheetErrorReportStoreStateType['selected']): number[] => {
  return Object.keys(selected).reduce((acc: number[], value: string) => {
    return acc.concat(parseInt(value));
  }, []);
};

/**
 * Function to return the count of selected timesheet error items
 * This is used to enable disabled certain UI elements such as bulk archive, bulk resubmit
 * @param selected
 */
const getTotalSelectedObjectCount = (selected: TimesheetErrorReportStoreStateType['selected']): number => {
  return getSelectedTimesheetErrorIds(selected).length;
};

/**
 * Start of the Timesheet Error Report App
 * @param props
 */
const TimesheetErrorReport = (props: TimesheetErrorReportPropTypes): ReactElement => {
  const {
    agressoInstance,
    archiveTimesheetErrorItem,
    clearAppliedFilters,
    fetchTimesheetErrorItems,
    fetchTimesheetErrorTypes,
    handleFilterChange,
    resubmitTimesheetErrorItem,
    timesheetErrorReport, // State
    toggleTimesheetErrorItem,
    v1Url,
  } = props;

  /**
   * State of the Timesheet Error Report
   * These state items are used to control certain UI elements as well
   * as allowing selective refreshing of timesheet error types section.
   *
   * Instead of refreshing the whole app, we can refresh specific
   * timesheet error types.
   */
  const [errorReportState, setErrorReportState] = useState({
    selectedErrorsCount: getTotalSelectedObjectCount(timesheetErrorReport?.selected || []),
    selectedErrorTypes: getSelectedTimesheetErrorTypeIds(timesheetErrorReport?.selected || []),
    selectedErrors: getSelectedTimesheetErrorIds(timesheetErrorReport?.selected || []),
  });

  useEffect(() => {
    setErrorReportState({
      selectedErrorsCount: getTotalSelectedObjectCount(timesheetErrorReport?.selected || []),
      selectedErrorTypes: getSelectedTimesheetErrorTypeIds(timesheetErrorReport?.selected || []),
      selectedErrors: getSelectedTimesheetErrorIds(timesheetErrorReport?.selected || []),
    });
  }, [timesheetErrorReport?.selected]);

  /**
   * The sections state is an array of TimesheetErrorReportSections
   * component that needs to be rendered. As you will see later, we
   * loop through the error types to generate the sections.
   */
  const [sections, setSections] = useState<ReactElement[]>([]);

  const [disableFilterButtons] = useState(false);

  /**
   * Initial state for the dialog to confirm bulk
   * archive and bulk resubmit
   */
  const dialogInitialState = {
    open: false,
    dialogTitle: '',
    dialogMessage: '',
    dialogConfirmAction: (): void => {
      return;
    },
  };

  const [dialogState, setDialogState] = useState(dialogInitialState);

  /**
   * Function to loop through all the timesheet error types and fetch
   * the error items belonging to that error type.
   */
  const __fetchAllTimesheetErrorItems = ({ params }: { params: any } = { params: {} }): void => {
    /** merge the supplied params with the applied filters in the store */
    params = { filter: { ...params.filter }, ...params };

    if (timesheetErrorReport?.appliedFilters) {
      params = {
        filter: {
          ...timesheetErrorReport?.appliedFilters,
          ...params.filter,
        },
        ...params,
      };
    }

    /** contruct a query string */
    const queryString = transformQueryParams(params, 'brackets');
    /** append above query string to the url to allow for deep linking */
    window.history.pushState('', '', `${window.location.pathname}?${queryString}`);

    /** loop through the error types and request the error items */
    (timesheetErrorReport?.timesheetErrorTypes || []).map(timesheetErrorType => {
      fetchTimesheetErrorItems(timesheetErrorType.id, params);
    });
  };

  /**
   * Function to handle clicking on load more button for each section.
   * This fires a request to fetch the page number and applied filters supplied
   */
  const handleLoadMoreItems: TimesheetErrorReportSectionPropsType['loadMoreItems'] = ({ errorTypeId, page }): void => {
    fetchTimesheetErrorItems(errorTypeId, {
      page,
      filter: timesheetErrorReport?.appliedFilters || {},
    });
  };

  /** @todo move this to a deeplink filter HOC */
  const checkForURLParams = (): Record<string, any> => {
    const deepLinkParams = qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true });
    return deepLinkParams;
  };

  /** This is where the application starts, by setting initial filters and fetching
   * the different timesheet error types.
   */
  useEffect(() => {
    /** This sets the current logged in user's office id as the userofficeid
     * This means that the initial load will already be filtering the timesheet
     * error items based on this userofficeid and show only errors where the user
     * submitting the timesheet belongs to that specific officeid.
     */
    /** DEMO-START */
    if (timesheetErrorReport) {
      timesheetErrorReport.timesheetErrorTypes = [];
    }
    /** DEMO-END */
    if (agressoInstance) {
      fetchTimesheetErrorTypes(agressoInstance);
    } else {
      console.log(`props agressoInstance is missing.`);
    }
  }, []);

  /**
   * The next step fetches the timesheet error items if the timesheet error types
   * is set in the redux store
   */
  useEffect(() => {
    timesheetErrorReport?.timesheetErrorTypes.length && __fetchAllTimesheetErrorItems({ params: checkForURLParams() });
  }, [timesheetErrorReport?.timesheetErrorTypes.length]);

  /**
   * When fetching the error types is complete and the redux store
   * has been populated, loop through each of them, create a
   * TimesheetErrorReportSection component and pass the section
   * data to the component.
   */
  useEffect(() => {
    const sectionComponents: ReactElement[] = [];
    const { timesheetErrorTypes } = timesheetErrorReport || {};
    timesheetErrorTypes &&
      timesheetErrorTypes.length &&
      timesheetErrorTypes.map(timesheetErrorType => {
        sectionComponents.push(
          <TimesheetErrorReportSection
            key={timesheetErrorType.id}
            title={timesheetErrorType.attributes.error_type}
            errorTypeId={timesheetErrorType.id}
            loadMoreItems={handleLoadMoreItems}
            timesheetErrorReportSectionDetails={timesheetErrorType}
            toggleTimesheetErrorItem={toggleTimesheetErrorItem}
            resubmitTimesheetErrorItem={resubmitTimesheetErrorItem}
          />,
        );
      });
    setSections(sectionComponents);
  }, [timesheetErrorReport?.timesheetErrorTypes]);

  /**
   * Handle click on the show archived filter checkbox
   */
  const handleShowArchived = (e: any): void => {
    handleFilterChange({
      showArchived: e.currentTarget.checked,
    });
  };

  /**
   * Handle closing the bulk confirmation dialog
   */
  const handleClose = (): void => {
    setDialogState(dialogInitialState);
  };

  /**
   * Handle confirming bulk resubmit operation
   * This function is passed to the dialog component state
   * in the function below.
   */
  const confirmBulkResubmit = (): void => {
    resubmitTimesheetErrorItem(errorReportState.selectedErrorTypes, errorReportState.selectedErrors);
    setDialogState(dialogInitialState);
  };

  /**
   * Handle click on the Bulk Resubmit button
   * This set the confirmation dialog component to
   * render the right text and to perform the resubmit
   * request on confirmation.
   */
  const bulkResubmit = (): void => {
    setDialogState({
      open: true,
      dialogTitle: 'Bulk resubmit',
      dialogMessage: `You are about to resubmit ${errorReportState.selectedErrorsCount} timesheet errors.`,
      dialogConfirmAction: confirmBulkResubmit,
    });
  };

  /**
   * Handle confirming bulk archive operation
   * This function is passed to the dialog component state
   * in the function below.
   */
  const confirmBulkArchive = (): void => {
    archiveTimesheetErrorItem(errorReportState.selectedErrorTypes, errorReportState.selectedErrors);
    setDialogState(dialogInitialState);
  };

  /**
   * Handle click on the Bulk Archive button
   * This set the confirmation dialog component to
   * render the right text and to perform the resubmit
   * request on confirmation.
   */
  const bulkArchive = (): void => {
    setDialogState({
      open: true,
      dialogTitle: 'Bulk archive',
      dialogMessage: `You are about to archive ${
        timesheetErrorReport?.selected ? Object.keys(timesheetErrorReport?.selected).length : 0
      } timesheet errors.`,
      dialogConfirmAction: confirmBulkArchive,
    });
  };

  /**
   * Handle click on the Export button
   * Performs a request to a v1 endpoint
   */
  const exportReport = (): void => {
    const url = `${v1Url}reports.php?action=custom_reports&custom_report_id=41&method=export&instance=${agressoInstance}&is_v2=1`;
    window.open(url, '_blank');
  };

  /**
   * Handle click on the apply filter button
   */
  const applyFilters = (): void => {
    const params = {
      filter: timesheetErrorReport?.appliedFilters || {},
    };
    __fetchAllTimesheetErrorItems({
      params,
    });
  };

  /**
   * Handle click on the reset filters button
   */
  const clearFilters = (): void => {
    clearAppliedFilters({
      appliedFilters: {
        instance: agressoInstance,
      },
    });
    __fetchAllTimesheetErrorItems({ params: { filter: { instance: agressoInstance } } });
    window.history.pushState('', '', `${window.location.pathname}`);
  };

  const handleDatePickerChange = (value): void => {
    if (
      timesheetErrorReport?.appliedFilters?.startDate === value.values.startDate &&
      timesheetErrorReport?.appliedFilters?.endDate === value.values.endDate
    ) {
      return;
    }
    handleFilterChange(value.values);
  };

  return (
    <>
      <Card
        variant="outlined"
        classes={{
          root: styles['timesheet-error-report__panel'],
        }}
      >
        <CardHeader
          classes={{
            root: styles['timesheet-error-report__panel-header'],
            action: styles['timesheet-error-report__panel-action'],
          }}
          action={
            timesheetErrorReport?.timesheetErrorTypes?.length ? (
              <TimesheetErrorReportActions
                disableBulkArchive={!errorReportState.selectedErrorsCount}
                disableBulkResubmit={!errorReportState.selectedErrorsCount}
                bulkResubmit={bulkResubmit}
                bulkArchive={bulkArchive}
                exportReport={exportReport}
              />
            ) : (
              false
            )
          }
          title="Timesheet Submission Errors"
        ></CardHeader>
        <Divider />
        <CardContent>
          <Paper
            classes={{
              root: styles['timesheet-error-report__filters'],
            }}
            square={true}
            variant={'outlined'}
          >
            {
              <Grid container spacing={2}>
                <Grid item xs={12} md={1}>
                  {/* @todo write a HOC to pass the applied filters to the children and the event handler */}
                  <PulseSearch
                    labelText="Search Submission ID"
                    onChangeHandler={handleFilterChange}
                    type={InputTypes.number}
                    presetValue={timesheetErrorReport?.appliedFilters.search || ''}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <PulseProjectUsersSelect
                    labelName="Timesheet Submission User"
                    valueChangeHandler={handleFilterChange}
                    value={timesheetErrorReport?.appliedFilters.userId || []}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DateRangePicker
                    onChange={handleDatePickerChange}
                    DatepickerProps={{
                      variant: 'input',
                      range: true,
                      PulseDatepickerCalendarProps: {
                        enableReset: true,
                      },
                    }}
                    values={{
                      startDate: timesheetErrorReport?.appliedFilters.startDate
                        ? new Date(timesheetErrorReport?.appliedFilters.startDate)
                        : null,
                      endDate: timesheetErrorReport?.appliedFilters.endDate
                        ? new Date(timesheetErrorReport?.appliedFilters.endDate)
                        : null,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={styles['timesheet-error-report__filters-checkbox-ctn']}>
                    <Checkbox
                      key="showarchived"
                      checked={timesheetErrorReport?.appliedFilters.showArchived}
                      className={[styles['timesheet-error-report__filters-checkbox']]}
                      label="Include archived errors"
                      labelPlacement="right"
                      onClick={handleShowArchived}
                      showLabel={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} className={styles['timesheet-error-report__filters-action-ctn']}>
                  <PCSButtonBase
                    label="Apply"
                    color={Colors.primary}
                    disabled={disableFilterButtons}
                    clickHandler={applyFilters}
                  ></PCSButtonBase>
                  <PCSButtonBase
                    label="Reset filters"
                    color={Colors.default}
                    disabled={disableFilterButtons}
                    clickHandler={clearFilters}
                  ></PCSButtonBase>
                </Grid>
              </Grid>
            }
            <Typography variant="h5">No timesheet error type found</Typography>
          </Paper>
          <div className="uk-margin-top">{sections}</div>
        </CardContent>
      </Card>
      <Dialog
        open={dialogState.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6">{dialogState.dialogTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">{dialogState.dialogMessage}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={dialogState.dialogConfirmAction} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootReducedState, ownProps: RootReducedState): TimesheetErrorReportStateTypes => {
  return {
    timesheetErrorReport: state.timesheetErrorReport,
    agressoInstance: ownProps.agressoInstance,
    currentUserOffice: ownProps.currentUserOffice,
    v1Url: ownProps.v1Url,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<TimesheetErrorReportStoreStateType, void, Action<string>>,
): TimesheetErrorReportDispatchTypes => ({
  archiveTimesheetErrorItem: (errorTypeIds, submissionIds): void =>
    dispatch(archiveTimesheetErrorItem(errorTypeIds, submissionIds)),
  clearAppliedFilters: (appliedFilters: Filters): void => dispatch(clearFilters(appliedFilters)),
  exportTimesheetErrorReport: (url): void => dispatch(exportTimesheetErrorReport(url)),
  fetchTimesheetErrorItems: (errorTypeId: number, params?: any): void =>
    dispatch(fetchTimesheetErrorItems(errorTypeId, params)),
  fetchTimesheetErrorTypes: (agressoInstance): void => dispatch(fetchTimesheetErrorTypes(agressoInstance)),
  handleFilterChange: (value: any): TimesheetErrorReportActionType => {
    return dispatch({
      type: FILTERING_TIMESHEET_ERROR_ITEMS,
      payload: {
        filters: {
          ...value,
        },
      },
    });
  },
  resubmitTimesheetErrorItem: (errorTypeIds, submissionIds): void =>
    dispatch(resubmitTimesheetErrorItem(errorTypeIds, submissionIds)),
  toggleTimesheetErrorItem: (errorTypeId, submissionId, selected): TimesheetErrorReportActionType =>
    dispatch(toggleTimesheetErrorItem(errorTypeId, submissionId, selected)),
});

export const ConnectedTimesheetErrorReport = connect(mapStateToProps, mapDispatchToProps)(TimesheetErrorReport);

const TimesheetErrorApp = (props: TimesheetErrorReportPropTypes): ReactElement => {
  return (
    <Provider store={TimesheetErrorReportStore}>
      <ConnectedTimesheetErrorReport {...props} />
    </Provider>
  );
};

export default TimesheetErrorApp;
