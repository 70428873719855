import { DeeplinkFiltersMeta } from 'HOC/deeplink-types';
import isFunction from 'lodash/isFunction';
import qs from 'qs';
import React, { ComponentType } from 'react';

/**
 * Helper function to add the params to the window address URL
 * @param filters
 *
 * A 'me' variable represent for assigned user id
 * @param loggedUserId
 */
const appendFiltersToURL = (filters: Record<string, string | string[]>, filterWithloggedUserId?: string): void => {
  /**
   * @todo add an allowed filters so that we can allow
   * others app to add params and this won't override
   * their params
   */

  const queryString = qs.stringify(
    {
      filter: filters,
    },
    {
      addQueryPrefix: true,
      encode: false,
      filter: (prefix, value) => {
        if (prefix.includes('user.id') && value === filterWithloggedUserId) {
          return 'me';
        }
        return value;
      },
    },
  );

  const location = `${window.location.pathname}${queryString}`;

  window.history.replaceState('', 'Resource Planner', location);
};

/**
 * HOC that will provide the wrapped component with deepLinkFiltersMeta props
 * containing the filters available on page load under window.pulse.request.deepLinks
 * @param WrappedComponent The component to pass the deep link meta filters to
 * @param transformerFn
 * A function to map window.pulse.request.deepLinks to the wrapped component state to ensure that selects are populated by tags.
 * In the wrapped component, you will need to take care of how the `deepLinkFiltersMeta` is handled.
 *
 * @returns
 */

const withDeepLink = (
  WrappedComponent: ComponentType<any> & {
    transformer?: (deepLinks: DeeplinkFiltersMeta<any>) => Record<string, any>[];
  },
  transformerFn?: (deepLinks: DeeplinkFiltersMeta<any>) => Record<string, any>[],
): ComponentType<any> => {
  const currentLocation = window.location;
  const urlParams = qs.parse(currentLocation.search.substring(1));

  const transformerFunction =
    (isFunction(transformerFn) && transformerFn) ||
    (isFunction(WrappedComponent.transformer) && WrappedComponent.transformer);

  delete urlParams.id;
  delete urlParams.viewMode;

  const deepLinks = window.pulse?.request?.deepLinks || {};

  const filtersMeta = transformerFunction ? transformerFunction(deepLinks) : deepLinks;

  const ComponentWithDeepLink = props => <WrappedComponent {...props} deepLinkFiltersMeta={filtersMeta} />;

  ComponentWithDeepLink.displayName = `withDeepLink(${
    WrappedComponent.displayName || WrappedComponent.name || 'Components'
  })`;
  return ComponentWithDeepLink;
};

withDeepLink.appendFiltersToURL = appendFiltersToURL;

export default withDeepLink;
