import clsx from 'clsx';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import { ButtonSizes, ButtonVariants, Colors, IconSizes } from 'pulse-commons/types';
import React, { ReactElement } from 'react';
import { PulseUnsupportedViewerProps } from './pulse-unsupported-viewer-types';
import styles from './pulse-unsupported-viewer.module.scss';

export const PulseUnsupportedViewer = (props: PulseUnsupportedViewerProps): ReactElement => {
  const { classes, onDownload, onOpenOfficeOnline, showOfficeOnline = false } = props;

  return (
    <div
      data-testid="pulse-unsupported-viewer"
      className={clsx(styles['pulse-asset-viewer__unsupported'], classes?.root)}
    >
      <PulseIcon
        iconName=""
        classes={{
          icon: clsx('fal fa-exclamation-circle', styles.icon),
        }}
      />
      <span className={clsx(styles['pulse-asset-viewer__unsupported--title'], classes?.title)}>
        This document cannot be previewed
      </span>
      <span className={clsx(styles['pulse-asset-viewer__unsupported--description'], classes?.description)}>
        Please download file to view the content.
      </span>
      <div className={clsx(styles['pulse-asset-viewer__unsupported--buttonCtn'], classes?.buttonCtn)}>
        <PulseButtonBase
          icon
          iconClasses={{
            root: 'pulse-asset-viewer__unsupported--button-icon',
            icon: 'fal fa-download',
          }}
          classes={[styles['pulse-asset-viewer__unsupported--button'], clsx(classes?.downloadBtn)]}
          iconSize={IconSizes.sm}
          sizes={ButtonSizes.md}
          label={'download'}
          onClick={onDownload}
          variant={ButtonVariants.outlined}
          color={Colors.default}
        />
        {showOfficeOnline && (
          <PulseButtonBase
            icon
            iconClasses={{
              root: 'pulse-asset-viewer__unsupported--button-icon',
              icon: 'fab fa-windows',
            }}
            classes={[styles['pulse-asset-viewer__unsupported--button'], clsx(classes?.onlineOfficeBtn)]}
            iconSize={IconSizes.sm}
            sizes={ButtonSizes.md}
            label={'Open office online'}
            onClick={onOpenOfficeOnline}
            variant={ButtonVariants.outlined}
            color={Colors.default}
          />
        )}
      </div>
    </div>
  );
};

export default PulseUnsupportedViewer;
