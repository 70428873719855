/* eslint-disable react/no-children-prop */
import React, { ReactElement, FC, useState, useEffect, useRef, Ref } from 'react';
import PulseButton from '../pulse-button/base/pulse-button-base';
import { Colors, ButtonVariants } from 'pulse-commons/types';
import { PulseEditorProps, PulseEditorDefaultSettings, EditorState } from './pulse-editor-types';
import PulseMenuButton from '../pulse-button/menu/pulse-menu-button';
import debounce from 'lodash/debounce';
import PulseUploader from 'components/pulse-uploader/pulse-uploader';
import './pulse-editor.scss';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import 'tinymce/tinymce.min.js';
// DOM model
import 'tinymce/models/dom/model.min.js';
// Theme
import 'tinymce/themes/silver/theme.min.js';
// Toolbar icons
import 'tinymce/icons/default/icons.min.js';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist/plugin.min.js';
import 'tinymce/plugins/anchor/plugin.min.js';
import 'tinymce/plugins/autolink/plugin.min.js';
import 'tinymce/plugins/autoresize/plugin.min.js';
import 'tinymce/plugins/autosave/plugin.min.js';
import 'tinymce/plugins/charmap/plugin.min.js';
import 'tinymce/plugins/code/plugin.min.js';
import 'tinymce/plugins/codesample/plugin.min.js';
import 'tinymce/plugins/directionality/plugin.min.js';
import 'tinymce/plugins/emoticons/plugin.min.js';
import 'tinymce/plugins/fullscreen/plugin.min.js';
import 'tinymce/plugins/help/plugin.min.js';
import 'tinymce/plugins/image/plugin.min.js';
import 'tinymce/plugins/importcss/plugin.min.js';
import 'tinymce/plugins/insertdatetime/plugin.min.js';
import 'tinymce/plugins/link/plugin.min.js';
import 'tinymce/plugins/lists/plugin.min.js';
import 'tinymce/plugins/media/plugin.min.js';
import 'tinymce/plugins/nonbreaking/plugin.min.js';
import 'tinymce/plugins/pagebreak/plugin.min.js';
import 'tinymce/plugins/preview/plugin.min.js';
import 'tinymce/plugins/quickbars/plugin.min.js';
import 'tinymce/plugins/save/plugin.min.js';
import 'tinymce/plugins/searchreplace/plugin.min.js';
import 'tinymce/plugins/table/plugin.min.js';
import 'tinymce/plugins/template/plugin.min.js';
import 'tinymce/plugins/visualblocks/plugin.min.js';
import 'tinymce/plugins/visualchars/plugin.min.js';
import 'tinymce/plugins/wordcount/plugin.min.js';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis.js';

import { Editor } from '@tinymce/tinymce-react';
import 'tinymce/skins/ui/oxide/skin.min.css';
import { EditorEvent, Events } from 'tinymce';
import { EventHandler } from '@tinymce/tinymce-react/lib/cjs/main/ts/Events';
import isFunction from 'lodash/isFunction';
import clsx from 'clsx';
import { PulseSelectPropTypes } from 'components/pulse-select/base/pulse-select-base-types';
import { isMobileDevice } from 'pulse-commons/helpers';

/**
 * Function to set the font size on the bullet point
 * or the numbers of the list items
 * @param editor
 * @param node The node element which is selected in the editor
 */
const setListItemFontSize = (editor: Editor['editor'], node: HTMLElement) => {
  if (!editor) {
    return;
  }
  /**
   * Nested lists returns LI instead of SPAN
   */
  const nodeEl = node.querySelector('span');
  if (node.nodeName === 'LI' && nodeEl) {
    const nodeFontSize = nodeEl.style.fontSize;
    editor.dom.setStyle(node, 'font-size', nodeFontSize);
  } else {
    /** if SPAN, check if it has an LI parent */
    const nodeLIParent = node.closest('li');
    if (node.nodeName === 'SPAN' && nodeLIParent && nodeLIParent.nodeName === 'LI') {
      const nodeFontSize = node.style.fontSize;
      editor.dom.setStyle(nodeLIParent, 'font-size', nodeFontSize);
    } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
      /**
       * If SPAN is the first one, it will return UL or OL
       * Also if selecting more than 1 line, it will return UL
       */
      const li = editor.dom.select('li', node);
      li.map((el: any) => {
        if (el.querySelector('span')) {
          const nodeFontSize = el.querySelector('span').style.fontSize;
          const nodeParent = el.closest('li');
          editor.dom.setStyle(nodeParent, 'font-size', nodeFontSize);
        }
      });
    }
  }
};

/**
 * These will determine where tinymce looks for the
 * css file for the content of the editor.
 */
const CONTENT_CSS_DEV = 'main.css';

export const DEFAULT_SETTINGS: PulseEditorDefaultSettings = {
  convert_urls: false,
  link_default_target: '_blank',
  font_size_formats: '11px 12px 13px 14px 16px 18px 24px 36px 48px',
  forced_root_block_attrs: {
    style: 'margin: 0px;',
  },
  link_default_protocol: 'https',
  menubar: false,
  paste_preprocess: (plugin: any, args: any) => {
    if (args.content.match('<meta') || args.content.match('<a href')) {
      return false;
    } else {
      const matchRegex = new RegExp('^https?://', 'g');
      args.content.match(matchRegex) &&
        (args.content = `<a target="_blank" href="${args.content}">${args.content}</a>`);
    }
  },
  smart_paste: false,
  placeholder: 'Start typing ...',
  plugins: [
    'advlist',
    'anchor',
    'autolink',
    'help',
    'image',
    'link',
    'media',
    'lists',
    'searchreplace',
    'table',
    'wordcount',
    'emoticons',
  ],
  skin: false,
  /**
   * This is the default for content_css for it to work in
   * development mode.
   */
  content_css: [process.env.NODE_ENV === 'development' ? CONTENT_CSS_DEV : ''],
  icons: '',
  relative_urls: false,
  selector: undefined,
  statusbar: false,
  target: undefined,
  toolbar:
    'fontsize forecolor backcolor | bold italic underline link alignment image media hr | bullist numlist outdent indent | table blockquote | undo redo | removeformat | emoticons',
  toolbar_groups: {
    alignment: {
      icon: 'align-left',
      tooltip: 'Alignment Options',
      items: 'alignleft aligncenter alignright alignjustify',
    },
  },
  toolbar_sticky: false,
  width: '100%',
};

export const PulseEditor: FC<PulseEditorProps> = (props: PulseEditorProps): ReactElement => {
  const {
    classes,
    CancelButtonProps,
    footer = true,
    footerComponent,
    footerExtraComponents,
    initSettings,
    mode = 'read',
    onCancel,
    onEditorChange,
    onSave,
    promptOnCancel,
    SaveButtonProps,
    value = '',
    PulseUploaderProps,
    PulseMenuButtonProps,
    isInvalid,
    isRequired,
    ...rest
  } = props;

  const uploaderCtnEl = useRef(null);
  const extraFooterCtn: Ref<HTMLDivElement> = useRef(null);

  const [editorState, setEditorState] = useState({
    mode,
    value,
    savedValue: value,
  } as EditorState);
  const [isShowFooterDropdown, setIsShowFooterDropdown] = useState(false);

  useEffect(() => {
    let timeOut;
    let resizeWidth;
    if (extraFooterCtn.current) {
      const extraFooterCtnCurrent = extraFooterCtn.current;
      timeOut = setTimeout(() => setIsShowFooterDropdown(childWidth > extraFooterCtnCurrent.offsetWidth));
      const childWidth = calcExtraFooterChildWidth();
      resizeWidth = debounce(() => {
        setIsShowFooterDropdown(childWidth > extraFooterCtnCurrent.offsetWidth);
      }, 500);
      window.addEventListener('resize', resizeWidth);
    }
    return () => {
      timeOut && clearTimeout(timeOut);
      resizeWidth && window.removeEventListener('resize', resizeWidth);
    };
  }, []);

  useEffect(() => {
    setEditorState({
      ...editorState,
      value,
    });
  }, [value]);

  const editorInitSettings = {
    ...DEFAULT_SETTINGS,
    ...initSettings,
  };

  useEffect(() => {
    setEditorState({
      ...editorState,
      value,
    });
  }, [value]);

  useEffect(() => {
    setEditorState({
      ...editorState,
      mode,
    });
  }, [mode]);

  const editContent = (): void => {
    setEditorState({
      ...editorState,
      mode: 'edit',
    });
  };

  const handleEditorChange = (content: any, editor: Editor['editor']): void => {
    setEditorState({
      ...editorState,
      value: content,
    });
    editor && onEditorChange && onEditorChange(content, editor);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>): void | boolean => {
    onCancel && onCancel(e, editorState, setEditorState);
    if (promptOnCancel) {
      return false;
    }
    editorState.mode !== 'edit-only' &&
      setEditorState({
        ...editorState,
        mode: 'read',
        value: editorState.savedValue,
      });
  };

  /**
   * Attach custom event listeners to the editor
   *
   * @param event   - the init event
   * @param editor  - tinymce editor instance
   */
  const handleOnInit = (event: Event, editor: Editor['editor']) => {
    if (!editor) {
      return;
    }
    /**
     * The following events depends on browser
     * supporting Element.closest() - all modern
     * browsers except IE11.
     *
     * Putting a check to prevent errors on IE11
     */
    if (isFunction(Element.prototype.closest)) {
      editor.on('ExecCommand', function checkListNodes(evt: EditorEvent<Events.ExecCommandEvent>) {
        /**
         * We want to make the bullet points or the numbers the same
         * font size as the text it contains. This will look for the
         * first span element inside an LI and put the font size for
         * the bullet point to the font size of that span
         */
        const cmd = evt.command;
        if (cmd === 'FontSize') {
          const node = evt.target.selection.getNode();
          setListItemFontSize(editor, node);
        }
        if (cmd === 'InsertUnorderedList' || cmd === 'InsertOrderedList') {
          const node = evt.target.selection.getNode();
          setListItemFontSize(editor, node);
        }
      });
    }
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>): void => {
    onSave && onSave(e);

    if (editorState.mode !== 'edit-only') {
      setEditorState({
        ...editorState,
        mode: 'read',
        savedValue: editorState.value,
      });
    }
  };

  const [selectedFolder, setSelectedFolder] = useState<PulseSelectPropTypes['value']>(null);

  const handleChange: PulseSelectPropTypes['valueChangeHandler'] = (value: {
    dirId: PulseSelectPropTypes['value'];
  }) => {
    setSelectedFolder(value.dirId);
  };

  const containerClassNames = clsx('pulse-editor__root', classes?.root, isInvalid && 'pulse-editor__root--invalid');
  const footerClassNames = clsx('pulse-editor__footer', classes?.footer, footerExtraComponents && 'space-between');
  const uploadComponent = (
    <PulseUploader
      portalTarget={uploaderCtnEl}
      PulseFolderSelectProps={{
        jobId: PulseUploaderProps?.PulseFolderSelectProps?.jobId,
        valueChangeHandler: handleChange,
        value: selectedFolder,
        selectFirst: true,
        ...PulseUploaderProps?.PulseFolderSelectProps,
      }}
      {...PulseUploaderProps}
    />
  );
  const calcExtraFooterChildWidth = () => {
    if (footer && !footerComponent && footerExtraComponents && extraFooterCtn.current?.children) {
      return [...extraFooterCtn.current?.children].reduce((width, child) => {
        const marginLeft = parseInt(window.getComputedStyle(child).getPropertyValue('margin-left'));
        const marginRight = parseInt(window.getComputedStyle(child).getPropertyValue('margin-right'));
        return width + child.clientWidth + marginLeft + marginRight;
      }, 0);
    }
    return 0;
  };
  return (
    <div data-testid="pulse-editor__root" className={containerClassNames}>
      {editorState.mode && ['read', 'read-only'].includes(editorState.mode) && (
        <div data-testid="pulse-editor__read-ctn" className="pulse-editor__read-ctn">
          {editorState.mode === 'read' && (
            <PulseButton
              classes={['pulse-editor__edit']}
              color={Colors.default}
              icon={true}
              iconClasses={{ icon: ['fal fa-pencil pulse-editor__edit-icon'] }}
              label={null}
              onClick={editContent}
              variant={ButtonVariants.outlined}
            />
          )}
          <div
            className="pulse-editor__read"
            dangerouslySetInnerHTML={{
              __html: editorState.savedValue || '',
            }}
          ></div>
        </div>
      )}
      {isRequired && (
        <div className="pulse-editor__label">
          <span className={clsx('pulse-editor__label-required', isInvalid && 'pulse-editor__label--invalid')}>
            *Required
          </span>
        </div>
      )}
      {editorState.mode && ['edit', 'edit-only'].includes(editorState.mode) && (
        <>
          <Editor
            disabled={false}
            init={editorInitSettings}
            inline={false}
            onEditorChange={handleEditorChange}
            onInit={handleOnInit as EventHandler<any>}
            value={editorState.value}
            {...rest}
          />
          {footer && !footerComponent && (
            <>
              <div className={footerClassNames}>
                <div ref={extraFooterCtn} className={'pulse-editor__extraFooterCtn'}>
                  {isMobileDevice() || isShowFooterDropdown ? (
                    <PulseMenuButton
                      children={
                        <>
                          {PulseUploaderProps?.PulseFolderSelectProps?.jobId && uploadComponent}
                          {footerExtraComponents}
                        </>
                      }
                      {...PulseMenuButtonProps}
                    />
                  ) : (
                    <>
                      {PulseUploaderProps?.PulseFolderSelectProps?.jobId && uploadComponent}
                      {footerExtraComponents}
                    </>
                  )}
                </div>
                <div className="pulse-editor__default-actions">
                  {onCancel && (
                    <PulseButton
                      classes={['pulse-editor__button']}
                      onClick={handleCancel}
                      label="Cancel"
                      color={Colors.default}
                      variant={ButtonVariants.outlined}
                      {...CancelButtonProps}
                    />
                  )}
                  {onSave && (
                    <PulseButton
                      classes={['pulse-editor__button']}
                      onClick={handleSave}
                      label="Save"
                      color={Colors.success}
                      {...SaveButtonProps}
                    />
                  )}
                </div>
              </div>
              <div ref={uploaderCtnEl}></div>
            </>
          )}
          {footer && footerComponent}
        </>
      )}
    </div>
  );
};

export default PulseEditor;
