import React, { ReactElement } from 'react';
import { PulseModalFooterProps } from './pulse-modal-footer-types';
import styles from './pulse-modal-footer.module.scss';
import { Colors } from 'pulse-commons/types';
import clsx from 'clsx';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';

const PulseModalFooter: React.FunctionComponent<PulseModalFooterProps> = (
  props: PulseModalFooterProps,
): ReactElement => {
  /** Destructure your props here */
  const {
    cancelComponent,
    cancelIconClasses,
    cancelText,
    classes = {
      root: [],
      cancel: [],
      confirm: [],
    },
    confirmComponent,
    confirmDisabled,
    confirmIconClasses,
    confirmText,
    onCancelClick,
    onConfirmClick,
  } = props;

  return (
    <div data-testid="pulse-modal-footer__root" className={clsx(styles['pulse-modal-footer__root'], classes.root)}>
      {!cancelComponent ? (
        <PulseButtonBase
          classes={[
            styles['pulse-modal-footer__button'],
            styles['pulse-modal-footer__cancel-button'],
            ...(classes?.cancel ?? []),
          ]}
          iconClasses={{
            icon: cancelIconClasses,
          }}
          icon={!!cancelIconClasses}
          label={cancelText}
          onClick={onCancelClick}
        />
      ) : (
        cancelComponent
      )}
      {!confirmComponent ? (
        <PulseButtonBase
          classes={[
            styles['pulse-modal-footer__button'],
            styles['pulse-modal-footer__confirm-button'],
            ...(classes?.confirm ?? []),
          ]}
          color={Colors.success}
          disabled={confirmDisabled}
          iconClasses={{ icon: confirmIconClasses }}
          icon={!!confirmIconClasses}
          label={confirmText}
          onClick={onConfirmClick}
        />
      ) : (
        confirmComponent
      )}
    </div>
  );
};

export default PulseModalFooter;
