import clsx from 'clsx';
import PulseSelectOption, { PulseSelectOptionProps } from 'components/pulse-select/base/components/pulse-select-option';
import React, { ReactElement, Ref, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './pulse-user-option.module.scss';

type PulseUserOptionProps = Omit<PulseSelectOptionProps, 'isValue'>;

export const PulseUserLoadingComponent = (): ReactElement => {
  return (
    <>
      <span>
        <Skeleton className={styles['pulse-skeleton']} height={12} width={120} />
      </span>
    </>
  );
};

const PulseUserOption = (props: PulseUserOptionProps, ref: Ref<HTMLDivElement>) => {
  const { classes, PulseLabelProps, removeable = false } = props;

  const { classes: PulseLabelClasses } = PulseLabelProps || {};

  const internalPulseLabelProps: Partial<typeof PulseLabelProps> = {
    classes: {
      ...PulseLabelClasses,
      root: clsx(styles['pulse-user-option__pulseLabelRoot'], PulseLabelClasses?.root),
    },
    icon: false,
    ...PulseLabelProps,
  };

  return (
    <PulseSelectOption
      {...props}
      classes={{
        ...classes,
        root: clsx([
          classes?.root,
          styles['pulse-user-option__root'],
          removeable && styles['pulse-user-option__removeable'],
        ]),
      }}
      forwardedRef={ref}
      isLoadingComponent={<PulseUserLoadingComponent />}
      PulseLabelProps={useMemo(() => internalPulseLabelProps, [PulseLabelProps])}
    />
  );
};

export default React.forwardRef<HTMLDivElement, PulseUserOptionProps>(PulseUserOption);
