import React, { FC, memo, useMemo } from 'react';
import { ActionMeta, ValueType } from 'react-select';
import { isArray, isEqual } from 'lodash';
import { Provider } from 'react-redux';
import { PulseSelectOptionType } from 'components/pulse-select/base/pulse-select-base-types';
import PulseSelectBase from 'components/pulse-select/base/pulse-select-base';
import store, { usePSSDispatch, usePSSSelector } from './store';
import { changeSuggestion } from './store/slices';
import { PulseSuggestionSelectProps } from './types';

const PulseSuggestionSelect: FC<PulseSuggestionSelectProps> = props => {
  const {
    dataType,
    recentlySelectedLabel = 'RECENTLY SELECTED',
    moreOptionsLabel = 'MORE OPTIONS',
    PulseSelectGroupProps,
    onChange,
  } = props;
  const preloadValues = usePSSSelector(state => state.suggestion);
  const dispatch = usePSSDispatch();

  const staticOptions = useMemo(() => {
    // Get current user id to get suggestion values
    const userId = window.pulse?.config?.user?.id || '1';

    const options = (preloadValues[userId] || {})[dataType];

    if (options && options.length) {
      return [
        {
          label: recentlySelectedLabel,
          options: options,
        },
      ];
    }
    return undefined;
  }, [window.pulse?.config?.user, preloadValues]);

  const hanndleValueChanged = (
    value: ValueType<PulseSelectOptionType<Record<string, any>>>,
    action: ActionMeta<PulseSelectOptionType<Record<string, any>>>,
  ) => {
    // Call onchange from props if exists
    onChange && onChange(value, action);
    // Get current user id to get suggestion values
    const userId = window.pulse?.config?.user?.id || '1';
    if (isArray(value) && value.length) {
      return dispatch(changeSuggestion({ option: value[0], userId, dataType }));
    }
    // Do update to redux
    dispatch(changeSuggestion({ option: value, userId, dataType }));
  };

  return (
    <PulseSelectBase
      {...PulseSelectGroupProps}
      isGroup
      isMulti={false}
      dataStructure={null}
      selectMoreOptionLabel={moreOptionsLabel}
      staticOptions={staticOptions}
      onChange={hanndleValueChanged}
    />
  );
};

// Wrapping memo to reduce unecessary rendering
export default memo(
  (props: PulseSuggestionSelectProps) => (
    <Provider store={store}>
      <PulseSuggestionSelect {...props} />
    </Provider>
  ),
  // Shallow equals
  isEqual,
);
