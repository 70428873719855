import React, { ReactElement, useEffect, useState, useRef, Ref } from 'react';
import ReactDOM from 'react-dom';
import { PulseUploaderProps, PulseUploaderDropzoneProps, PulseUploaderFile } from './pulse-uploader-types';
import styles from './pulse-uploader.module.scss';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import PulseFolderSelect from 'components/pulse-select/pulse-folder/pulse-folder-select';
import PulseFileNameEditor from './components/pulse-file-name-editor/pulse-file-name-editor';
import { useDropzone } from 'react-dropzone';
import PulseThumbnail from 'components/pulse-comments/components/pulse-thumbnail/pulse-thumbnail';
import thumbnailStyles from 'components/pulse-comments/components/pulse-thumbnail/pulse-thumbnail.module.scss';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import clsx from 'clsx';
import { IconSizes } from 'pulse-commons/types';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import isFunction from 'lodash/isFunction';

const getFileUploaderDisAllowedExtensions = window?.pulse?.config?.fileUploaderDisAllowedExtensions?.split(',') ?? [];

export const PulseUploaderDropzone = (props: PulseUploaderDropzoneProps): ReactElement => {
  const {
    allowSelectFolder = true,
    files = [],
    isMultiple = true,
    onFileDrop,
    onFileRename,
    onFileRemove,
    PulseFolderSelectProps,
    visible = false,
    allowDuplicateFiles = false,
    classes,
  } = props;
  const [rootHeight, setRootHeight] = useState(0);
  const placeholderTextRef: Ref<HTMLParagraphElement> = useRef(null);
  const placeholderRef: Ref<HTMLDivElement> = useRef(null);
  const dropzoneRootRef: Ref<HTMLDivElement> = useRef(null);

  const { getRootProps, getInputProps, open, rootRef } = useDropzone({
    noClick: true,
    multiple: isMultiple,
    onDrop: acceptedFiles => {
      const existingFilePath = files.map(file => file.path);

      const acceptedFilesWithPreview = acceptedFiles.filter((file: PulseUploaderFile) => {
        if (allowDuplicateFiles || !existingFilePath.includes(file.path)) {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }
      });
      onFileDrop && onFileDrop(acceptedFilesWithPreview);
    },
    validator: file => {
      const errorMessage = 'Upload failed due to invalid file extension';
      const splitFileName = file.name.split?.('.')?.pop?.();
      const extensionFile = splitFileName?.toLocaleLowerCase?.();
      if (extensionFile && getFileUploaderDisAllowedExtensions.includes(extensionFile)) {
        window?.utilities?.notification.danger(errorMessage);
        return {
          code: 'invalid-file-extension',
          message: errorMessage,
        };
      }
      return null;
    },
  });

  /* istanbul ignore next */
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.map(file => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    return () => {
      files.forEach(file => URL.revokeObjectURL(file?.preview || ''));
    };
  }, [files]);

  useEffect(() => {
    if (visible) {
      const scrollHeight = dropzoneRootRef?.current?.scrollHeight;
      scrollHeight && setRootHeight(scrollHeight);
    } else {
      setRootHeight(0);
    }
  }, [visible, files]);

  useEffect(() => {
    window.onresize = () => {
      const scrollHeight = dropzoneRootRef?.current?.scrollHeight;
      visible && scrollHeight && setRootHeight(scrollHeight);
    };
  }, []);

  const handleFilePickerOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    (e.target === rootRef.current || e.target === placeholderTextRef.current) && open();
  };

  const handlePlaceHolderClick = () => {
    open();
  };

  const handleOnRename = (originalFileName: string, newFileName: string) => {
    const newFilesArray = files.map(file => {
      if (file.name === originalFileName) {
        const newFile = new File([file], newFileName, { type: file.type });
        Object.assign(newFile, {
          preview: URL.createObjectURL(file),
          path: newFileName,
        });
        return newFile as PulseUploaderFile;
      }
      return file as PulseUploaderFile;
    });
    onFileRename && onFileRename(newFilesArray);
  };

  const removeFile = (removedFile: File, index: number) => () => {
    onFileRemove && onFileRemove(removedFile, index);
  };

  return (
    <div
      data-testid="pulse-uploader__dropzoneRoot"
      className={clsx(styles['pulse-uploader__dropzoneRoot'], classes?.root)}
      style={{
        height: rootHeight,
        overflow: visible ? 'visible' : 'hidden',
        transition: 'height 300ms, overflow 300ms',
      }}
    >
      <div ref={dropzoneRootRef}>
        {allowSelectFolder && <PulseFolderSelect {...PulseFolderSelectProps} />}
        <div
          {...getRootProps({
            className: clsx(styles['pulse-uploader__dropzone'], classes?.dropzone),
            onClick: handleFilePickerOpen,
          })}
        >
          <input {...getInputProps()} />
          {!files?.length && <p ref={placeholderTextRef}>Click to add files or drag and drop your files here</p>}
          {!!files?.length && (
            <>
              {files?.map((file, index) => {
                return (
                  <PulseThumbnail
                    classes={{
                      root: styles['pulse-uploader__thumbnail'],
                      imageCtn: styles['pulse-uploader__imageCtn'],
                    }}
                    extraComponents={
                      <>
                        <PulseFileNameEditor
                          classes={{
                            root: classes?.fileEditor,
                          }}
                          file={file}
                          onRename={handleOnRename}
                        />
                        <PulseIconButton
                          handleClick={removeFile(file, index)}
                          classes={{
                            root: styles['pulse-uploader__removeBtn'],
                            pulseIcon: {
                              icon: 'fal fa-times',
                            },
                          }}
                          size={IconSizes.sm}
                        />
                      </>
                    }
                    fileType={file.type}
                    key={`${file.name}-${index}`}
                    fileDetails={file}
                    src={file.preview || ''}
                  />
                );
              })}
              <div
                className={clsx(
                  styles['pulse-uploader__thumbnail'],
                  styles['pulse-uploader__placeholder'],
                  thumbnailStyles['pulse-thumbnail__root'],
                )}
                ref={placeholderRef}
                onClick={handlePlaceHolderClick}
              >
                <PulseIcon
                  classes={{
                    root: styles['pulse-uploader__placeholderIcon'],
                    icon: 'fal fa-plus-circle',
                  }}
                  iconName=""
                  size={IconSizes.lg}
                />
                <span>Add new files</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const PulseUploader = (props: PulseUploaderProps): ReactElement => {
  const { portalTarget, visible, onToggleUploader, PulseButtonBaseProps, ...pulseUploaderDropzoneProps } = props;
  const [isPulseDropzoneVisible, setIsPulseDropzoneVisible] = useState(false);

  const showPulseDropzone = () => {
    isFunction(onToggleUploader) ? onToggleUploader(!visible) : setIsPulseDropzoneVisible(!isPulseDropzoneVisible);
  };

  return (
    <>
      <PulseButtonBase
        icon={true}
        label="Add Attachments"
        onClick={showPulseDropzone}
        {...PulseButtonBaseProps}
        iconClasses={{
          icon: 'fal fa-paperclip',
          ...PulseButtonBaseProps?.iconClasses,
        }}
      />
      {portalTarget?.current &&
        ReactDOM.createPortal(
          <PulseUploaderDropzone
            visible={isFunction(onToggleUploader) ? visible : isPulseDropzoneVisible}
            {...pulseUploaderDropzoneProps}
          />,
          portalTarget.current,
        )}
    </>
  );
};

export default PulseUploader;
