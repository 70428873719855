import clsx from 'clsx';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import { PulseIconProps } from 'types/icon-types';
import styles from './pulse-loading-option.module.scss';

export type PulseLoadingOptionProps = {
  icon: NonNullable<PulseIconProps['classes']>['icon'];
};

const PulseLoadingOption = (props: PulseLoadingOptionProps): ReactElement => {
  const { icon } = props;
  return (
    <span className={clsx(styles['pulse-loading-option__root'])}>
      <PulseIcon classes={{ root: styles['pulse-loading-option__iconRoot'], icon }} iconName="" />
      <Skeleton className={clsx([styles['pulse-skeleton']])} height={12} width={120} />
    </span>
  );
};

export default React.memo<PulseLoadingOptionProps>(PulseLoadingOption);
