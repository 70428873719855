import { AxiosResponse } from 'axios';
import { brandCategoriesApi } from 'pulse-api/brand-categories/brand-categories';
import { brandsApi } from 'pulse-api/brands/brands';
import { clientsApi } from 'pulse-api/clients/clients';
import { departmentsApi } from 'pulse-api/departments/departments';
import { groupsApi } from 'pulse-api/groups/groups';
import { jobsApi } from 'pulse-api/jobs/jobs';
import { rolesApi } from 'pulse-api/roles/roles';
import { statusApi } from 'pulse-api/status/status';
import { timesheetActivityApi } from 'pulse-api/timesheetactivity/timesheetactivity';
import { usersApi } from 'pulse-api/users/users';
import { GetConfig, PulseResource, PulseSubRequestObject } from 'pulse-types/pulse-resource';

export enum PowerSearchFacets {
  AssignedUser = 'AssignedUser',
  BookingStatus = 'BookingStatus',
  BrandCategories = 'BrandCategories',
  Brands = 'Brands',
  Clients = 'Clients',
  Departments = 'Departments',
  Jobs = 'Jobs',
  JobStatus = 'JobStatus',
  Requestor = 'Requestor',
  Roles = 'Roles',
  TimesheetActivity = 'TimesheetActivity',
  UserGroups = 'UserGroups',
}
export enum PowerSearchSubFacets {
  UserOffices = 'UserOffices',
  JobOffices = 'JobOffices',
}

export type PowerSearchTypes = {
  api?: {
    [index: string]: any;
    get: (params: Partial<GetConfig>) => Promise<AxiosResponse<any> | any>;
    getConfig: (params: GetConfig) => PulseSubRequestObject;
  };
  fields?: Record<string, string>;
  filterKey?: string;
  filters?: Record<string, any>;
  groupLabel?: string;
  optionLabelKey: string;
  returnedType: string;
  requestKey: string;
  customFacets?: {
    [key in PowerSearchSubFacets]: PowerSearchTypes;
  };
};

type PulsePowerSearchAllowedResourceTypes = {
  [key in PowerSearchFacets]: PowerSearchTypes;
};

export const PulsePowerSearchAllowedResource: PulsePowerSearchAllowedResourceTypes = {
  AssignedUser: {
    api: usersApi,
    fields: {
      users: 'name,userid,avatarUrl',
    },
    filterKey: 'search',
    groupLabel: 'Assigned User',
    optionLabelKey: 'name',
    returnedType: 'assignedUsers',
    requestKey: 'user.id',
  },
  BookingStatus: {
    api: undefined,
    optionLabelKey: 'name',
    returnedType: 'bookingStatus',
    requestKey: 'booking.probability',
  },
  BrandCategories: {
    api: brandCategoriesApi,
    fields: {
      brands: 'title,brand_category_id',
    },
    filterKey: 'title',
    groupLabel: 'brand categories',
    optionLabelKey: 'title',
    returnedType: 'brandcategories',
    requestKey: 'booking.job.brand.category.id',
    filters: {
      isApplyPermissions: 1,
    },
  },
  Brands: {
    api: brandsApi,
    fields: {
      brands: 'title,id',
    },
    filterKey: 'title',
    groupLabel: 'brands',
    optionLabelKey: 'title',
    returnedType: 'brands',
    requestKey: 'booking.job.brand.id',
    filters: {
      isApplyPermissions: 1,
    },
  },
  Departments: {
    api: departmentsApi,
    fields: {
      brands: 'department_id,name',
    },
    filterKey: 'name',
    groupLabel: 'departments',
    optionLabelKey: 'name',
    returnedType: 'departments',
    requestKey: 'user.department.id',
    filters: {
      isApplyPermissions: 1,
    },
  },
  Clients: {
    api: clientsApi,
    optionLabelKey: 'company',
    requestKey: '',
    returnedType: 'clients',
    customFacets: {
      UserOffices: {
        api: clientsApi,
        fields: {
          clients: 'company,clientid',
        },
        filterKey: 'company',
        groupLabel: 'user offices',
        optionLabelKey: 'company',
        returnedType: 'userOffices',
        requestKey: 'user.client.id',
      },
      JobOffices: {
        api: clientsApi,
        fields: {
          clients: 'company,clientid',
        },
        filterKey: 'company',
        groupLabel: 'job offices',
        optionLabelKey: 'company',
        returnedType: 'jobOffices',
        requestKey: 'booking.job.client.id',
      },
    },
  },
  Jobs: {
    api: jobsApi,
    fields: {
      jobs: 'jobTitle,jobExtension,jobId',
    },
    filterKey: 'or.jobtitle.jobextension',
    groupLabel: 'jobs',
    optionLabelKey: 'jobTitle',
    returnedType: 'jobs',
    requestKey: 'booking.job.id',
  },
  JobStatus: {
    api: statusApi,
    fields: {
      jobStatus: 'jobStatusId,title,prefix',
    },
    filterKey: 'title',
    groupLabel: 'job status',
    optionLabelKey: 'title',
    returnedType: 'jobStatus',
    requestKey: 'booking.job.status',
  },
  Requestor: {
    api: usersApi,
    fields: {
      users: 'name,userid,avatarUrl',
    },
    filterKey: 'search',
    groupLabel: 'booking requestor',
    optionLabelKey: 'name',
    returnedType: 'requestor',
    requestKey: 'booking.requestedBy',
  },
  Roles: {
    api: rolesApi,
    fields: {
      roles: 'name,role_id',
    },
    filterKey: 'name',
    groupLabel: 'roles',
    optionLabelKey: 'name',
    returnedType: 'roles',
    requestKey: 'user.role.id',
  },
  TimesheetActivity: {
    api: timesheetActivityApi,
    fields: {
      timesheetactivity: 'timesheetactivityid,name',
    },
    filterKey: 'name',
    groupLabel: 'activity',
    optionLabelKey: 'name',
    returnedType: 'timesheetactivity',
    requestKey: 'booking.activityid',
    filters: {
      isApplyPermissions: 1,
    },
  },
  UserGroups: {
    api: groupsApi,
    fields: {
      clients: 'user_group_id,name',
    },
    filterKey: 'name',
    groupLabel: 'user groups',
    optionLabelKey: 'name',
    returnedType: 'usergroups',
    requestKey: 'user.group.id',
    filters: {
      isApplyPermissions: 1,
    },
  },
};

export interface PulsePowerSearchResource {
  params?: {
    [key: string]: any;
  };
  type: PulseResource;
  requestId?: string;
}
