import React, { ReactElement } from 'react';
import { PulseDatepickerProps } from './pulse-datepicker-types';
import Datepicker from './components/datepicker';
import './datepicker.scss';
import { PulseDatePickerProvider } from './context/pulse-datepicker-context';

/**
 * Start of Date Picker Component
 *
 */
export const PulseDatePicker = (props: PulseDatepickerProps): ReactElement => {
  const { classes, DatepickerProps, onChange, values } = props;

  return (
    <PulseDatePickerProvider
      providedState={{
        values,
        onChange,
      }}
    >
      <div className={classes?.root}>
        <Datepicker {...DatepickerProps} />
      </div>
    </PulseDatePickerProvider>
  );
};

export default PulseDatePicker;
